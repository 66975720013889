import Api from './Api';

const graphService = {

  getClientGroupGraphData: ( jsonData ) => {

    return Api.post( '/graph/client_groups', jsonData, {
      withCredentials: true
    } )

  },
  getProductGraphData: ( jsonData ) => {

    return Api.post( '/graph/products', jsonData, {
      withCredentials: true
    } )

  },
  getSalesStatusData: ( jsonData ) => {

    return Api.post( '/graph/month_status', jsonData, {
      withCredentials: true
    } )

  },
  getTotalsGraphData: ( jsonData ) => {

    return Api.post( '/graph/totals', jsonData, {
      withCredentials: true
    } )

  },
}

export default graphService
