import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Select } from 'antd';
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import Swal from 'sweetalert2';
import clientsGroupsService from '../../../../services/ClientsGroups';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewGroup = ( { handlerClose, refreshGroups, editionGroup } ) => {

  const [ groupCreationVisible, setGroupCreationVisible ] = useState( false )
  const [ loading, setLoading ] = useState( false )
  const [ newGroupData, setNewGroupData ] = useState({
    name: ''
  })
  
  const addGroup = () => {

    if ( !newGroupData.name ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })

      return;
    }

    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando el grupo..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    clientsGroupsService.post( newGroupData )
      .then( res => {
        Swal.close();
        refreshGroups();

        handlerClose();
      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        {
          loading
          ?
          "loading.."
          :
          <ClientGroupModalBody
            newGroupData={ newGroupData }
            setNewGroupData={ setNewGroupData }
            handlerClose={ handlerClose }
            setGroupCreationVisible={ setGroupCreationVisible }
            addGroup={ addGroup }
            />
        }

      </Modal>
    </div>
  )
}

export default NewGroup

const ClientGroupModalBody = ({ newGroupData, setNewGroupData, handlerClose, addGroup }) => {

  return (
    <div className="modalContainer creation">
      <div className="column">
        <AiOutlineUsergroupAdd />
      </div>
      <div className="data">
        <div className="form">
          <div className="line name">
            <div className="form-input">
              <input id="clientName" type="text"placeholder="Nombre" value={ newGroupData.name } onChange={ ev => { setNewGroupData( { ...newGroupData, name: ev.target.value } ) } } />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button type="text" size="large" onClick={ handlerClose }> Volver </Button>
          <Button className="success" variant="primary" onClick={ addGroup } size="large"> Crear Grupo </Button>
        </div>
      </div>
    </div>
  )
}