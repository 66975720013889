import React from 'react'
import { CalendarFilter } from '../../clients/components/filter/CalendarFilter'
import SearchFilter from './SearchFilter'

import './filter.css'
import moment from 'moment'
import { MONTHS } from '../../../../../constants/constants'
import { Cell, Pie, PieChart } from 'recharts'
import Currency from '../../../../../components/Currency'

export const Filter = ({ dateSelected, selectDate, filterString, setFilterString, closestExchange, totals }) => {

  const date = moment( closestExchange.date )

  const percentageFull = totals && totals.LOCAL.forecast ? ( ( ( totals.LOCAL.sales + totals.LOCAL.commitments ) / totals.LOCAL.forecast ) * 100 ) : 100
  const data = ( percentageFull || percentageFull === 0 ) && [ {
      value: percentageFull <= 100 ? percentageFull : 100,
      fill: "#4caf50"
  }, {
      value: 100 - ( percentageFull <= 100 ? percentageFull : 100 ),
      fill: '#ff0000'
  } ] || null

  return (
    <div className='card filters'>
        
        <CalendarFilter dateSelected={ dateSelected } selectDate={ selectDate } />

        <div className='month-exchange-data'>
          <div className='tag'> Tipo de cambio: </div>
          <div className='value'> { closestExchange.value } <span className='exchange-tag'> <Currency /> </span> </div>
          <div className='reference'> { MONTHS[ date.month() ] } { date.year() } </div>
        </div>

        <div className='separator'></div>

        <SearchFilter filterString={ filterString } setFilterString={ setFilterString } />

        {
          totals && ( ( totals.LOCAL.sales + totals.LOCAL.commitments ) || totals.LOCAL.forecast ) && 
          <div className='card'>
            <div className='info'>
              <div className='tag'>
                Total
              </div>
              <div className='value'>
                { ( totals.LOCAL.sales + totals.LOCAL.commitments ).toLocaleString() } / { totals.LOCAL.forecast.toLocaleString() } <span className='exchange-tag'> <Currency /> </span>
              </div>
            </div>

            <div className='percentage'>
              <div className="graph">
                  <PieChart height={ 70 } width={ 90 }>
                      <Pie
                      data={ data }
                      startAngle={ 225 }
                      endAngle={ -45 }
                      innerRadius={ 20 }
                      outerRadius={ 30 }
                      paddingAngle={5}
                      dataKey="value"
                      >
                      {
                          data.map((entry, index) => <Cell key={`cell-${index}`} fill={ entry.fill } />)
                      }
                      </Pie>
                  </PieChart>
                  <div className="percentage">
                      <span className="value"> { Math.round( percentageFull ) } </span>
                      <span className="unit"> % </span>
                  </div>
              </div>
            </div>
          </div>
        }

    </div>
  )
}
