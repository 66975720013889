import Checkbox from 'antd/es/checkbox/Checkbox'
import React from 'react'
import Currency from '../../../../../../components/Currency'
import { MONTHS } from '../../../../../../constants/constants'
import './menu.css'

export const FilterMenu = ({
    filters,
    setFilters,
    products,
    clients
}) => {

    const handleChangeSelector = productId => {

        setFilters( prev => {

            const products = prev.products.indexOf( productId ) > -1 ? prev.products.filter( p => p !== productId ) : [ ...prev.products, productId ] 

            return {
                ...prev,
                products
            }
        })

    }

    const handleChangeClientSelector = clientId => {

        setFilters( prev => {

            const allsubclients = clients.list.data.filter( client => client.group === filters.client_group.id ).map( p => p.id )
            let sub_clients = prev.sub_clients
            if ( prev.sub_clients === null ){
                sub_clients = allsubclients.filter( c => c !== clientId )
            } else {
                if ( prev.sub_clients.indexOf( clientId ) > -1 ){
                    sub_clients = prev.sub_clients.filter( c => c !== clientId )
                } else {
                    sub_clients = [ ...prev.sub_clients, clientId ]
                }
            }

            return {
                ...prev,
                sub_clients
            }
        })

    }

    const selectAll = type => {

        if ( type === 'ALL' ){

            setFilters({ ...filters, products: products.data.map( p => p.id ) })

        } else if ( type === 'NONE' ){

            setFilters({ ...filters, products: [] })

        }

    }

    return (
        <div className="filter-menu">
            <div className="item">
                <div className="title">MES</div>
                <div className="options">
                    {
                        MONTHS.map( (monthName, index) => (
                            <div 
                                key={monthName} 
                                className={`option ${ index === filters.date.month ? 'selected' : '' }`}
                                onClick={ () => { setFilters( { ...filters, date: { month: index } } ) } }>
                                    { monthName }
                            </div>
                        ) )
                    }
                </div>
            </div>

            <div className="item">
                <div className="title">MONEDA</div>
                <div className="options row">
                    <div 
                        className={ `option ${ filters.exchange === 'LOCAL' ? 'selected' : '' }` }
                        onClick={ () => setFilters({ ...filters, exchange: 'LOCAL' }) }>
                        <Currency />
                    </div> 
                    | 
                    <div 
                        className={ `option ${ filters.exchange === 'USD' ? 'selected' : '' }` }
                        onClick={ () => setFilters({ ...filters, exchange: 'USD' }) }>
                        USD
                    </div>
                </div>
            </div>

            <div className="item">
                <div className="title">CLIENTES</div>

                <div className="options row" style={{ paddingBottom: '10px' }}>
                    <div className="options row">
                        <div 
                            className={ `option` }
                            onClick={ () => setFilters( prev => ({ ...prev, sub_clients: [] }) ) }>
                                Ninguno
                        </div> 
                        | 
                        <div 
                            className={ `option` }
                            onClick={ () => setFilters( prev => ({ ...prev, sub_clients: null }) ) }>
                                Todos
                        </div>
                    </div>
                </div>
                
                <div className="options">
                    {
                        clients.list.data
                        .filter( client => client.group === filters.client_group.id )
                        .map( client => (
                            <div className="item" key={ client.id } onClick={ () => handleChangeClientSelector( client.id ) }>
                                <Checkbox checked={ filters.sub_clients === null || filters.sub_clients.indexOf( client.id ) > -1 } />
                                <span style={{ paddingLeft: '5px' }}> { client.name } </span>
                            </div>    
                        ) )
                    }
                </div>
            </div>

            <div className="item">
                <div className="title">PRODUCTOS</div>

                <div className="options row" style={{ paddingBottom: '10px' }}>
                    <div className="options row">
                        <div 
                            className={ `option` }
                            onClick={ () => { selectAll( 'NONE' ) } }>
                                Ninguno
                        </div> 
                        | 
                        <div 
                            className={ `option` }
                            onClick={ () => { selectAll( 'ALL' ) } }>
                                Todos
                        </div>
                    </div>
                </div>
                
                <div className="options">
                    {
                        products.data.map( product => (
                            <div className="item" key={ product.id } onClick={ () => handleChangeSelector( product.id ) }>
                                <Checkbox checked={ filters.products.indexOf( product.id ) > -1 } />
                                <span style={{ paddingLeft: '5px' }}> { product.name } </span>
                            </div>    
                        ) )
                    }
                </div>
            </div>

        </div>
    )

}