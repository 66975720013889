import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import { FiUserPlus } from 'react-icons/fi'
import './newMonthExchange.css'
import Swal from 'sweetalert2';
import exchangeService from '../../../../services/Exchange';
import Currency from '../../../../components/Currency';

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewMonthExchange = ( { year, month, editionMode, initialValue, handlerClose, refreshAction } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ newExchangeData, setNewExchangeData ] = useState({
    year: year,
    month: month + 1,
    value: initialValue || ''
  })

  const addExchange = () => {

    if ( !newExchangeData.value ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando el tipo de cambio..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    const ejecutarAPI = editionMode ? exchangeService.put : exchangeService.post
    ejecutarAPI( newExchangeData )
      .then( res => {
        refreshAction();
        Swal.close();
        handlerClose()
      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer creation">
            <div className="column">
                <FiUserPlus />
            </div>
            <div className="data">
                <div className='title'> ¿Cuánto vale 1 dolar ( USD ) en pesos? </div>
                <div className="form">
                    <div className="line code">
                        <div className="form-input">
                            <input type="number" placeholder="Valor de cambio" value={ newExchangeData.value } onChange={ ev => { setNewExchangeData( { ...newExchangeData, value: Number( ev.target.value ) } ) } } />
                            <span className='unit'> <Currency /> </span>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button variant="primary" onClick={ addExchange } size="large" disabled={ loading }> Crear Cambio </Button>
                </div>
            </div>
        </div>

      </Modal>
    </div>
  )
}

export default NewMonthExchange