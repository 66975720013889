import React from 'react';

import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import { BiLineChartDown, BiLineChart } from "react-icons/bi";
import { FiUser, FiUsers } from "react-icons/fi";
import { GiBottledBolt } from "react-icons/gi";
import { RiExchangeDollarFill } from 'react-icons/ri'
import { BsPersonBadge, BsFileText } from 'react-icons/bs'
import { MdStackedLineChart } from 'react-icons/md'
import { connect } from 'react-redux';
import { BsPieChart } from 'react-icons/bs';

const FeaturesList = ( { userdata } ) => {

  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();

  const selectFeature = ( event ) => {

    if ( document.getElementsByClassName('featureItem selected').length ){
      document.getElementsByClassName('featureItem selected')[0].classList.remove('selected');
    }

    event.target.closest('.featureItem').classList.add('selected');
  }

  return (
    <div className="featuresList">
      <div className="subtitle"> Dashboards </div>
        <div className={ pathname.includes('stateprod') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/stateprod`}>
            <div className="logo">
              <IconContext.Provider value={{ className: "global-class-name" }}>
                <div>
                  <BiLineChartDown />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> Estado Productos </div>
          </Link>
        </div>
        <div className={ pathname.includes('statecli') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/statecli`}>
            <div className="logo">
              <IconContext.Provider value={{ className: "global-class-name" }}>
                <div>
                  <BiLineChart />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> Estado Clientes </div>
          </Link>
        </div>
        <div className={ pathname.includes('status') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/status`}>
            <div className="logo">
              <IconContext.Provider value={{ className: "global-class-name" }}>
                <div>
                  <BsFileText />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> Estado General </div>
          </Link>
        </div>
        <div className={ pathname.includes('totals') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/totals`}>
            <div className="logo">
              <IconContext.Provider value={{ className: "global-class-name" }}>
                <div>
                  <BsPieChart />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> Totales </div>
          </Link>
        </div>

      <div className="subtitle"> Carga de datos </div>
        <div className={ pathname.includes('sales') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
            <Link to={`${ url }/sales`}>
              <div className="logo">
                <IconContext.Provider value={{ className: "global-class-name" }}>
                  <div>
                    <BsPersonBadge />
                  </div>
                </IconContext.Provider>
              </div>
              <div className="tag"> Ventas </div>
            </Link>
          </div>
        <div className={ pathname.includes('clients') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
            <Link to={`${ url }/clients`}>
              <div className="logo">
                <IconContext.Provider value={{ className: "global-class-name" }}>
                  <div>
                    <FiUsers />
                  </div>
                </IconContext.Provider>
              </div>
              <div className="tag"> Clientes </div>
            </Link>
          </div>
          <div className={ pathname.includes('products') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
            <Link to={`${ url }/products`}>
              <div className="logo">
                <IconContext.Provider value={{ className: "global-class-name" }}>
                  <div>
                    <GiBottledBolt />
                  </div>
                </IconContext.Provider>
              </div>
              <div className="tag"> Productos </div>
            </Link>
          </div>
        <div className={ pathname.includes('exchange') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/exchange`}>
            <div className="logo">
              <IconContext.Provider value={{ className: "global-class-name" }}>
                <div>
                  <RiExchangeDollarFill />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> Tipo de cambio </div>
          </Link>
        </div>
        <div className={ pathname.includes('forecast') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/forecast`}>
            <div className="logo">
              <IconContext.Provider value={{ className: "global-class-name" }}>
                <div>
                  <MdStackedLineChart />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> Pronostico ventas </div>
          </Link>
        </div>
        {
          userdata.data &&
          userdata.data.type === 10
          ?
          <div className={ pathname.includes('sellers') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
            <Link to={`${ url }/sellers`}>
              <div className="logo">
                <IconContext.Provider value={{ className: "global-class-name" }}>
                  <div>
                    <BsPersonBadge />
                  </div>
                </IconContext.Provider>
              </div>
              <div className="tag"> Vendedores </div>
            </Link>
          </div>
          :
          null
        }
        <div className={ pathname.includes('profile') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/profile`}>
             <div className="logo">
               <IconContext.Provider value={{ className: "global-class-name" }}>
                 <div>
                   <FiUser />
                 </div>
               </IconContext.Provider>
             </div>
             <div className="tag"> Profile </div>
          </Link>
        </div>
    </div>
  )
}

export default connect( state => ({ userdata: state.userdata } ) )( FeaturesList )
