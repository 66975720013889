const userdataReducer = ( userdata = { loading: true, error: null, data: null }, action ) => {

    if ( action.type === 'START_FETCH_USERDATA' ){
      return { loading: true, error: null, data: null };
    }

    if ( action.type === 'FAIL_FETCH_USERDATA' ){
      return { loading: false, error: true, data: null };
    }

    if ( action.type === 'RECEIVE_FETCH_USERDATA' ){
      return { loading: false, error: false, data: { ...action.payload } };
    }

    return userdata;
  
  }
  
export default userdataReducer;
  