import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, DatePicker, Input, Select } from 'antd';
import { FiUserPlus } from 'react-icons/fi'
import Swal from 'sweetalert2';
import salesService from '../../../../services/Sales';
import moment from 'moment';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewSaleModal = ( { year, month, handlerClose, refreshAction, clients, products, data } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ newSaleData, setNewSaleData ] = useState({
    date: data.editionMode ? moment( data.saleData.date ) : moment().month( month ).year( year ),
    product: data.editionMode ? data.saleData.product : null,
    client: data.editionMode ? data.saleData.client : null,
    q: data.editionMode ? data.saleData.q : '',
    currency: 'LOCAL',
    total: '',
    facturable: data.editionMode ? data.saleData.facturable : true
  })

  const addSale = () => {

    if ( !newSaleData.product || !newSaleData.client || !newSaleData.q || !newSaleData.currency ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando la venta..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    const addSaleAPI = data.editionMode ? salesService.put : salesService.post;
    addSaleAPI( { ...newSaleData, id: data?.saleData?.id } )
      .then( res => {
        refreshAction();
        Swal.close();
        handlerClose()
      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer creation">
            <div className="column">
                <FiUserPlus />
            </div>
            <div className="data">
                <div className="form">
                    <div className="line code">
                        <DatePicker 
                                    allowClear={ false }
                                    value={ newSaleData.date } 
                                    onChange={ date => { setNewSaleData( { ...newSaleData, date: date } ) } } />
                    </div>
                    <div className='line'>
                      <Select
                                showSearch
                                style={{ width: 200 }}
                                value={ newSaleData.client }
                                onChange={ value => { setNewSaleData({ ...newSaleData, client: Number( value ) }) } }
                                placeholder="Buscar cliente"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {
                                  clients.list.data.map( client => <Option key={ client.id } value={ client.id }>{ client.name }</Option>)
                                }
                        </Select>
                    </div>
                    <div className='line'>
                      <Select
                                showSearch
                                style={{ width: 200 }}
                                value={ newSaleData.product }
                                onChange={ value => { setNewSaleData({ ...newSaleData, product: Number( value ) }) } }
                                placeholder="Buscar producto"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {
                                  products.list.data.map( product => <Option key={ product.id } value={ product.id }>{ product.name }</Option>)
                                }
                        </Select>
                    </div>
                    <div className='line'>
                      <Input type="number" placeholder='Cantidad' value={ newSaleData.q } onChange={ ev => { setNewSaleData({ ...newSaleData, q: Number( ev.target.value ) }) } } />
                      <Input type="number" placeholder='Precio Total' value={ newSaleData.total } onChange={ ev => { setNewSaleData({ ...newSaleData, total: Number( ev.target.value ) }) } } />
                    </div>
                </div>
                <div className="footer">
                    <Button variant="primary" onClick={ addSale } size="large" disabled={ loading }> Subir Venta </Button>
                </div>
            </div>
        </div>

      </Modal>
    </div>
  )
}

export default NewSaleModal