const clientssReducer = ( clients = {
  list: { loading: true, error: null, data: null },
  groups: { loading: true, error: null, data: null }
}, action ) => {

    // CLIENTS

    if ( action.type === 'START_FETCH_CLIENTS' ){
      return {
        ...clients,
        list: { loading: true, error: null, data: null }
      };
    }

    if ( action.type === 'FAIL_FETCH_CLIENTS' ){
      return {
        ...clients,
        list: { loading: false, error: true, data: null }
      };
    }

    if ( action.type === 'RECEIVE_FETCH_CLIENTS' ){
      return {
        ...clients,
        list: { loading: false, error: true, data: action.payload }
      };
    }

    // CLIENTS GROUPS

    if ( action.type === 'START_FETCH_CLIENTS_GROUPS' ){
      return {
        ...clients,
        groups: { loading: true, error: null, data: null }
      };
    }

    if ( action.type === 'FAIL_FETCH_CLIENTS_GROUPS' ){
      return {
        ...clients,
        groups: { loading: false, error: true, data: null }
      };
    }

    if ( action.type === 'RECEIVE_FETCH_CLIENTS_GROUPS' ){
      return {
        ...clients,
        groups: { loading: false, error: true, data: action.payload }
      };
    }
  
    return clients;
  
  }
  
  export default clientssReducer;
  