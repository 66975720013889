import productsService from '../../services/Products';
import productsGroupsService from '../../services/ProductsGroups';
import productsLinesService from '../../services/ProductsLines';

export const getProducts = () => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_PRODUCTS' } )

    productsService.get()
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_PRODUCTS',
          payload: response.data.data
        })

      })
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_PRODUCTS'
        })

      })
  }

}

export const getProductsGroups = () => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_PRODUCTS_GROUPS' } )

    productsGroupsService.get()
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_PRODUCTS_GROUPS',
          payload: response.data.data
        })

      })
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_PRODUCTS_GROUPS'
        })

      })

  }

}

export const getProductsLines = () => {

    return ( dispatch ) => {

      dispatch( { type: 'START_FETCH_PRODUCTS_LINES' } )

      productsLinesService.get()
        .then( response => {
  
          dispatch({
            type: 'RECEIVE_FETCH_PRODUCTS_LINES',
            payload: response.data.data
          })
  
        })
        .catch( err => {
  
          dispatch({
            type: 'FAIL_FETCH_PRODUCTS_LINES'
          })
  
        })
  
    }
  
  }