import exchangeService from '../../services/Exchange';

export const getExchanges = () => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_EXCHANGES' } )

    exchangeService.get()
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_EXCHANGES',
          payload: response.data.data
        })

      } )
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_EXCHANGES'
        })

      })
    
  }

}
