import moment from "moment"

const MIN_YEAR = 2021;

const campaignReducer = ( state = moment().year(), action ) => {

    if ( action.type === 'SET_CAMPAIGN' ){
  
      if ( action.payload >= MIN_YEAR ){

        return action.payload
        
      }
  
    }

    return state
  
  }
  
  export default campaignReducer
  