import Api from './Api';

const authService = {
  isAuthenticated: () => {
    const cookies = document.cookie
  
    return cookies.indexOf('vetanco.ventas.auth') > -1
  },
  getSelfData: (  ) => {

    return Api.get( '/users/self', {
      withCredentials: true
    } )

  },
  updateLocale: ( newLocale ) => {
    return Api.put( '/usuario', { idioma: newLocale },{
      withCredentials: true
    } )
  },
  updatePassword: ( passwordData ) => {
    return Api.post( '/usuario/password/cambio', passwordData, {
      withCredentials: true
    })
  },
  updatePasswordOutside: ( passwordData ) => {
    return Api.post( '/usuario/password/cambio/outside', passwordData)
  },
  recoveryPasswordPetition: ( emailData ) => {
    return Api.post( '/usuario/password/recuperar', emailData)
  },
  ping: () => {

    return Api.get('/ping', {
      withCredentials: true
    })

  }
};

export default authService
