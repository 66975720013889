import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';
import salesService from '../../../../services/Sales';
import { EmptyContainer } from './EmptyContainer';
import { FaArrowsAltV } from 'react-icons/fa';
import Currency from '../../../../components/Currency';

// COMPONENTS

// ITEM LIST HEADER
const SaleListItemHeader = ({ monthYearSelected, reloadAction, edition, setOrderedList }) => {

  const handleDeleteAll = () => {

    deleteMonthSales( monthYearSelected.year, monthYearSelected.month, reloadAction )

  }

  return (
    <div className="list-item header">
      <div className="column-data"> Fecha <div onClick={ () => { setOrderedList( 'DATE' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Cliente <div onClick={ () => { setOrderedList( 'CLIENT' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Producto <div onClick={ () => { setOrderedList( 'PRODUCT' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Cantidad <div onClick={ () => { setOrderedList( 'CANTIDAD' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Importe Total <div onClick={ () => { setOrderedList( 'TOTAL' ) } }> <FaArrowsAltV /> </div> </div>
      { edition && <div className="column-data"> <Button danger type="primary" onClick={ handleDeleteAll }> Eliminar todo </Button> </div> }
    </div>
  )
}

// ITEM LIST DEL BODY
const SaleListItem = ( { sale, products, clients, reloadAction, openEditionModal, edition } ) => {

  const product = products.list.data && products.list.data.find( p => p.id === sale.product ).name || ''
  const client = clients.list.data && clients.list.data.find( c => c.id === sale.client ).name || ''

  return (
    <div className="list-item">
      <div className="column-data"> { sale.date } </div>
      <div className="column-data"> { client } </div>
      <div className="column-data"> { product } </div>
      <div className="column-data"> { sale.q } </div>
      <div className="column-data"> { sale.total.toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ sale.currency } /> </span> </div>
      {
        edition &&
        <div className='column-data actions'>
          <div className='action edition' onClick={ openEditionModal }> <HiOutlinePencil /> </div>
          <div className='action delete' onClick={ () => { deleteSale( sale.id, reloadAction ) } }> <FiTrash /> </div>
        </div>
      }
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const SalesList = ( { sales, products, clients, reloadAction, openEditionModal, monthYearSelected, edition } ) => {

  const [ activePage, setActivePage ] = useState( 1 );
  const [ orderedList, setOrderedList ] = useState( { orderby: { item: null, desc: false }, list: sales } )
  const pageSize = 20;

    // EFECTO PARA ORDENAR LISTA
    useEffect( () => {

      let ordered = sales
      if ( orderedList.orderby.item === 'DATE' ){
  
        ordered = sales.sort( ( sale1, sale2 ) => {
  
          if ( orderedList.orderby.desc ){
  
            if ( sale1.date < sale2.date ) {
              return 1;
            }
            if ( sale1.date > sale2.date ) {
              return -1;
            }
            return 0;
  
          } else {
  
            if ( sale1.date > sale2.date ) {
              return 1;
            }
            if ( sale1.date < sale2.date ) {
              return -1;
            }
            return 0;
  
          }
          
        } );
  
      } else if ( orderedList.orderby.item === 'CLIENT' ){
  
        ordered = sales.sort( ( sale1, sale2 ) => {
  
          const client1 = clients.list.data && clients.list.data.find( c => c.id === sale1.client )
          const client2 = clients.list.data && clients.list.data.find( c => c.id === sale2.client )
  
          if ( orderedList.orderby.desc ){
  
            if ( ( client1 && client1.name || '' ) < ( client2 && client2.name || '' ) ) {
              return 1;
            }
            if ( ( client1 && client1.name || '' ) > ( client2 && client2.name || '' ) ) {
              return -1;
            }
            return 0;
  
          } else {
  
            if ( ( client1 && client1.name || '' ) > ( client2 && client2.name || '' ) ) {
              return 1;
            }
            if ( ( client1 && client1.name || '' ) < ( client2 && client2.name || '' ) ) {
              return -1;
            }
            return 0;
  
          }
  
        } );
  
      } else if ( orderedList.orderby.item === 'PRODUCT' ){
      
        ordered = sales.sort( ( sale1, sale2 ) => {
  
          const product1 = products.list.data && products.list.data.find( c => c.id === sale1.client )
          const product2 = products.list.data && products.list.data.find( c => c.id === sale2.client )
  
          if ( orderedList.orderby.desc ){
  
            if ( ( product1 && product1.name || '' ) < ( product2 && product2.name || '' ) ) {
              return 1;
            }
            if ( ( product1 && product1.name || '' ) > ( product2 && product2.name || '' ) ) {
              return -1;
            }
            return 0;
  
          } else {
  
            if ( ( product1 && product1.name || '' ) > ( product2 && product2.name || '' ) ) {
              return 1;
            }
            if ( ( product1 && product1.name || '' ) < ( product2 && product2.name || '' ) ) {
              return -1;
            }
            return 0;
  
          }
  
        } );
  
      } else if ( orderedList.orderby.item === 'CANTIDAD' ){
  
        ordered = sales.sort( ( sale1, sale2 ) => {
  
          if ( orderedList.orderby.desc ){
  
            if ( sale1.q < sale2.q ) {
              return 1;
            }
            if ( sale1.q > sale2.q ) {
              return -1;
            }
            return 0;
  
          } else {
  
            if ( sale1.q > sale2.q ) {
              return 1;
            }
            if ( sale1.q < sale2.q ) {
              return -1;
            }
            return 0;
  
          }
          
        } );
  
      } else if ( orderedList.orderby.item === 'TOTAL' ){
  
        ordered = sales.sort( ( sale1, sale2 ) => {
  
          if ( orderedList.orderby.desc ){
  
            if ( sale1.total < sale2.total ) {
              return 1;
            }
            if ( sale1.total > sale2.total ) {
              return -1;
            }
            return 0;
  
          } else {
  
            if ( sale1.total > sale2.total ) {
              return 1;
            }
            if ( sale1.total < sale2.total ) {
              return -1;
            }
            return 0;
  
          }
          
        } );
  
      }
  
      setOrderedList( {
        ...orderedList,
        list: ordered
      } )
  
    }, [ sales, orderedList.orderby ] )

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = orderedList.list
                      .map( sale => <SaleListItem 
                                                key={ sale.id }
                                                sale={ sale }
                                                products={ products }
                                                clients={ clients }
                                                edition={ edition }
                                                openEditionModal={ () => { openEditionModal( sale.id ) } }
                                                reloadAction={ reloadAction }  /> )

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="list-container">
      <div className="list">
        <SaleListItemHeader monthYearSelected={ monthYearSelected } reloadAction={ reloadAction } edition={ edition } setOrderedList={ ( orderby ) => { setOrderedList( { ...orderedList, orderby: { item: orderby, desc: !orderedList.orderby.desc } } ) } } />
        { 
        orderedList.list.length
        ?
        <div className='paginated-list'>
          { pageList.slice( ( activePage - 1 ) * pageSize , activePage * pageSize) }
          <div className="pagination" style={ pageList.length <= pageSize ? { display: 'none' } : { } }>
            <Pagination
              activePage={ activePage }
              itemsCountPerPage={ pageSize }
              totalItemsCount={ pageList.length }
              pageRangeDisplayed={ pageSize }
              onChange={ page => setActivePage( page ) }
              hideFirstLastPages={ true }
              prevPageText="«"
              nextPageText="»"
            />
          </div>
        </div>
        :
        <EmptyContainer
                      title="Sin registros"
                      message="Aún no fueron cargados registros de ventas para este mes" />
        }
      </div>
    </div>
  )
}



// FUNCTIONS

// BAJA DE USUARIO
const deleteSale = ( saleId, reloadAction ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Eliminar Producto',
            text: 'Estás seguro que deseas eliminar el producto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, eliminarlo!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return salesService.delete( saleId )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Eliminado!',
                'Venta eliminada exitosamente!',
                'success'
              )

              reloadAction();
            }
          })
}

const deleteMonthSales = ( year, month, reloadAction ) => {

  Swal.fire({
    title: 'Eliminar Ventas del mes',
    text: 'Estás seguro que deseas eliminar todas las ventas del mes?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Si, eliminarlas!',
    showLoaderOnConfirm: true,
    // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
    preConfirm: ( value ) => {
      return salesService.deleteMonth( year, month )
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
    if ( result.value ) {
      Swal.fire(
        'Eliminadas!',
        'Ventas eliminadas exitosamente!',
        'success'
      )

      reloadAction();
    }
  })

}

export default SalesList
