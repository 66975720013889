import React, { useState, useEffect } from 'react';
import { FiTrash } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { FaArrowsAltV } from 'react-icons/fa';
import Swal from 'sweetalert2';
import clientsGroupsService from '../../../../services/ClientsGroups';

// COMPONENTS

// ITEM LIST HEADER
const GroupListItemHeader = ({ setOrderedList, edition }) => {

  return (
    <div className="list-item header">
      <div className="column-data"> Nombre <div onClick={ () => { setOrderedList( 'NAME' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Cantidad de clientes </div>
      { edition && <div className="column-data"> <FiTrash /> </div> }
    </div>
  )
}

// ITEM LIST DEL BODY
const GroupListItem = ( { group, clients, reloadAction, setNewGroup, edition, campaign } ) => {

  return (
    <div className="list-item">
      <div className="column-data"> { group.name } </div>
      <div className='column-data'> { group.clients_number } </div>
      {
        edition &&
        <div className='column-data actions'> 
          <div className='action delete' onClick={ () => { deleteGroup( group.id, reloadAction, campaign ) } }> <FiTrash /> </div>
        </div>
      }
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const GroupsList = ( { clients, groups, reloadAction, setNewGroup, edition, campaign } ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ activePage, setActivePage ] = useState( 1 );
  const [ filteredList, setFilteredList ] = useState( [] );
  const [ orderedList, setOrderedList ] = useState( { orderby: { item: 'NAME', desc: false }, list: clients } )
  const pageSize = 5;

  // EFECTO PARA ORDENAR LISTA
  useEffect( () => {

    let ordered = groups
    if ( orderedList.orderby.item === 'NAME' ){

        ordered = groups.sort( ( groupData1, groupData2 ) => {
    
            if ( orderedList.orderby.desc ){
    
              if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
                return 1;
              }
              if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
                return -1;
              }
              return 0;
    
            } else {
    
              if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
                return 1;
              }
              if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
                return -1;
              }
              return 0;
    
            }
    
          } );
    }

    setOrderedList( {
      ...orderedList,
      list: ordered
    } )

  }, [ groups, orderedList.orderby ] )

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = orderedList.list
                      .map( group => <GroupListItem 
                                                key={ group.id } 
                                                group={ group } 
                                                clients={ clients }
                                                edition={ edition }
                                                campaign={ campaign }
                                                setNewGroup={ setNewGroup }
                                                reloadAction={ reloadAction } /> )
                      // .slice( ( activePage - 1 ) * pageSize , ( activePage - 1 ) + pageSize);

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="list-container">
      <div className="list">
        <GroupListItemHeader edition={ edition } setOrderedList={ ( orderby ) => { setOrderedList( { ...orderedList, orderby: { item: orderby, desc: !orderedList.orderby.desc } } ) } } />
        { pageList }
      </div>
      {/* <div className="pagination" style={ filteredList.length <= pageSize ? { display: 'none' } : { } }>
        <Pagination
              activePage={ activePage }
              itemsCountPerPage={ pageSize }
              totalItemsCount={ filteredList.length }
              pageRangeDisplayed={ pageSize }
              onChange={ page => setActivePage( page ) }
              hideFirstLastPages={ true }
              prevPageText="«"
              nextPageText="»"
            />
      </div> */}
    </div>
  )
}

// FUNCTIONS

// BAJA DE USUARIO
const deleteGroup = ( groupId, reloadAction, campaign ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Desasociar clientes del grupo',
            text: 'Recuerda que al desasociar los clientes de este grupo, los mismos se moveran al grupo "OTROS" para EL AÑO: ' + campaign ,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, desasociar clientes!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return clientsGroupsService.delete( groupId, campaign )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Eliminado!',
                'Grupo eliminado exitosamente!',
                'success'
              )

              reloadAction();
            }
          })
}

export default GroupsList
