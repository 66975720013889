import React from 'react';
import { Select, Switch } from 'antd';

const { Option } = Select;

const Filter = ( { filters, setFilters, clients, products } ) => {

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilters( { ...filters, string: value } )
  }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <div className="actions">
        <div className="search">
          <input name="user" placeholder="Buscar..." value={ filters.string } onChange={ handleChange } />
        </div>
      </div>
      {
        products && products.list &&
        <div className='selector'>
          <Select
                  allowClear
                  showSearch
                  style={{ width: 200 }}
                  size={ [ 200, 36 ] }
                  value={ filters.product }
                  onChange={ value => { setFilters( { ...filters, product: value } ) } }
                  placeholder="Filtrar producto"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {
                    products.list.data.map( product => <Option key={ product.id } value={ product.id }>{ product.name }</Option>)
                  }
            </Select>
          </div>
      }
      {
        clients && clients.groups &&
        <div className='selector'>
          <Select
                  allowClear
                  showSearch
                  style={{ width: 200 }}
                  value={ filters.client_group }
                  onChange={ value => { setFilters( { ...filters, client_group: value } ) } }
                  placeholder="Filtrar grupo"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {
                    clients.groups.data.map( client_group => <Option key={ client_group.id } value={ client_group.id }>{ client_group.name }</Option>)
                  }
            </Select>
          </div>
      }
      <div className='switch'>
        <div className='tag'>Solo pronosticados</div>
        <div className='data'> <Switch checked={ filters.justProductsWithData } onChange={ checked => { setFilters({ ...filters, justProductsWithData: checked }) } } /> </div>
      </div>
    </div>
  )
}

export default Filter