import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { FiUsers } from 'react-icons/fi'
import Filter from './components/Filter'
import ClientsList from './components/ClientsList'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import NewClient from './components/NewClient'
import { getClients, getClientsGroups } from '../../../store/actions/getClients'
import { LoadingPage } from '../loading/LoadingPage'
import { Link } from 'react-router-dom'
import './clients.css'

const ClientsPage = ({ clients_status, getClients, getClientsGroups, campaign, userdata }) => {

  const [ filterString, setFilterString ] = useState( '' );
  const [ clientsListFiltered, setClientsListFiltered ] = useState( [] )
  const [ newClient, setNewClient ] = useState( { visible: false, editionClient: null, editionLimited: null  } )

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    
    if ( !clients_status.list.loading && clients_status.list.data && !clients_status.groups.loading && clients_status.groups.data ){

      const fullList = clients_status.list.data.filter( client => {

        const client_group = clients_status.groups.data.find( cg => cg.id === client.group )

        const stringToMatch = `${ client.name } ${ client.code } ${ client_group.name }`
  
        return stringToMatch.toUpperCase().includes( filterString.toUpperCase() )
  
      } )
  
      setClientsListFiltered( fullList );

    }

  }, [ filterString, clients_status.list, clients_status.groups ] )

  if ( clients_status.list.loading || clients_status.groups.loading || userdata.loading ) return <LoadingPage />

  return (
    <div className='page clients page-list'>
        <div className='row'>
          <PageHeader title="Clientes" icon={ <FiUsers /> } />
          {
            userdata.data.type === 10 &&
            <div className='actions'>
              <div className='action'>
                <Link to="/main/clients/groups">
                  <Button type="text" size="middle" > Administrar grupos </Button>
                </Link>
              </div>
              <div className='action add'>
                <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewClient( { visible: true, editionClient: null } ) } } > Crear Nuevo </Button>
              </div>
            </div>
          }
        </div>
        <div className='body'>

            <Filter filterString={ filterString } setFilterString={ setFilterString } />

            <ClientsList 
                      clients={ clientsListFiltered } 
                      groups={ clients_status.groups.data } 
                      setNewClient={ setNewClient }
                      edition={ userdata.data.type === 10 }
                      campaign={ campaign }
                      reloadAction={ () => { getClients( campaign ) } } />

        </div>
        { newClient.visible && <NewClient 
                                    handlerClose={ () => { setNewClient( { visible: false, editionClient: null } ) } } 
                                    groups={ clients_status.groups.data } 
                                    refreshAction={ () => { getClients( campaign ) } }
                                    campaign={ campaign }
                                    refreshGroups={ () => { getClientsGroups( campaign ) } }
                                    editionClient={ newClient.editionClient }
                                    editionLimited={ newClient.editionLimited }
                                     /> }
    </div>
  )
}

export default connect( state => ({ userdata: state.userdata, clients_status: state.clients, campaign: state.campaign } ), { getClients, getClientsGroups } )( ClientsPage )