import React, { useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import Swal from 'sweetalert2';
import forecastService from '../../../../services/Forecast';
import { HiOutlinePencil } from 'react-icons/hi';
import { EmptyContainer } from '../../sales/components/EmptyContainer';
import Pagination from 'react-js-pagination';
import { Button } from 'antd';

// COMPONENTS

// ITEM LIST HEADER
const ForecastListItemHeader = ({ reloadAction, yearSelected, edition }) => {

  const handleDeleteAll = () => {

    deleteYearForecasts( yearSelected, reloadAction )

  }

  return (
    <div className="list-item header">
      <div className="column-data"> Grupo Economico </div>
      <div className="column-data"> Producto </div>
      <div className="column-data"> Precio unitario </div>
      <div className="column-data"> Total Anual </div>
      { edition && <div className="column-data"> <Button danger type="primary" onClick={ handleDeleteAll }> Eliminar todo </Button> </div> }
    </div>
  )
}

// ITEM LIST DEL BODY
const ForecastListItem = ( { forecast, products, clients, reloadAction, openEditionModal, edition } ) => {

  let name = ''
  if ( forecast.product_type === 1 ){
    
    const product = products.list.data && products.list.data.find( p => p.id === forecast.product )
    name = product && product.name || '' 
    
  } else if ( forecast.product_type === 2 ){
    
    const group = products.groups.data && products.groups.data.find( p => p.id === forecast.product )
    name = group && group.name || '' 

  } else if ( forecast.product_type === 3 ){
    
    const line = products.lines.data && products.lines.data.find( p => p.id === forecast.product )
    name = line && line.name || '' 

  }
  const client_group = clients.groups.data && clients.groups.data.find( c => c.id === forecast.client_group )
  const client_name = client_group && client_group.name || ''
  
  return (
    <div className="list-item column">
      <div className='row-data'>
        <div className="column-data"> { client_name } </div>
        <div className="column-data"> { name } </div>
        <div className="column-data"> { forecast.product_price } <div className='exchange-tag'>USD</div> </div>
        <div className="column-data"> { forecast.product_price && calculateYearValuePrice( { data: forecast, product_price: forecast.product_price } ).toLocaleString( 'es-AR' ) } <div className='exchange-tag'>USD</div> </div>
        {
          edition &&
          <div className='column-data actions'>
            <div className='action edition' onClick={ openEditionModal }> <HiOutlinePencil /> </div>
            <div className='action delete' onClick={ () => { deleteForecast( forecast.id, reloadAction ) } }> <FiTrash /> </div>
          </div>
        }
      </div>
      <hr />
      <div className='row-data'>
        <ForecastYearQData data={ forecast } />
      </div>
    </div>
  )
}

const ForecastYearQData = ({ data }) => {

  const {
    q_enero,
    q_febrero,
    q_marzo,
    q_abril,
    q_mayo,
    q_junio,
    q_julio,
    q_agosto,
    q_septiembre,
    q_octubre,
    q_noviembre,
    q_diciembre
  } = data

  const total = (
    ( q_enero || 0 )      +
    ( q_febrero || 0 )    +
    ( q_marzo || 0 )      +
    ( q_abril || 0 )      +
    ( q_mayo || 0 )       +
    ( q_junio || 0 )      +
    ( q_julio || 0 )      +
    ( q_agosto || 0 )     +
    ( q_septiembre || 0 ) +
    ( q_octubre || 0 )    +
    ( q_noviembre || 0 )  +
    ( q_diciembre || 0 )
  )

  return (
      <div className='year-q-data'>
          <div className='year-q-data-table'>
            <div className="column-data">
              <div className='header'>Enero</div>
              <div className='value'> { q_enero } </div>
            </div>
            <div className="column-data">
              <div className='header'>Febrero</div>
              <div className='value'> { q_febrero } </div>
            </div>
            <div className="column-data">
              <div className='header'>Marzo</div>
              <div className='value'> { q_marzo } </div>
            </div>
            <div className="column-data">
              <div className='header'>Abril</div>
              <div className='value'> { q_abril } </div>
            </div>
            <div className="column-data">
              <div className='header'>Mayo</div>
              <div className='value'> { q_mayo } </div>
            </div>
            <div className="column-data">
              <div className='header'>Junio</div>
              <div className='value'> { q_junio } </div>
            </div>
            <div className="column-data">
              <div className='header'>Julio</div>
              <div className='value'> { q_julio } </div>
            </div>
            <div className="column-data">
              <div className='header'>Agosto</div>
              <div className='value'> { q_agosto } </div>
            </div>
            <div className="column-data">
              <div className='header'>Septiembre</div>
              <div className='value'> { q_septiembre } </div>
            </div>
            <div className="column-data">
              <div className='header'>Octubre</div>
              <div className='value'> { q_octubre } </div>
            </div>
            <div className="column-data">
              <div className='header'>Noviembre</div>
              <div className='value'> { q_noviembre } </div>
            </div>
            <div className="column-data">
              <div className='header'>Diciembre</div>
              <div className='value'> { q_diciembre } </div>
            </div>
            <div className="column-data total">
              <div className='header'>Total</div>
              <div className='value'> { total } </div>
            </div>
          </div>
      </div>
  )

}

// LISTA COMPLETA DE USUARIOS
const ForecastsList = ( { forecasts, products, clients, reloadAction, openEditionModal, yearSelected, edition } ) => {

  const [ activePage, setActivePage ] = useState( 1 );
  const pageSize = 20;

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = forecasts
                      .map( forecast => <ForecastListItem 
                                                key={ forecast.id }
                                                forecast={ forecast }
                                                products={ products }
                                                clients={ clients }
                                                edition={ edition }
                                                openEditionModal={ () => { openEditionModal( forecast.id ) } }
                                                reloadAction={ reloadAction }  /> )

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="list-container">
      <div className="list">
        <ForecastListItemHeader edition={ edition } reloadAction={ reloadAction } yearSelected={ yearSelected } />
        { 
        pageList.length
        ?
        <div className='paginated-list'>
          { pageList.slice( ( activePage - 1 ) * pageSize , activePage * pageSize) }
          <div className="pagination" style={ pageList.length <= pageSize ? { display: 'none' } : { } }>
            <Pagination
              activePage={ activePage }
              itemsCountPerPage={ pageSize }
              totalItemsCount={ pageList.length }
              pageRangeDisplayed={ pageSize }
              onChange={ page => setActivePage( page ) }
              hideFirstLastPages={ true }
              prevPageText="«"
              nextPageText="»"
            />
          </div>
        </div>
        :
        <EmptyContainer
                    title="Sin registros"
                    message="Aún no fueron cargados registros de pronósticos para estos filtros" />
        }
      </div>
    </div>
  )
}

// BAJA DE USUARIO
const deleteForecast = ( forecastId, reloadAction ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Eliminar Producto',
            text: 'Estás seguro que deseas eliminar el producto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, eliminarlo!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return forecastService.delete( forecastId )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Eliminado!',
                'Venta eliminada exitosamente!',
                'success'
              )

              reloadAction();
            }
          })
}

function calculateYearValuePrice( { data, product_price } ){

  const {
    q_enero,
    q_febrero,
    q_marzo,
    q_abril,
    q_mayo,
    q_junio,
    q_julio,
    q_agosto,
    q_septiembre,
    q_octubre,
    q_noviembre,
    q_diciembre
  } = data

  return Math.round(
    ( Number( q_enero ) || 0 ) +
    ( Number( q_febrero ) || 0 ) +
    ( Number( q_marzo ) || 0 ) +
    ( Number( q_abril ) || 0 ) +
    ( Number( q_mayo ) || 0 ) +
    ( Number( q_junio ) || 0 ) +
    ( Number( q_julio ) || 0 ) +
    ( Number( q_agosto ) || 0 ) +
    ( Number( q_septiembre ) || 0 ) +
    ( Number( q_octubre ) || 0 ) +
    ( Number( q_noviembre ) || 0 ) +
    ( Number( q_diciembre ) || 0 )
  ) * product_price

}

export default ForecastsList


const deleteYearForecasts = ( year, reloadAction ) => {

  Swal.fire({
    title: 'Eliminar Pronosticos del año',
    text: 'Estás seguro que deseas eliminar todos los pronósticos del año?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Si, eliminarlos!',
    showLoaderOnConfirm: true,
    // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
    preConfirm: ( value ) => {
      return forecastService.deleteYear( year )
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
    if ( result.value ) {
      Swal.fire(
        'Eliminadas!',
        'Pronósticos eliminados exitosamente!',
        'success'
      )

      reloadAction();
    }
  })

}