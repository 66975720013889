import React, { useState, useEffect } from 'react';
import { FiTrash } from 'react-icons/fi';
import { BiUserCheck } from 'react-icons/bi';
import Swal from 'sweetalert2';
import sellersService from '../../../../services/Sellers';

// COMPONENTS

// ITEM LIST HEADER
const SellerListItemHeader = () => {

  return (
    <div className="list-item header">
      <div className="column-data"> Estado </div>
      <div className="column-data"> Nombre </div>
      <div className="column-data"> Apellido </div>
      <div className="column-data"> Email </div>
      <div className="column-data"> Acciones </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const SellerListItem = ( { seller, reloadAction } ) => {

  return (
    <div className="list-item">
      <div className="column-data"> <div className={ `status ${ seller.suspended ? 'suspended' : 'activated' }` } ></div> </div>
      <div className="column-data"> { seller.firstname } </div>
      <div className="column-data"> { seller.lastname } </div>
      <div className="column-data"> { seller.email } </div>
      <div className='column-data actions'> 
        { !seller.suspended && <div className='action delete' onClick={ () => { suspendSeller( seller.id, reloadAction ) } }> <FiTrash /> </div> }
        { seller.suspended && <div className='action activate' onClick={ () => { activateSeller( seller.id, reloadAction ) } }> <BiUserCheck /> </div> }
      </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const SellersList = ( { sellers, reloadAction } ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ activePage, setActivePage ] = useState( 1 );
  const [ filteredList, setFilteredList ] = useState( [] );
  const pageSize = 5;

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = sellers
                      .map( seller => <SellerListItem 
                                                key={ seller.id } 
                                                seller={ seller } 
                                                reloadAction={ reloadAction } /> )
                      // .slice( ( activePage - 1 ) * pageSize , ( activePage - 1 ) + pageSize);

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="list-container">
      <div className="list">
        <SellerListItemHeader />
        { pageList }
      </div>
    </div>
  )
}

// FUNCTIONS

// BAJA DE USUARIO
const suspendSeller = ( sellerId, reloadAction ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Suspender vendedor',
            text: 'Estás seguro que deseas suspender el vendedor?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, suspender!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return sellersService.suspend( sellerId )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Eliminado!',
                'Vendedor eliminado exitosamente!',
                'success'
              )

              reloadAction();
            }
          })
}

const activateSeller = ( sellerId, reloadAction ) => {
    // DISPARO ALERT DE CONFIRMACION
    Swal.fire({
              title: 'Activar vendedor',
              text: 'Estás seguro que deseas activar el usuario del vendedor?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Si, volver a activar!',
              showLoaderOnConfirm: true,
              // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
              preConfirm: ( value ) => {
                return sellersService.activate( sellerId )
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
              if ( result.value ) {
                Swal.fire(
                  'Activado!',
                  'Vendedor activado exitosamente!',
                  'success'
                )
  
                reloadAction();
              }
            })
  }

export default SellersList
