import { Button, Radio, Spin, Switch } from 'antd'
import React, { useState, useEffect } from 'react'
import './product-card.css'
import { Select } from 'antd';
import graphService from '../../../../../../services/Graph'
import { FilterPills } from './/FilterPills';
import Dropdown from 'antd/es/dropdown/dropdown';
import { FilterMenu } from './FilterMenu';
import { BsSliders } from 'react-icons/bs';
import SelectProductModal from './SelectProductModal'

const { Option } = Select;

export const ProductCard = ({ 
    campaign, 
    setGraphFetchStatus, 
    products, 
    productSelected, 
    selectProduct, 
    filters, 
    setFilters, 
    type, 
    client_groups 
}) => {

    const [ selectProductModal, setSelectProductModal ] = useState( { visible: false } )

    useEffect( () => {

        if ( filters.product.data ){
    
          setGraphFetchStatus( { loading: true, error: null, data: null } )
    
          graphService.getProductGraphData( { 
                                                   filters: {
                                                    date: {
                                                      month: filters.date.month, 
                                                      year: filters.date.year || campaign, 
                                                    },
                                                    product: {
                                                        groupby: filters.product.groupby,
                                                        id: filters.product.data.id
                                                    },
                                                    client_groups: filters.client_groups,
                                                    exchange: filters.exchange,
                                                    ignore_null_sales: false // filters.ignore_null_sales
                                                   }
                                                  } )
            .then( res => {
              setGraphFetchStatus( { loading: false, error: null, data: res } )
            } )
            .catch( err => {
              setGraphFetchStatus( { loading: false, error: true, data: null } )
            } )
        }
    
      }, [ filters.product.groupby, filters.client_groups, filters.product.data, filters.date, filters.exchange, filters.products, filters.ignore_null_sales, campaign ] )

    if ( !productSelected ) return <div className={ `card product ${ type }` }> <Spin /> </div>

    return (
        <div className={ `card product ${ type }` }>

            <div className="data">
                <div className='color-tag'></div>

                <div className='product-selected'>
                    <div className='row'>
                        <div className='product-name'>{ productSelected.name }</div>
                        <div className='action'>
                            <Button onClick={ () => { setSelectProductModal( { visible: true } ) } }>Cambiar</Button>
                        </div>
                    </div>
                </div>
                {
                    selectProductModal.visible && 
                    <SelectProductModal
                                products={ products }
                                handlerClose={ () => { setSelectProductModal( { visible: false } ) } }
                                selectProduct={ selectProduct }
                                groupby={ filters.product.groupby }
                                type={ type }
                                    />
                }
                <div className='separator' />

                <FilterPills filters={ filters } client_groups={client_groups} />
            </div>

            <div className="actions">
                <Dropdown
                    trigger={['click']}
                    dropdownRender={
                        () => <FilterMenu 
                                filters={ filters } 
                                setFilters={ setFilters }
                                products={products}
                                selectProduct={selectProduct}
                                client_groups={client_groups} />
                    }>
                    <Button type="link" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <BsSliders />
                        Filtrar
                    </Button>
                </Dropdown>
            </div>

        </div>
    )

}