import React from 'react';
import { IconContext } from "react-icons";

import './pageHeader.css'

const PageHeader = ( { title, icon, children } ) => {
  return (
    <div className="page-header">
      <div className='left'>
        <div className="title"> { title } </div>
        <div className="separator"> | </div>
        <div className="icon">
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              { icon }
            </div>
          </IconContext.Provider>
        </div>
      </div>
      <div className='actions'>
        {
          children
        }
      </div>
    </div>
  )
}

export default PageHeader
