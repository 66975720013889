import { combineReducers } from 'redux';
import campaignReducer from './campaignReducer';
import clientssReducer from './clientsReducer';
import exchangesReducer from './exchangeReducer';
import productssReducer from './productsReducer';
import sellersReducer from './sellersReducer';

import sidenavReducer from './sidenavReducer';
import userdataReducer from './userdataReducer';

const reducers = combineReducers({
  sidenavOpen: sidenavReducer,
  clients: clientssReducer,
  products: productssReducer,
  exchanges: exchangesReducer,
  sellers: sellersReducer,
  userdata: userdataReducer,
  campaign: campaignReducer
})

export default reducers;
