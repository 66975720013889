const exchangesReducer = ( exchanges = { loading: true, error: null, data: null }, action ) => {

      if ( action.type === 'START_FETCH_EXCHANGES' ){
        return { loading: true, error: null, data: null };
      }
  
      if ( action.type === 'FAIL_FETCH_EXCHANGES' ){
        return { loading: false, error: true, data: null };
      }
  
      if ( action.type === 'RECEIVE_FETCH_EXCHANGES' ){
        return { loading: false, error: true, data: [ ...action.payload ] };
      }

      return exchanges;
    
    }
    
export default exchangesReducer;
    