import React, { useEffect, useState } from 'react'
import { FiUsers } from 'react-icons/fi'
import { connect } from 'react-redux'
import { Button, Switch } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import GroupsList from './GroupsList'
import NewGroup from './NewGroup'
import Filter from '../components/Filter'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { getClients, getClientsGroups } from '../../../../store/actions/getClients'
import { LoadingPage } from '../../loading/LoadingPage'
import { Link } from 'react-router-dom'
import './GroupsPage.css'

const GroupsPage = ({ clients_status, getClients, getClientsGroups, campaign, userdata }) => {

  const [ filter, setFilter ] = useState( { string: '', withClients: true } );
  const [ groupsListFiltered, setGroupsListFiltered ] = useState( [] )
  const [ newGroup, setNewGroup ] = useState( { visible: false, editionGroup: null  } )

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    
    if ( !clients_status.groups.loading && clients_status.groups.data ){

      const fullList = clients_status.groups.data.filter( group => {

        const stringMatch = group.name.toUpperCase().includes( filter.string.toUpperCase() )
        const clientsMatch = filter.withClients ? group.clients_number : true
  
        return stringMatch && clientsMatch
  
      } )
  
      setGroupsListFiltered( fullList );

    }

  }, [ filter, clients_status.groups.data ] )


  if ( clients_status.list.loading || clients_status.groups.loading || userdata.loading ) return <LoadingPage />

  return (
    <div className='page clients page-list'>
        <div className='row'>
          <PageHeader title="Grupos económicos" icon={ <FiUsers /> } />
          <div className='actions'>
            <div className='action'>
                <Link to="/main/clients">
                    <Button type="text" size="middle" > Ver clientes </Button>
                </Link>
            </div>
            <div className='action add'>
              <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewGroup( { visible: true, editionGroup: null } ) } } > Crear Nuevo </Button>
            </div>
          </div>
        </div>
        <div className='body'>

            <Filter filterString={ filter.string } setFilterString={ s => { setFilter({ ...filter, string: s }) } }>
              <div className='switch'>
                <div className='tag'>Mostrar solo grupos con clientes este año</div>
                <div className='data'> <Switch checked={ filter.withClients } onChange={ checked => { setFilter({ ...filter, withClients: checked }) } } /> </div>
              </div>
            </Filter>

            <GroupsList 
                      clients={ clients_status.list.data } 
                      groups={ groupsListFiltered } 
                      setNewGroup={ setNewGroup }
                      campaign={ campaign }
                      edition={ userdata.data.type === 10 }
                      reloadAction={ () => { getClientsGroups( campaign ); getClients( campaign ) } } />

        </div>
        { newGroup.visible && <NewGroup 
                                    handlerClose={ () => { setNewGroup( { visible: false, editionGroup: null } ) } } 
                                    groups={ clients_status.groups.data } 
                                    refreshAction={ () => { getClients( campaign ) } }
                                    refreshGroups={ () => { getClientsGroups( campaign ) } }
                                    editionClient={ newGroup.editionGroup }
                                     /> }
    </div>
  )
}

export default connect( state => ({ userdata: state.userdata, clients_status: state.clients, campaign: state.campaign } ), { getClients, getClientsGroups } )( GroupsPage )