import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, DatePicker, Input, Select } from 'antd';
import { FiUserPlus } from 'react-icons/fi'
import Swal from 'sweetalert2';
import salesService from '../../../../services/Sales';
import moment from 'moment';
import forecastService from '../../../../services/Forecast';
import { YEARS_AVAILABLES } from '../../../../constants/constants';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewForecastModal = ( { year, handlerClose, refreshAction, clients, products, data } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ newForecastData, setNewForecastData ] = useState({
    year: data.editionMode ? data.forecastData.year : year,
    product: data.editionMode ? data.forecastData.product : null,
    client_group: data.editionMode ? data.forecastData.client_group : null,
    product_type: 1,
    q_enero: data.editionMode ? data.forecastData.q_enero : null,
    q_febrero: data.editionMode ? data.forecastData.q_febrero : null,
    q_marzo: data.editionMode ? data.forecastData.q_marzo : null,
    q_abril: data.editionMode ? data.forecastData.q_abril : null,
    q_mayo: data.editionMode ? data.forecastData.q_mayo : null,
    q_junio: data.editionMode ? data.forecastData.q_junio : null,
    q_julio: data.editionMode ? data.forecastData.q_julio : null,
    q_agosto: data.editionMode ? data.forecastData.q_agosto : null,
    q_septiembre: data.editionMode ? data.forecastData.q_septiembre : null,
    q_octubre: data.editionMode ? data.forecastData.q_octubre : null,
    q_noviembre: data.editionMode ? data.forecastData.q_noviembre : null,
    q_diciembre: data.editionMode ? data.forecastData.q_diciembre : null,
  })

  const addForecast = () => {

    if ( !newForecastData.product || !newForecastData.client_group || !newForecastData.year ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando la venta..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    const addForecastAPI = data.editionMode ? forecastService.put : forecastService.post;
    addForecastAPI( { ...newForecastData, id: data?.forecastData?.id } )
      .then( res => {
        refreshAction();
        Swal.close();
        handlerClose()
      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer creation">
            <div className="column">
                <FiUserPlus />
            </div>
            <div className="data">
                <div className="form">
                    <div className='line'>
                      <Select
                                showSearch
                                style={{ width: 200 }}
                                value={ newForecastData.client_group }
                                onChange={ value => { setNewForecastData({ ...newForecastData, client_group: Number( value ) }) } }
                                placeholder="Buscar grupo economico"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {
                                  clients.groups.data.map( client_group => <Option key={ client_group.id } value={ client_group.id }>{ client_group.name }</Option>)
                                }
                        </Select>
                    </div>
                    <div className='line'>
                      <Select
                                showSearch
                                style={{ width: 200 }}
                                value={ newForecastData.product }
                                onChange={ value => { setNewForecastData({ ...newForecastData, product: Number( value ) }) } }
                                placeholder="Buscar producto"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {
                                  products.list.data.map( product => <Option key={ product.id } value={ product.id }>{ product.name }</Option>)
                                }
                        </Select>
                    </div>
                    <div className='line'>
                      <div className='month-item'>
                          <div className='tag'>Enero</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_enero } onChange={ ev => { setNewForecastData({ ...newForecastData, q_enero: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Febrero</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_febrero } onChange={ ev => { setNewForecastData({ ...newForecastData, q_febrero: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Marzo</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_marzo } onChange={ ev => { setNewForecastData({ ...newForecastData, q_marzo: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Abril</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_abril } onChange={ ev => { setNewForecastData({ ...newForecastData, q_abril: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                    </div>
                    <div className='line'>
                      <div className='month-item'>
                          <div className='tag'>Mayo</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_mayo } onChange={ ev => { setNewForecastData({ ...newForecastData, q_mayo: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Junio</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_junio } onChange={ ev => { setNewForecastData({ ...newForecastData, q_junio: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Julio</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_julio } onChange={ ev => { setNewForecastData({ ...newForecastData, q_julio: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Agosto</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_agosto } onChange={ ev => { setNewForecastData({ ...newForecastData, q_agosto: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                    </div>
                    <div className='line'>
                      <div className='month-item'>
                          <div className='tag'>Septiembre</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_septiembre } onChange={ ev => { setNewForecastData({ ...newForecastData, q_septiembre: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Octubre</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_octubre } onChange={ ev => { setNewForecastData({ ...newForecastData, q_octubre: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Noviembre</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_noviembre } onChange={ ev => { setNewForecastData({ ...newForecastData, q_noviembre: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                      <div className='month-item'>
                          <div className='tag'>Diciembre</div>
                          <div className='value'>
                            <Input type="number" placeholder='Unidades' value={ newForecastData.q_diciembre } onChange={ ev => { setNewForecastData({ ...newForecastData, q_diciembre: Number( ev.target.value ) }) } } />
                          </div>
                      </div>
                    </div>
                </div>
                <div className="footer">
                    <Button variant="primary" onClick={ addForecast } size="large" disabled={ loading }> Subir Pronostico </Button>
                </div>
            </div>
        </div>

      </Modal>
    </div>
  )
}

export default NewForecastModal