import React, { useEffect, useState } from 'react'
import { YAxis } from 'recharts'
import { AreaChart } from 'recharts'
import { ResponsiveContainer } from 'recharts'
import { Bar } from 'recharts'
import { Radio, Spin, Switch } from 'antd';
import { BarChart } from 'recharts'
import { Area } from 'recharts'
import { XAxis } from 'recharts'
import { MONTHS } from '../../../../../../constants/constants'
import './forecast-graph.css'
import { Legend } from 'recharts'
import { Tooltip } from 'recharts'
import './forecast-graph.css'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Currency from '../../../../../../components/Currency'

export const ForecastGraph = ({ fetchStatus, products, exchange }) => {

    const [ config, setConfig ] = useState( { chartType: 'BAR', mode: 'Q' } )

    if ( !fetchStatus.data ) return <div className='card graph forecast'><Spin/></div>

    return (
        <div className='card graph forecast'>
            
            <div className='header'>
                <div className='title'> Ventas vs Pronóstico </div>
                <div className='config'>
                    <div className='config-item chart-type'>
                        <Radio.Group value="large" onChange={ e => setConfig({ ...config, chartType: e.target.value }) }>
                            <Radio.Button className={ config.chartType === "LINE" ? 'selected' : '' } value="LINE">Lineal</Radio.Button>
                            <Radio.Button className={ config.chartType === "BAR" ? 'selected' : '' } value="BAR">Barras</Radio.Button>
                            <Radio.Button className={ config.chartType === "TABLE" ? 'selected' : '' } value="TABLE">Tabla</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className='config-item mode'>
                        <Radio.Group value="large" onChange={ e => setConfig({ ...config, mode: e.target.value }) }>
                            <Radio.Button className={ config.mode === 'Q' ? 'selected' : '' } value="Q">Cantidad</Radio.Button>
                            <Radio.Button className={ config.mode === 'PRICE' ? 'selected' : '' } value="PRICE">Valor</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            <div className='body'>

                {
                config.chartType === 'LINE'
                ?
                <LinealGraph 
                            data={ fetchStatus.data.data.data.FORECAST_GRAPH } 
                            mode={ config.mode } 
                            exchange={ exchange } />   
                :
                (
                    config.chartType === 'BAR'
                    ?
                    <BarGraph 
                                data={ fetchStatus.data.data.data.FORECAST_GRAPH } 
                                mode={ config.mode } 
                                exchange={ exchange } />
                    :
                    <TableChart 
                        data={ fetchStatus.data.data.data.FORECAST_GRAPH } 
                        mode={ config.mode } 
                        products={ products.data } 
                        exchange={ exchange }/>
                )
                }

            </div>

        </div>
    )
}


const LinealGraph = ({ data, mode, exchange, filterNulls }) => {

    const dataSource = data
                            .total[ mode === 'Q' ? 'q' : 'price' ]
                            // .filter( d => filterNulls ? d.sales.find( item => item ) : true ) // CREO QUE NO ES CORRECTO FILTRAR EN ESTE GRAFICO

    const dataParsed = MONTHS.map( ( month, index ) => ({
        tag: month.slice( 0, 3 ),
        "Ventas": dataSource.sales[ index ],
        "Pronóstico": dataSource.forecast[ index ]
    }) )  
    
    
    return (
        <div className='forecast-graph-container' >
            <ResponsiveContainer>
                <AreaChart        
                    data={ dataParsed }
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#2490ff" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#3f51b5" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#9c27b0" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#673ab7" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ff9800" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#ff5722" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#cddc39" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#ffc107" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="tag" />
                    <YAxis />
                    <Tooltip  />
                    <Legend  />
                    <Area type="monotone" dataKey="Pronóstico" stroke="#2490ff" fillOpacity={1} fill="url(#colorUv)"  activeDot={{ r: 8 }} />
                    <Area type="monotone" dataKey="Ventas" stroke="#9c27b0" fillOpacity={1} fill="url(#colorPv)" activeDot={{ r: 8 }} />

                </AreaChart>
            </ResponsiveContainer>
        </div>
    )

}

const BarGraph = ({ data, mode, exchange, filterNulls }) => {

    const dataSource = data
                            .total[ mode === 'Q' ? 'q' : 'price' ]
                            // .filter( d => filterNulls ? d.sales.find( item => item ) : true ) // CREO QUE NO ES CORRECTO FILTRAR EN ESTE GRAFICO

    const dataParsed = MONTHS.map( ( month, index ) => ({
        tag: month.slice( 0, 3 ),
        "Ventas": dataSource.sales[ index ],
        "Pronóstico": dataSource.forecast[ index ]
    }) )                          
    
    return (
        <div className='forecast-graph-container' >
            <ResponsiveContainer>
                <BarChart        
                    data={ dataParsed }
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <XAxis dataKey="tag" />
                    <YAxis />
                    <Tooltip  />
                    <Legend />
                    <Bar type="monotone" dataKey="Ventas"  stroke="#2490ff" fill="#2490ff" />
                    <Bar type="monotone" dataKey="Pronóstico" stroke="#3f51b5" fill="#3f51b5" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )

}

const TableChart = ({ data, mode, products, exchange }) => {

    const dataSource = data.partials[ mode === 'Q' ? 'q' : 'price' ]
    const [ search, setSearch ] = useState( '' )
    const [ filteredData, setFilteredData ] = useState( dataSource )
    const history = useHistory()

    useEffect( () => {

        const filtered = dataSource.filter( item => {

            const productdata = products.find( product => product.id == item.product )
            return productdata && productdata.name.toUpperCase().indexOf( search.toUpperCase() ) > -1

        } )

        if ( filtered.length ){

            setFilteredData( filtered )

        } else {

            setFilteredData( dataSource )

        }


    }, [ mode, search ] )

    const totals = dataSource && getTableTotals( dataSource )

    if ( !filteredData || !filteredData.length ){

        return (
            <div className='forecast-table-container' >
                <EmptyContainer title="No hay datos para mostrar" />
            </div>
        )
    }

    return (
        <div className='forecast-table-container totals' >
            <div className='col col-1'>
                <div className='head'>
                    <div className="name" style={{ display: 'flex' }}>
                        <input style={{ flex: 1 }} placeholder="Buscar..." value={ search } onChange={ ev => setSearch( ev.target.value ) } />
                    </div>
                </div>
                <div className='body'>
                    {
                    filteredData.map( ( productItem, index ) => {

                        const productdata = products.find( product => product.id == productItem.product )
                        const handleClick = () => {

                            history.push( `/main/stateprod/${ productdata.id }` )

                        }

                        return (
                            <div className='item' key={ index } onClick={ handleClick }>
                                <div className='name'>{ productdata.name }</div>
                            </div>
                        )

                    } )

                    }
                </div>
            </div>
            <div className='col col-2'>
                <div className='head'>
                    <div className='sub-item month-item'>ENE</div>
                    <div className='sub-item month-item'>FEB</div>
                    <div className='sub-item month-item'>MAR</div>
                    <div className='sub-item month-item'>ABR</div>
                    <div className='sub-item month-item'>MAY</div>
                    <div className='sub-item month-item'>JUN</div>
                    <div className='sub-item month-item'>JUL</div>
                    <div className='sub-item month-item'>AGO</div>
                    <div className='sub-item month-item'>SEP</div>
                    <div className='sub-item month-item'>OCT</div>
                    <div className='sub-item month-item'>NOV</div>
                    <div className='sub-item month-item'>DIC</div>
                    <div className='sub-item total'>Total</div>
                </div>
                <div className='body'>
                    {
                       filteredData.map( ( productItem, index ) => {

                        const totalSales = productItem.sales.reduce( ( prev, curr ) => prev + curr, 0 )
                        const totalForecast = productItem.forecast.reduce( ( prev, curr ) => prev + curr, 0 )

                        return (
                            <div className='item' key={ index }>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 0 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 0 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 1 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 1 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 2 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 2 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 3 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 3 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 4 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 4 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 5 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 5 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 6 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 6 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 7 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 7 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 8 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 8 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 9 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 9 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 10 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 10 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item month-item'>{ Math.round( productItem.sales[ 11 ] ).toLocaleString( 'es-AR' ) }/{ Math.round( productItem.forecast[ 11 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                                <div className='sub-item total'>{ Math.round( totalSales ).toLocaleString( 'es-AR' ) }/{ Math.round( totalForecast ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                            </div>
                        )

                    } )

                    }
                    <div className='item item-total' >
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 0 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 0 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 1 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 1 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 2 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 2 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 3 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 3 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 4 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 4 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 5 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 5 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 6 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 6 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 7 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 7 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 8 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 8 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 9 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 9 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 10 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 10 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item month-item'>{ Math.round( totals.sales[ 11 ].toLocaleString( 'es-AR' ) ) }/{ Math.round( totals.forecast[ 11 ] ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                        <div className='sub-item total'>{ Math.round( totals.totals.sales ).toLocaleString( 'es-AR' ) }/{ Math.round( totals.totals.forecast ).toLocaleString( 'es-AR' ) } { mode === 'PRICE' && <span className='exchange-tag'> <Currency value={ exchange } /> </span> }</div>
                    </div>

                </div>
            </div>
        </div>
    )

}


function getTableTotals( data ){

    const response = data
                        .reduce( ( prev, curr, index ) => {

                            const forecast = []
                            const sales = []

                            Array.from( Array(12).keys() )
                                .map( ( item , i ) => {
                                    forecast[ i ] = prev.forecast[ i ] + curr.forecast[ i ]
                                    sales[ i ] = prev.sales[ i ] + curr.sales[ i ]
                                } )

                            return {
                                forecast,
                                sales
                            }

                        }, { 
                            forecast: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ], 
                            sales: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] 
                        })

    response[ 'totals' ] = {
        forecast: response.forecast.reduce( ( prev, curr ) => prev + curr, 0 ),
        sales: response.sales.reduce( ( prev, curr ) => prev + curr, 0 )
    }

    return response

}