import React from 'react'
import Currency from '../../../../../../components/Currency'
import { MONTHS } from '../../../../../../constants/constants'
import './filter-pills.css'

export const FilterPills = ({
    filters,
    client_groups
}) => {

    return (
        <div className="filter-pills">
            
            <div className="item">{ MONTHS[ filters.date.month ] }</div>

            <div className="item">{filters.exchange === "LOCAL" ? <Currency /> : "USD" }</div>

            <div className="item">{ filters.client_groups.length < client_groups.data.length ? `${ filters.client_groups.length } grupos económicos` : 'Todos los grupos económicos' }</div>

        </div>
    )

}