import React, { useState } from 'react';

const SearchFilter = ( { filterString, setFilterString } ) => {

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilterString( value );
  }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <div className="actions">
        <div className="search">
          <input name="user" placeholder="Buscar..." value={ filterString } onChange={ handleChange } />
        </div>
      </div>

    </div>
  )
}

export default SearchFilter