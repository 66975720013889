import Api from './Api';

const productsGroupsService = {

  get: (  ) => {

    return Api.get( '/products/groups', {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/products/groups', jsonData, {
        withCredentials: true
      } )

  },
  delete: ( usersID ) => {

    return Api.delete( `/products/groups/${ JSON.stringify( usersID ) }`, {
      withCredentials: true
    })
  }

}

export default productsGroupsService
