import { Button, Dropdown, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import './client-card.css'
import graphService from '../../../../../../services/Graph'
import { FilterPills } from './FilterPills'
import { BsSliders } from "react-icons/bs";
import { FilterMenu } from './FilterMenu'
import SelectClientModal from './SelectClientModal'

export const ClientCard = ({ 
    campaign, 
    setGraphFetchStatus, 
    clients, 
    clientGroupSelected, 
    selectClientGroup, 
    filters,
    setFilters, 
    products, 
    type, 
    graphFetchStatus 
}) => {

    const [ selectClientModal, setSelectClientModal ] = useState( { visible: false } )

    // FETCH DATA
    useEffect( () => {

        if ( filters.client_group ){
    
          setGraphFetchStatus( { loading: true, error: null, data: null } )
    
          graphService.getClientGroupGraphData( { 
                                                   filters: {
                                                    date: {
                                                      month: filters.date.month, 
                                                      year: filters.date.year || campaign, 
                                                    },
                                                    client_group: filters.client_group.id,
                                                    sub_clients: filters.sub_clients,
                                                    products: filters.products,
                                                    exchange: filters.exchange,
                                                    ignore_null_sales: false //filters.ignore_null_sales
                                                   }
                                                  } )
            .then( res => {
              setGraphFetchStatus( { loading: false, error: null, data: res } )
            } )
            .catch( err => {
              setGraphFetchStatus( { loading: false, error: true, data: null } )
            } )
        }
    
      }, [ 
          filters.client_group, 
          filters.date, 
          filters.exchange, 
          filters.products, 
          filters.ignore_null_sales, 
          filters.sub_clients, 
          campaign
         ] )

    if ( !clientGroupSelected ) return <div className={ `card client ${ type }` }> <Spin /> </div>

    return (
        <div className={ `card client ${ type }` }>

            <div className='color-tag'></div>
            <div className="data">
                <div className='client-selected'>
                    <div className='row'>
                        <div className='client-name'>{ clientGroupSelected.name }</div>
                        <div className='action'>
                            <Button shape="round" onClick={ () => { setSelectClientModal( { visible: true } ) } }>Cambiar</Button>
                        </div>
                    </div>
                </div>
                {
                    selectClientModal.visible && 
                    <SelectClientModal 
                                clients={ clients }
                                handlerClose={ () => { setSelectClientModal( { visible: false } ) } }
                                selectClientGroup={ selectClientGroup }
                                type={ type }
                                    />
                }
                <div className='separator' />

                <FilterPills filters={ filters } products={products} clients={clients} />
            </div>

            <div className="actions">
                <Dropdown
                    trigger={['click']}
                    dropdownRender={
                        () => <FilterMenu 
                                filters={ filters } 
                                setFilters={ setFilters }
                                clients={ clients }
                                products={products} />
                    }>
                    <Button type="link" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <BsSliders />
                        Filtrar
                    </Button>
                </Dropdown>
            </div>


        </div>
    )

}