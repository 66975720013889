import React, { useEffect, useState } from 'react';
import { Route, useRouteMatch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import authService from '../../services/Auth';

import { getClients, getClientsGroups } from '../../store/actions/getClients'
import { getProducts, getProductsGroups, getProductsLines } from '../../store/actions/getProducts'
import { getExchanges } from '../../store/actions/getExchanges';
import { getSellers } from '../../store/actions/getSellers';
import { getUserdata } from '../../store/actions/getUserdata';
import { setCampaign } from '../../store/actions/campaign';

import Sidenav from '../../components/sidenav/Sidenav';

import Modal from 'react-modal';
import ClientsPage from './clients/ClientsPage';

import './listPages.css'
import ProductsPage from './products/ProductsPage';
import ExchangePage from './exchange/ExchangePage';
import SellersPage from './sellers/SellersPage';
import loginService from '../../services/Login';
import SalesPage from './sales/SalesPage';
import ForecastPage from './forecast/ForecastPage';
import ClientsStatePage from './result-state/clients/ClientsStatePage';
import ProductsStatePage from './result-state/products/ProductsStatePage';
import SalesStatePage from './result-state/sales/SalesStatePage';
import ProfilePage from './profile/ProfilePage';
import GroupsPage from './clients/groups/GroupsPage';
import TotalsPage from './result-state/totals/TotalsPage';

Modal.setAppElement('#root');

const Main = ( props ) => {

  const [ initialFetch, setinitialFetch ] = useState( {
    loading: false,
    error: null,
    data: null
  } )

  useEffect( () => {
    authService.ping()
      .then( res => {

        props.getClients( props.campaign );
        props.getClientsGroups( props.campaign );
        props.getProducts();
        props.getProductsGroups();
        props.getProductsLines();
        props.getExchanges( props.campaign );
        props.getUserdata()
        props.getSellers()

      } )
      .catch( err => {
        loginService.logout( )
          .then( () => {
            window.location.reload()
          } )
      })
  }, [] )

  useEffect( () => {

    props.getClients( props.campaign );
    props.getClientsGroups( props.campaign );
    props.getExchanges( props.campaign );

  }, [ props.campaign ] )

  let { path } = useRouteMatch();

if ( initialFetch.loading ) {   
    return (
      <div className="mainContainer">
        <div className="loadingPage">
          <img src="/assets/loading.svg" alt="loading"></img>
        </div>
      </div>
    )
  } else {

    return (
       <div className="mainContainer">
         <Sidenav open={ props.sidenavOpen } year={ props.campaign } setYear={ props.setCampaign } />
         <div className={ !props.sidenavOpen ? 'pageContainer full' : 'pageContainer' }>
            <Route path={ `${ path }/clients` } exact component={ ClientsPage } />
            <Route path={ `${ path }/clients/groups` } component={ GroupsPage } />
            <Route path={ `${ path }/products` } component={ ProductsPage } />
            <Route path={ `${ path }/exchange` } component={ ExchangePage } />
            <Route path={ `${ path }/sellers` } component={ SellersPage } />
            <Route path={ `${ path }/sales` } component={ SalesPage } />
            <Route path={ `${ path }/forecast` } component={ ForecastPage } />
            <Route path={ `${ path }/statecli/:id` } component={ ClientsStatePage } />
            <Route path={ `${ path }/statecli` } exact component={ ClientsStatePage } />
            <Route path={ `${ path }/stateprod/:id` } component={ ProductsStatePage } />
            <Route path={ `${ path }/stateprod` } exact component={ ProductsStatePage } />
            <Route path={ `${ path }/status` } component={ SalesStatePage } />
            <Route path={ `${ path }/totals` } component={ TotalsPage } />
            <Route path={ `${ path }/profile` } component={ ProfilePage } />
           <Route path={ `${ path }` } exact > <Redirect to={ `${ path }/sales` } /> </Route> 
         </div>
       </div>
    )
  }
}

const mapStateToProps = ( state ) => {

  return {
    sidenavOpen: state.sidenavOpen,
    clients: state.clients,
    campaign: state.campaign
  }

}

export default connect( mapStateToProps, { 
  getClients,
  getClientsGroups,
  getProducts,
  getProductsGroups,
  getProductsLines,
  getExchanges,
  getSellers,
  getUserdata,
  setCampaign
})(Main)
