import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Select } from 'antd';
import { FiUserPlus } from 'react-icons/fi'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import './NewClient.css'
import Swal from 'sweetalert2';
import clientsService from '../../../../services/Clients';
import clientsGroupsService from '../../../../services/ClientsGroups';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewClient = ( { groups, handlerClose, refreshAction, editionClient, campaign, editionLimited } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ newClientData, setNewClientData ] = useState( editionClient || {
    name: '',
    group: groups[ 0 ].id,
    faena_mensual: null,
    reproductoras: null
  } )

  const addClient = () => {

    if ( !newClientData.name || !newClientData.group ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando el cliente..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    const apiFx = !editionClient ? clientsService.post : clientsService.put
    apiFx( newClientData )
      .then( res => {

        if ( !editionClient || editionClient.group !== newClientData.group ){

          clientsService.associate({
            client: editionClient ? editionClient.id : res.data.data.id,
            group: newClientData.group,
            year: campaign
          })
            .then( res => {

              refreshAction();
              Swal.close();
              handlerClose();

            } )

        } else {

          refreshAction();
          Swal.close();
          handlerClose();

        }

      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        {
          loading
          ?
          "loading.."
          :
          <ClientModalBody
            newClientData={ newClientData }
            setNewClientData={ setNewClientData }
            groups={ groups }
            editionLimited={ editionLimited }
            addClient={ addClient }
            />
        }

      </Modal>
    </div>
  )
}

export default NewClient


const ClientModalBody = ({ editionLimited, newClientData, setNewClientData, setGroupCreationVisible, groups, addClient }) => {

  return (
    <div className="modalContainer creation">
      <div className="column">
        <FiUserPlus />
      </div>
      <div className="data">
        <div className="form">
          <div className="line name">
            <div className="form-input">
              <input disabled={ editionLimited } id="clientName" type="text"placeholder="Nombre" value={ newClientData.name } onChange={ ev => { setNewClientData( { ...newClientData, name: ev.target.value } ) } } />
            </div>
          </div>
          <div className="line faena">
            <div className="form-input">
              <input id="clientFaenaMensual" type="number" placeholder="Faena mensual" value={ newClientData.faena_mensual } onChange={ ev => { setNewClientData( { ...newClientData, faena_mensual: ev.target.value } ) } } />
            </div>
          </div>
          <div className="line faena">
            <div className="form-input">
              <input id="clientReproductoras" type="number" placeholder="Reproductoras" value={ newClientData.reproductoras } onChange={ ev => { setNewClientData( { ...newClientData, reproductoras: ev.target.value } ) } } />
            </div>
          </div>
          <div className="line extra">
            <div className="form-select">
              <Select disabled={ editionLimited } value={ newClientData.group } onChange={ group => { setNewClientData({ ...newClientData, group }) } }>
                {
                  groups.map( group => <Option key={ group.id } value={ group.id }> { group.name } </Option> )
                }
              </Select>
            </div>
          </div>
        </div>
        <div className="footer">
          <Button variant="primary" onClick={ addClient } size="large"> Guardar Cliente </Button>
        </div>
      </div>
    </div>
  )
}