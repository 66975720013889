import clientsService from '../../services/Clients';
import clientsGroupsService from '../../services/ClientsGroups';

export const getClients = ( year ) => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_CLIENTS' } )

    clientsService.get( year )
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_CLIENTS',
          payload: response.data.data
        })

      })
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_CLIENTS'
        })

      })
    
  }

}

export const getClientsGroups = ( year ) => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_CLIENTS_GROUPS' } )

    clientsGroupsService.get( year )
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_CLIENTS_GROUPS',
          payload: response.data.data
        })

      })
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_CLIENTS_GROUPS'
        })

      })

  }

}
