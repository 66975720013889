import React from 'react';
import Currency from '../../../../components/Currency';
import { CalendarFilter } from '../../result-state/clients/components/filter/CalendarFilter';

const Filter = ( { filterString, setFilterString, monthYearSelected, setMonthYearSelected, fetchingSalesStatus } ) => {

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilterString( value );
  }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <CalendarFilter dateSelected={ monthYearSelected } selectDate={ setMonthYearSelected } />
      <div className='separator'></div>
      <div className="actions">
        <div className="search">
          <input name="user" placeholder="Buscar..." value={ filterString } onChange={ handleChange } />
        </div>
      </div>

      <div className='right'>
        <div className='card'>
          <div className='tag'>Total</div>
          <div className='value'>$ { fetchingSalesStatus.loading ? "..Calculando.." : Math.round( calculateTotal( fetchingSalesStatus.data ) ).toLocaleString() } <span className='exchange-tag'><Currency /></span> </div>
        </div>
      </div>

    </div>
  )
}

export default Filter

function calculateTotal( sales ){

  return sales.reduce( ( prev, curr ) => prev + curr.total, 0 )
  
}