import { Radio, Spin } from 'antd'
import React, { useState } from 'react'
import { Cell } from 'recharts'
import { Pie } from 'recharts'
import { PieChart } from 'recharts'
import { ResponsiveContainer } from 'recharts'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import './ranking.css'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import Currency from '../../../../../../components/Currency'

const COLORS = ['#35618f', '#611115', '#446645', '#491f91', '#142a25', '#ba0951'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const RankingClientsGraph = ({ fetchStatus, clients, clients_groups, exchange }) => {

    const [ mode, setMode ] = useState( 'CLIENT' )

    if ( !fetchStatus.data ) return <div className='card ranking'><Spin/></div>

    const data = fetchStatus.data.data.data.RANKING_GRAPH[ mode === 'CLIENT' ? 'client' : 'client_groups' ]

    return (
        <div className={ `card ranking` }>
            <div className='header'>
                <div className='title'> Ranking de clientes </div>
                <div className='mode-selector'>
                    <Radio.Group value="large" onChange={ e => setMode( e.target.value ) }>
                        <Radio.Button className={ mode === "CLIENT" ? 'selected' : '' } value="CLIENT">Clientes</Radio.Button>
                        <Radio.Button className={ mode === "CLIENT_GROUP" ? 'selected' : '' } value="CLIENT_GROUP">Grupos Económicos</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            {
                data && data.length
                ?
                <div className='data-container'>
                    <div className='list-container'>
                        {
                            data.map( ( item, index ) => {

                                const target = (mode === 'CLIENT' ? clients : clients_groups ).data.find( p => p.id === Number( item.client ) || p.id === Number( item.client_group ) )

                                return (
                                    <div className='item' key={ index }>
                                        <div className='color' style={{ background: COLORS[ index ] }}></div>
                                        <div className='rank'>{ index + 1 }.</div>
                                        <div className='data'>
                                            <div className='name'>{ target && target.name || 'Otros' }</div>
                                            {
                                                item.productSoldPrice
                                                ?
                                                <div className='average'>
                                                    <span className='tag'>Precio venta: </span> 
                                                    <span className='price'>{ item.productSoldPrice ? Math.round( item.productSoldPrice ) : '' } <span className='exchange-tag'><Currency value={ exchange } /></span> </span>
                                                    {/* { typeof item.overSoldPrice === 'boolean' ? ( item.overSoldPrice ? <FaArrowUp style={{ color: '#4caf50' }} /> : <FaArrowDown style={{ color: 'red' }} /> ) : null } */}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className='sold'>{ Math.round( item.sold ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'><Currency value={ exchange } /></span> </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='graph' style={{ width: '200px', height: '200px' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={200} height={200}>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="sold"
                            >
                                {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                :
                <EmptyContainer title="Sin datos" message="No hay datos para este periodo" />
            }
        </div>
    )
}
