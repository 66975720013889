import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Main from '../pages/main/Main';

import Modal from 'react-modal';
import authService from '../services/Auth';
import LoginPage from '../pages/accounts/login/LoginPage';
import RecoveryPage from '../pages/accounts/recovery/RecoveryPage';

Modal.setAppElement('#root');

function PrivateRoute({ children, ...rest }) {

  const loggedIn = authService.isAuthenticated();

  return (
    <Route
      {...rest}
      render={({ location }) => {

        if ( loggedIn ) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      } }
    />
  );
}

const App = ( props ) => {

  return (
     <div className="App">
       <BrowserRouter basename="/">
         <Switch>
           <PrivateRoute path='/main' > <Main /> </PrivateRoute>
           <Route path='/' exact> <Redirect to='/index' /> </Route>
           <Route path='/login/:t'> <LoginPage /> </Route>
           <Route path='/login'> <LoginPage /> </Route>
           <Route path='/recovery/:t'> <RecoveryPage /> </Route>
           <Route path='**'> <Redirect to="/login" /> </Route>
         </Switch>
       </BrowserRouter>
     </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    sidenavOpen: state.sidenavOpen
  }

}

export default connect( mapStateToProps )(App)
