import React, { useState } from 'react';
// import NewUser from './NewUser';

const Filter = ( { filterString, setFilterString, children } ) => {

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilterString( value );

    // props.filterChangeHandler( value );
  }

//   const reloadUsers = () => {

//     setNewUserFormVisible( false );

//     props.reloadUsers();

//   }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <div className="actions">
        <div className="search">
          <input name="user" placeholder="Buscar..." value={ filterString } onChange={ handleChange } />
        </div>
        {/* <div className="options">
          <div className="newUser" onClick={ () => { setNewUserFormVisible( true ) } }> <img src="/assets/img/icons/newUser.svg" alt="cliente nuevo" /></div>
        </div> */}
      </div>

      {
        children
      }
      {/* <NewClient open={ newUserFormVisible } handlerClose={ () => { setNewUserFormVisible( false ) } } successHandler={ reloadUsers } /> */}
    </div>
  )
}

export default Filter