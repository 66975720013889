import Api from './Api';

const exchangeService = {

  get: (  ) => {

    return Api.get( '/exchange', {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/exchange', jsonData, {
        withCredentials: true
      } )

  },
  put: ( jsonData ) => {

    return Api.put( `/exchange/${ jsonData.year }/${ jsonData.month }`, jsonData, {
      withCredentials: true
    })
  }

}

export default exchangeService
