import sellersService from "../../services/Sellers";


export const getSellers = () => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_SELLERS' } )

    sellersService.get()
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_SELLERS',
          payload: response.data.data
        })

      })
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_SELLERS'
        })

      })

  }

}
