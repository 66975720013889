import { Spin } from 'antd'
import React from 'react'
import { Cell } from 'recharts'
import { Pie } from 'recharts'
import { PieChart } from 'recharts'
import Currency from '../../../../../../components/Currency'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import './actual-forecast-summery.css'

export const ActualForecastSummery = ({ fetchStatus, dateSelected, type, compare, exchange, monthly }) => {

    if ( !fetchStatus.data ) return <div className='card forecast-summery'><Spin/></div>

    const totalSales = monthly ? fetchStatus.data.data.data.FORECAST_GRAPH.total.price.sales[ dateSelected.month ] : fetchStatus.data.data.data.FORECAST_GRAPH.total.price.sales.reduce( ( prev, curr ) => prev + curr, 0 )
    const totalForecast = monthly ? fetchStatus.data.data.data.FORECAST_GRAPH.total.price.forecast[ dateSelected.month ] : fetchStatus.data.data.data.FORECAST_GRAPH.total.price.forecast.reduce( ( prev, curr ) => prev + curr, 0 )
    const percentageFull = totalForecast ? ( ( totalSales / totalForecast ) * 100 ) : 100
    const data = [ {
        value: percentageFull <= 100 ? percentageFull : 100,
        fill: "#4caf50"
    }, {
        value: 100 - ( percentageFull <= 100 ? percentageFull : 100 ),
        fill: '#ff0000'
    } ]

  return (
    <div className={ `card forecast-summery ${ type === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }>
        { compare.on && <div className='header-color-tag'></div> }
        <div className='title'> Estado de venta del { monthly ? 'mes' : 'año' } </div>
        {
            totalSales || totalForecast
            ?
            <div className='data-container'>
                <div className='table-container'>
                    <div className='item'>
                        <div className='tag'>Pronosticado</div>
                        <div className='value'> { Math.round( totalForecast ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                    </div>
                    <div className='item'>
                        <div className='tag'>Vendido</div>
                        <div className='value'>{ Math.round( totalSales ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                    </div>
                    <div className='separator'></div>
                    <div className='item'>
                        <div className='tag'>{ percentageFull > 100 ? 'Sobreventa' : 'Restante' }</div>
                        <div className='value'> { Math.round( Math.abs( totalForecast - totalSales ) ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                    </div>
                </div>
                <div className="graph">
                    <PieChart height={ 200 } width={ 200 }>
                        <Pie
                        data={ data }
                        startAngle={225}
                        endAngle={-45}
                        innerRadius={ 84 }
                        outerRadius={ 100 }
                        paddingAngle={5}
                        dataKey="value"
                        >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={ entry.fill } />)
                        }
                        </Pie>
                    </PieChart>
                    <div className="percentage">
                        <span className="value"> { Math.round( percentageFull ) } </span>
                        <span className="unit"> % </span>
                    </div>
                </div>
            </div>
            :
            <EmptyContainer title="Sin datos" message="No hay datos para este periodo" />
        }
    </div>
  )
}
