import { Radio, Spin } from 'antd'
import React, { useState } from 'react'
import { Cell } from 'recharts'
import { Pie } from 'recharts'
import { PieChart } from 'recharts'
import { ResponsiveContainer } from 'recharts'
import Currency from '../../../../../../components/Currency'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import '../../../clients/components/graphs/ranking.css'

const COLORS = ['#35618f', '#611115', '#446645', '#491f91', '#142a25', '#ba0951'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const RankingGraph = ({ fetchStatus, clients, client_groups, type, exchange, compare }) => {

    const [ mode, setMode ] = useState( 'MONTH' )

    if ( !fetchStatus.data ) return <div className='card ranking'><Spin/></div>

    const data = fetchStatus.data.data.data.RANKING_GRAPH[ mode ]

  return (
    <div className={ `card ranking ${ type === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }>
        { compare.on && <div className='header-color-tag'></div> }
        <div className='header'>
            <div className='title'> Ranking de clientes </div>
            <div className='mode-selector'>
                <Radio.Group value="large" onChange={ e => setMode( e.target.value ) }>
                    <Radio.Button className={ mode === "MONTH" ? 'selected' : '' } value="MONTH">Mes</Radio.Button>
                    <Radio.Button className={ mode === "THREEMONTH" ? 'selected' : '' } value="THREEMONTH">Trimestre</Radio.Button>
                    <Radio.Button className={ mode === "SIXMONTH" ? 'selected' : '' } value="SIXMONTH">Semestre</Radio.Button>
                    <Radio.Button className={ mode === "YEAR" ? 'selected' : '' } value="YEAR">Año</Radio.Button>
                </Radio.Group>
            </div>
        </div>
        {
            data && data.length
            ?
            <div className='data-container'>
                <div className='list-container'>
                    {
                        data.map( ( item, index ) => {

                            const client = client_groups.data.find( c => c.id === Number( item.client ) )

                            return (
                                <div className='item' key={ index }>
                                    <div className='color' style={{ background: COLORS[ index ] }}></div>
                                    <div className='rank'>{ index + 1 }.</div>
                                    <div className='name'>{ client && client.name || 'Otros' }</div>
                                    <div className='sold'>{ Math.round( item.sold ).toLocaleString() } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='graph' style={{ width: '200px', height: '200px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={200} height={200}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="sold"
                        >
                            {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
            :
            <EmptyContainer title="Sin datos" message="No hay datos para este periodo" />
        }
    </div>
  )
}
