import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { BiLineChart } from 'react-icons/bi'
import { connect } from 'react-redux'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { LoadingPage } from '../../loading/LoadingPage'
import './products-state.css'
import { Switch } from 'antd'
import { ProductCard } from './components/filters/ProductCard'
import { ForecastGraph } from './components/graphs/ForecastGraph'
import { ActualForecastSummery } from '../clients/components/graphs/ActualForecastSummery'
import { RankingGraph } from './components/graphs/RankingGraph'
import { RevenueList } from './components/graphs/RevenueList'
import { SalesList } from '../clients/components/graphs/SalesList'
import { EmptyContainer } from '../../sales/components/EmptyContainer'
import { ConsumeGraph } from './components/graphs/ConsumeGraph'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const ClientsStatePage = ({ clients_status, products_status, campaign }) => {

  const [ filters, setFilters ] = useState( { product: { groupby: 'PRODUCT', data: null }, exchange: 'LOCAL', date: { month: moment().month() }, client_groups: [], ignore_null_sales: true } )
  const [ graphFetchStatus, setGraphFetchStatus ] = useState( { loading: true, error: null, data: null } )
  const [ compare, setCompare ] = useState( { on: false, filters: { product: { groupby: 'PRODUCT', data: null }, exchange: 'LOCAL', client_groups: [], date: { month: 0 }, ignore_null_sales: true }, fetchStatus: { loading: true, error: null, data: null } } )
  const { id } = useParams()

  useEffect( () => {

    const container = document.querySelector( '.pageContainer' )

    // When the user scrolls the page, execute myFunction
    container.onscroll = handleHomeScroll.bind( this, container )

    return () => { container.onscroll = null }

}, [] )

  useEffect( () => {
    console.log( {
      products_status,
      clients_status
    })

    if ( 
        !products_status.list.loading && 
        products_status.list.data && 
        !filters.client_groups.length && 
        !compare.filters.client_groups.length &&
        !clients_status.list.loading && 
        clients_status.groups.data && 
        !filters.product.data && 
        !compare.filters.product.data
      ){

      const product = id ? products_status.list.data.find( product => Number( product.id ) === Number( id ) ) : { ...products_status.list.data[ 0 ] }

      console.log( product )

      setFilters({ ...filters, client_groups: clients_status.groups.data.map( p => p.id ), product: { groupby: 'PRODUCT', data: { ...product } } })
      setCompare( { ...compare, filters: { ...compare.filters, client_groups: clients_status.groups.data.map( p => p.id ), product: { groupby: 'PRODUCT', data: { ...product } } } } )

    }

  }, [ products_status, clients_status, id ] )

  const selectProduct = ( productData, type ) => {
    
    if ( type === 'MAIN' ){
      setFilters( { ...filters, product: productData } )
      
    } else if ( type === 'COMPARE' ){
      setCompare({ ...compare, filters: { ...compare.filters, product: productData } })
    }

  }

  if ( 
    clients_status.list.loading    || 
    clients_status.groups.loading  || 
    products_status.list.loading   || 
    products_status.groups.loading || 
    products_status.lines.loading ||
    !filters.product
  ) return <LoadingPage />

  return (
    <div className={ `page state-products state ${ compare.on ? 'compare-on' : '' }` }>
      <PageHeader title="Estado de productos" icon={ <BiLineChart /> } >
        {
          !graphFetchStatus.loading && graphFetchStatus.data && ( graphFetchStatus.data.data.data.FORECAST_GRAPH.partials.q.length || graphFetchStatus.data.data.data.SALES_GRAPH.length )
          ?
          <div className='action'>
            <Switch checkedChildren="Comparando" unCheckedChildren="Vista individual" checked={ compare.on } onChange={ checked => { setCompare( { ...compare, on: checked } ) } } />
          </div>
          :
          null
        }
      </PageHeader>

      <ProductCard
                    products={ products_status }
                    productSelected={ filters.product.data }
                    selectProduct={ prod => { selectProduct( prod, "MAIN" ) } }
                    filters={ filters }
                    campaign={ campaign }
                    setFilters={ setFilters }
                    client_groups={ clients_status.groups }
                    setGraphFetchStatus={ setGraphFetchStatus }
                    selectDate={ ( date ) => { setFilters( { ...filters, date } ) } }
                    type="MAIN"
                     />

      {
        compare.on &&
        <ProductCard
                    products={ products_status }
                    productSelected={ compare.filters.product.data }
                    selectProduct={ prod => { selectProduct( prod, "COMPARE" ) } }
                    filters={ compare.filters }
                    campaign={ campaign }
                    setFilters={ ( filters ) => { setCompare( { ...compare, filters: { ...compare.filters, ...filters } } ) } }
                    client_groups={ clients_status.groups }
                    setGraphFetchStatus={ fStatus => { setCompare( { ...compare, fetchStatus: fStatus } ) } }
                    selectDate={ ( date ) => { setCompare( { ...compare, filters: { ...compare.filters, ...date } } ) } }
                    type="COMPARE"
                    compare={ compare }
                    />
      }

      {
        !graphFetchStatus.loading && graphFetchStatus.data && ( graphFetchStatus.data.data.data.FORECAST_GRAPH.partials.q.length || graphFetchStatus.data.data.data.SALES_GRAPH.length )
        ?
        <div className='body'>

          <ForecastGraph 
                          fetchStatus={ graphFetchStatus } 
                          client_groups={ clients_status.groups } 
                          exchange={ filters.exchange }
                          compare={ compare } />
    
          <div className='row'>
            <ActualForecastSummery 
                          fetchStatus={ graphFetchStatus } 
                          dateSelected={ filters.date } 
                          monthly
                          exchange={ filters.exchange }
                          compare={ compare } />
            {
              compare.on &&
              <ActualForecastSummery 
                          fetchStatus={ compare.fetchStatus } 
                          dateSelected={ compare.filters.date } 
                          monthly
                          exchange={ compare.filters.exchange }
                          compare={ compare }
                          type="COMPARE" />
            }

            <ActualForecastSummery 
                          fetchStatus={ graphFetchStatus } 
                          dateSelected={ filters.date } 
                          exchange={ filters.exchange }
                          compare={ compare } />
            {
              compare.on &&
              <ActualForecastSummery 
                          fetchStatus={ compare.fetchStatus } 
                          dateSelected={ compare.filters.date } 
                          exchange={ compare.filters.exchange }
                          compare={ compare }
                          type="COMPARE" />
            }

            <ConsumeGraph 
                          fetchStatus={ graphFetchStatus }
                          clients={ clients_status.list }  
                          compare={ compare }
                        />
                        
            {
              compare.on &&
              <ConsumeGraph 
                          fetchStatus={ compare.fetchStatus }
                          clients={ clients_status.list }  
                          type="COMPARE"
                          compare={ compare }
                        />
            }
    
            <RankingGraph 
                          fetchStatus={ graphFetchStatus } 
                          clients={ clients_status.list } 
                          client_groups={ clients_status.groups }
                          exchange={ filters.exchange }
                          compare={ compare }
                          />
    
            {
              compare.on &&
              <RankingGraph 
                            fetchStatus={ compare.fetchStatus } 
                            clients={ clients_status.list } 
                            client_groups={ clients_status.groups }
                            exchange={ compare.filters.exchange }
                            compare={ compare }
                            type="COMPARE"
                            />
            }
          </div>
    
          <RevenueList 
                      fetchStatus={ graphFetchStatus } 
                      clients={ clients_status.list } 
                      exchange={ filters.exchange }
                      compare={ compare } />
    
          <SalesList 
                    fetchStatus={ graphFetchStatus } 
                    products={ products_status.list } 
                    clients={ clients_status.list } 
                    exchange={ filters.exchange }
                    compare={ compare } />

        </div>
        :
        <EmptyContainer title="Sin datos" message="No cargaste datos de ventas ni pronósticos para estos filtros" />
      }
      
    </div>
  )
}


export default connect( state => ({ campaign: state.campaign, products_status: state.products, clients_status: state.clients } ) )( ClientsStatePage )


function handleHomeScroll( container ){

  // Get the header
  var headers = document.querySelectorAll(".card.client");

  // Get the offset position of the navbar
  var sticky = 77;

  // const offsetY = document.querySelector('.page.home').scrollTop
  const offsetY = container.scrollTop

  headers.forEach( header => {
      if ( offsetY > sticky ) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
  })

}