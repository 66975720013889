import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { BiLineChartDown } from 'react-icons/bi'
import { connect } from 'react-redux'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { LoadingPage } from '../../loading/LoadingPage'
import './clients-state.css'
import { ClientCard } from './components/filter/ClientCard'
import { ForecastGraph } from './components/graphs/ForecastGraph'
import { ActualForecastSummery } from './components/graphs/ActualForecastSummery'
import { RankingGraph } from './components/graphs/RankingGraph'
import { SalesList } from './components/graphs/SalesList'
import { RevenueList } from './components/graphs/RevenueList'
import { Switch } from 'antd'
import { EmptyContainer } from '../../sales/components/EmptyContainer'
import { ConsumeGraph } from './components/graphs/ConsumeGraph'
import { useParams } from 'react-router-dom'

const ClientsStatePage = ({ clients_status, products_status, campaign }) => {

  const [ filters, setFilters ] = useState( { 
    client_group: null,
    sub_clients: null,
    exchange: 'LOCAL', 
    date: { 
      month: moment().month() 
    }, 
    products: [], 
    ignore_null_sales: true 
  } )
  const [ graphFetchStatus, setGraphFetchStatus ] = useState( { loading: true, error: null, data: null } )
  const [ compare, setCompare ] = useState( { 
    on: false, 
    filters: { 
      client_group: null, 
      sub_clients: null,
      exchange: 'LOCAL', 
      products: null, 
      date: { 
        month: moment().month() 
      } 
    }, 
    fetchStatus: { loading: true, error: null, data: null } 
  } )
  const { id } = useParams()

  // EFECTO PARA MANEJAR EL CSS STICKY DEL FILTRO
  useEffect( () => {

    const container = document.querySelector( '.pageContainer' )

    // When the user scrolls the page, execute myFunction
    container.onscroll = handleHomeScroll.bind( this, container )

    return () => { container.onscroll = null }

}, [] )

  // EFECTO PARA SETEAR EL PRIMER FILTER Y COMPARE VALORES INICIALES DE ESTADOS
  useEffect( () => {

    if ( 
      !clients_status.loading && 
      clients_status.groups.data && 
      !filters.client_group &&
      !products_status.loading &&
      products_status.list.data &&
      !filters.products.length
      ){

        const client_group_data = id ? clients_status.groups.data.find( cg => Number( cg.id ) === Number( id ) ) : clients_status.groups.data[ 0 ]

      setFilters( { ...filters, client_group: client_group_data, products: products_status.list.data.map( p => p.id ) } )
      setCompare( { ...compare, filters: { ...compare.filters, client_group: client_group_data, products: products_status.list.data.map( p => p.id ) } } )
    }

  }, [ clients_status, products_status, id ] )


  const selectClientGroup = ( clientGroupId ) => {

    const clientData = clients_status.groups.data.find( clientgroup => Number( clientgroup.id ) === Number( clientGroupId ) )
    
    setFilters( { ...filters, client_group: clientData } )

  }

  const selectClientGroupForCompare = ( clientGroupId, ) => {

    const clientData = clients_status.groups.data.find( clientgroup => Number( clientgroup.id ) === Number( clientGroupId ) )

    setCompare({ ...compare, filters: { ...compare.filters, client_group: clientData } })

  }

  if ( 
    clients_status.list.loading    || 
    clients_status.groups.loading  || 
    products_status.list.loading   || 
    products_status.groups.loading || 
    products_status.lines.loading ||
    !filters.client_group
  ) return <LoadingPage />

  return (
    <div className={ `page state-clients state ${ compare.on ? 'compare-on' : '' }` }>
      <PageHeader title="Estado de clientes" icon={ <BiLineChartDown /> } >
        {
          !graphFetchStatus.loading && graphFetchStatus.data && ( graphFetchStatus.data.data.data.FORECAST_GRAPH.partials.q.length || graphFetchStatus.data.data.data.SALES_GRAPH.length )
          ?
          <div className='action'>
            <Switch checkedChildren="Comparando" unCheckedChildren="Vista individual" checked={ compare.on } onChange={ checked => { setCompare( { ...compare, on: checked } ) } } />
          </div>
          :
          null
        }
      </PageHeader>
      <ClientCard
                    clients={ clients_status }
                    clientGroupSelected={ filters.client_group }
                    selectClientGroup={ selectClientGroup }
                    filters={ filters }
                    campaign={ campaign }
                    setFilters={ setFilters }
                    products={ products_status.list }
                    setGraphFetchStatus={ setGraphFetchStatus }
                    graphFetchStatus={ graphFetchStatus } 
                    selectDate={ ( date ) => { setFilters( { ...filters, date } ) } }
                    type="MAIN"
                    />

      {
        compare.on &&
        <ClientCard
                    clients={ clients_status }
                    clientGroupSelected={ compare.filters.client_group }
                    selectClientGroup={ selectClientGroupForCompare }
                    filters={ compare.filters }
                    campaign={ campaign }
                    setFilters={ ( filters ) => { setCompare( { ...compare, filters: { ...compare.filters, ...filters } } ) } }
                    products={ products_status.list }
                    setGraphFetchStatus={ ( fetchStatus ) => { setCompare( { ...compare, fetchStatus: { ...fetchStatus } } ) } }
                    graphFetchStatus={ graphFetchStatus } 
                    selectDate={ ( date ) => { setCompare( { ...compare.filters, date } ) } }
                    type="COMPARE"
                    compare={ compare }
                    />
      }

      {
        !graphFetchStatus.loading && graphFetchStatus.data && ( graphFetchStatus.data.data.data.FORECAST_GRAPH.partials.q.length || graphFetchStatus.data.data.data.SALES_GRAPH.length )
        ?
        (
          <div className='body'>

                    <ForecastGraph 
                                    fetchStatus={ graphFetchStatus } 
                                    products={ products_status.list } 
                                    exchange={ filters.exchange }
                                    compare={ compare } />
              

                    <ActualForecastSummery 
                                    fetchStatus={ graphFetchStatus } 
                                    dateSelected={ filters.date } 
                                    exchange={ filters.exchange }
                                    monthly
                                    compare={ compare } />
                      {
                        compare.on &&
                        <ActualForecastSummery 
                                    fetchStatus={ compare.fetchStatus } 
                                    dateSelected={ compare.filters.date } 
                                    exchange={ compare.filters.exchange }
                                    compare={ compare }
                                    monthly
                                    type="COMPARE" />
                      }

                      <ActualForecastSummery 
                                    fetchStatus={ graphFetchStatus } 
                                    dateSelected={ filters.date } 
                                    exchange={ filters.exchange }
                                    compare={ compare } />
                                    
                      {
                        compare.on &&
                        <ActualForecastSummery 
                                    fetchStatus={ compare.fetchStatus } 
                                    dateSelected={ compare.filters.date } 
                                    exchange={ compare.filters.exchange }
                                    compare={ compare }
                                    type="COMPARE" />
                      }

                      <ConsumeGraph 
                                    fetchStatus={ graphFetchStatus }
                                    products={ products_status.list }  
                                    compare={ compare }
                                  />

                      {
                        compare.on &&
                        <ConsumeGraph 
                                    fetchStatus={ compare.fetchStatus }
                                    products={ products_status.list }  
                                    compare={ compare }
                                    type="COMPARE"
                                  />
                      }
              
                      <RankingGraph 
                                    fetchStatus={ graphFetchStatus } 
                                    products={ products_status.list } 
                                    exchange={ filters.exchange }
                                    compare={ compare }
                                    />
              
                      {
                        compare.on &&
                        <RankingGraph 
                                      fetchStatus={ compare.fetchStatus } 
                                      products={ products_status.list } 
                                      exchange={ filters.exchange }
                                      compare={ compare }
                                      type="COMPARE"
                                      />
                      }
              
                    <RevenueList 
                                fetchStatus={ graphFetchStatus } 
                                products={ products_status.list } 
                                exchange={ filters.exchange }
                                compare={ compare } />
              
                    <SalesList 
                              fetchStatus={ graphFetchStatus } 
                              products={ products_status.list } 
                              clients={ clients_status.list } 
                              exchange={ filters.exchange }
                              compare={ compare } />
                              
          </div>
        )
        :
        <EmptyContainer title="Sin datos" message="No cargaste datos de ventas ni pronósticos para estos filtros" />
        
      }
      
    </div>
  )


}


export default connect( state => ({ campaign: state.campaign, products_status: state.products, clients_status: state.clients } ) )( ClientsStatePage )


function handleHomeScroll( container ){

  // Get the header
  var headers = document.querySelectorAll(".card.client");

  // Get the offset position of the navbar
  var sticky = 77;

  // const offsetY = document.querySelector('.page.home').scrollTop
  const offsetY = container.scrollTop

  headers.forEach( header => {
      if ( offsetY > sticky ) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
  })

}