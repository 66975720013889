import React, { useState } from 'react'
import './ConsumeGraph.css'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import { Button, Progress, Radio, Spin } from 'antd'

export const ConsumeGraph = ({
    fetchStatus,
    products,
    type,
    compare
}) => {

    const [ detailView, setDetailView ] = useState( false )
    const [ mode, setMode ] = useState( "YEAR" )

    if ( fetchStatus.loading ){
        return <div className={ `card consume ${ type === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }> <Spin /> </div>
    }
    

    const { CONSUME_GRAPH } = fetchStatus.data.data.data

    return (
        <div className={ `card consume ${ type === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }>
            { compare.on && <div className='header-color-tag'></div> }
            <div className='header'>
                <div className='title'> Marketshare </div>
                <div className='mode-selector'>
                    <Radio.Group value="large" onChange={ e => setMode( e.target.value ) }>
                        <Radio.Button className={ mode === "MONTH" ? 'selected' : '' } value="MONTH">Mes</Radio.Button>
                        <Radio.Button className={ mode === "YEAR" ? 'selected' : '' } value="YEAR">Año</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            {
                !CONSUME_GRAPH.total.pendings_data_load
                ?
                <div className='data-container'>
                    <MainConsumeGraph sold={ mode === 'YEAR' ? CONSUME_GRAPH.total.sold_year : CONSUME_GRAPH.total.sold_month } max={ mode === 'YEAR' ? CONSUME_GRAPH.total.max_year : CONSUME_GRAPH.total.max_month } />

                    {
                        Object.keys( CONSUME_GRAPH.partials ) &&
                        Object.keys( CONSUME_GRAPH.partials ).length
                        ?
                        <Button type="link" block size='medium' onClick={ () => { setDetailView( !detailView ) } }>
                            { detailView ? 'Ocultar detalle' : 'Ver detalle' }
                        </Button>
                        :
                        null
                    }

                    {
                        detailView && <DetailConsumeList listItems={
                                                                        Object.keys( mode === 'YEAR' ? CONSUME_GRAPH.partials.year : CONSUME_GRAPH.partials.month ).map( productId => {
                                                                            const match = products.data.find( product => Number( product.id ) === Number( productId ) )
                                                                    
                                                                            return {
                                                                                ...CONSUME_GRAPH.partials[ productId ],
                                                                                id: productId,
                                                                                name: match.name
                                                                            }
                                                                        } )
                                                                    } />
                    }
                </div>
                :
                <EmptyContainer 
                    title="Falta configurar clientes" 
                    message="Para visualizar este grafico por favor configure las reproductoras o faena mensual de los clientes del grupo" 
                    />
            }
        </div>
    )

}

export const MainConsumeGraph = ({ sold, max }) => {

    const soldPercentage = max ? ( sold * 100 / max ) : 100

    return (
        <div className='consume-graph'>
            <div className='tags'>
                <div className='tag'>potencial</div>
                <div className='tag'>vendido</div>
            </div>
            <div className='axe'></div>
            <div className='graph-content'>
                <div className='progress'>
                    <div className='line'>
                        <div className='completed' style={{ width: '100%' }}>{ max.toLocaleString() } unidades </div>
                    </div>
                </div>
                <div className='progress'>
                    <div className='line'>
                        <div className='completed' style={{ width: soldPercentage + '%' }}></div>
                        <div className='percentage'> { Math.ceil( soldPercentage ) } % </div>
                    </div>
                    <div className='units'>{ sold.toLocaleString() } unidades vendidas</div>
                </div>
            </div>
        </div>
    )

}

export const DetailConsumeList = ({
    listItems
}) => {

    return (
        <div className='consume-detail-list'>
            {
                listItems.map( item => {

                    const { name, sold, max, id } = item
                    const percent = max ? Math.ceil( sold * 100 / max ) : 100

                    return (
                        <div className='consume-detail-item' key={ id }>
                            <div className='tag'>{ name }</div>
                            <Progress strokeLinecap="butt" percent={ percent } />
                        </div>
                    )
                } )
            }
        </div>
    )

}