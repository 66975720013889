const productssReducer = ( products = {
    list: { loading: true, error: null, data: null },
    groups: { loading: true, error: null, data: null },
    lines: { loading: true, error: null, data: null }
  }, action ) => {

    // PRODUCTS
    if ( action.type === 'START_FETCH_PRODUCTS' ){
      return {
        ...products,
        list: { loading: true, error: null, data: null }
      };
    }

    if ( action.type === 'FAIL_FETCH_PRODUCTS' ){
      return {
        ...products,
        list: { loading: false, error: true, data: null }
      };
    }

    if ( action.type === 'RECEIVE_FETCH_PRODUCTS' ){
      return {
        ...products,
        list: { loading: false, error: true, data: action.payload }
      };
    }

    // groups
    if ( action.type === 'START_FETCH_PRODUCTS_GROUPS' ){
      return {
        ...products,
        groups: { loading: true, error: null, data: null }
      };
    }

    if ( action.type === 'FAIL_FETCH_PRODUCTS_GROUPS' ){
      return {
        ...products,
        groups: { loading: false, error: true, data: null }
      };
    }

    if ( action.type === 'RECEIVE_FETCH_PRODUCTS_GROUPS' ){
      return {
        ...products,
        groups: { loading: false, error: true, data: action.payload }
      };
    }

    // LINES
    if ( action.type === 'START_FETCH_PRODUCTS_LINES' ){
      return {
        ...products,
        lines: { loading: true, error: null, data: null }
      };
    }

    if ( action.type === 'FAIL_FETCH_PRODUCTS_LINES' ){
      return {
        ...products,
        lines: { loading: false, error: true, data: null }
      };
    }

    if ( action.type === 'RECEIVE_FETCH_PRODUCTS_LINES' ){
      return {
        ...products,
        lines: { loading: false, error: true, data: action.payload }
      };
    }
    
      return products;
    
    }
    
    export default productssReducer;
    