import { Radio, Spin } from 'antd'
import React, { useState } from 'react'
import Currency from '../../../../../../components/Currency'
import '../../../clients/components/graphs/revenue-list.css'

export const RevenueList = ({ fetchStatus, clients, compare, exchange }) => {

    const [ mode, setMode ] = useState( 'REPRODUCTORAS' )

    if ( !fetchStatus.data ) return <div className='card revenue'><Spin/></div>

    const compareData = compare.on && !compare.fetchStatus.loading && compare.fetchStatus.data ? compare.fetchStatus.data.data.data.REVENUE_GRAPH.map( item => ({ ...item, compareItem: true }) ) : []
    const data = [ ...fetchStatus.data.data.data.REVENUE_GRAPH, ...compareData ]

    const totals = data && getTotals( fetchStatus.data.data.data.REVENUE_GRAPH )
    const compareTotals = compareData && getTotals( compareData )

    return (
        <div className={ `card revenue ${ compare.on === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }>
            <div className='header'>
                <div className='title'>Ventas por ave</div>
                <div className='config'>
                    <Radio.Group value="large" onChange={ e => setMode( e.target.value ) }>
                        <Radio.Button className={ mode === "REPRODUCTORAS" ? 'selected' : '' } value="REPRODUCTORAS">Reproductoras</Radio.Button>
                        <Radio.Button className={ mode === "FAENA" ? 'selected' : '' } value="FAENA">Faena</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            <div className='revenue-table'>
                <div className='table-header'>
                        <div className='item name'>Cliente</div>
                        <div className='item month-item'>ENE</div>
                        <div className='item month-item'>FEB</div>
                        <div className='item month-item'>MAR</div>
                        <div className='item month-item'>ABR</div>
                        <div className='item month-item'>MAY</div>
                        <div className='item month-item'>JUN</div>
                        <div className='item month-item'>JUL</div>
                        <div className='item month-item'>AGO</div>
                        <div className='item month-item'>SEP</div>
                        <div className='item month-item'>OCT</div>
                        <div className='item month-item'>NOV</div>
                        <div className='item month-item'>DIC</div>
                        <div className='item total'>Promedio Mes</div>
                </div>
                <div className='table-body'>
                    {
                        data.map( ( revenue, index ) => {

                            const client = clients.data.find( c => c.id === Number( revenue.client ) )

                            if ( !revenue[ mode ] ){

                                return (
                                    <div className='revenue-item' key={ index }>
                                        { compare.on && <div className={ "color-tag " + (revenue.compareItem ? "COMPARE" : 'MAIN') }></div> }
                                        <div className='item name'>{ client.name }</div>
                                        <div className='item month-item nodata'> Sin datos cargados para este cliente </div>
                                    </div>
                                )
                            }

                            const total = revenue[ mode ].reduce( ( prev, curr ) => prev + curr, 0 ) / 12

                            return (
                                <div className='revenue-item' key={ index }>
                                    { compare.on && <div className={ "color-tag " + (revenue.compareItem ? "COMPARE" : 'MAIN') }></div> }
                                    <div className='item name'>{ client.name }</div>
                                    <div className='item month-item'> { revenue[ mode ][ 0 ] && revenue[ mode ][ 0 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 1 ] && revenue[ mode ][ 1 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 2 ] && revenue[ mode ][ 2 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 3 ] && revenue[ mode ][ 3 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 4 ] && revenue[ mode ][ 4 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 5 ] && revenue[ mode ][ 5 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 6 ] && revenue[ mode ][ 6 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 7 ] && revenue[ mode ][ 7 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 8 ] && revenue[ mode ][ 8 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 9 ] && revenue[ mode ][ 9 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 10 ] && revenue[ mode ][ 10 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item month-item'> { revenue[ mode ][ 11 ] && revenue[ mode ][ 11 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    <div className='item total'>{ total.toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                </div>
                            )
                        } )
                    }

                    {
                        totals &&
                        <div className='revenue-item item-total'>
                            { compare.on && <div className={ "color-tag " + 'MAIN' }></div> }
                            <div className='item name'> Total </div>
                            <div className='item month-item'> { totals[ mode ][ 0 ] && totals[ mode ][ 0 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 1 ] && totals[ mode ][ 1 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 2 ] && totals[ mode ][ 2 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 3 ] && totals[ mode ][ 3 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 4 ] && totals[ mode ][ 4 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 5 ] && totals[ mode ][ 5 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 6 ] && totals[ mode ][ 6 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 7 ] && totals[ mode ][ 7 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 8 ] && totals[ mode ][ 8 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 9 ] && totals[ mode ][ 9 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 10 ] && totals[ mode ][ 10 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { totals[ mode ][ 11 ] && totals[ mode ][ 11 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item total'>{ totals.total[ mode ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                        </div>
                    }

                    {
                        compare.on && compareTotals &&
                        <div className='revenue-item item-total'>
                            { compare.on && <div className={ "color-tag " + 'COMPARE' }></div> }
                            <div className='item name'> Total </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 0 ] && compareTotals[ mode ][ 0 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 1 ] && compareTotals[ mode ][ 1 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 2 ] && compareTotals[ mode ][ 2 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 3 ] && compareTotals[ mode ][ 3 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 4 ] && compareTotals[ mode ][ 4 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 5 ] && compareTotals[ mode ][ 5 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 6 ] && compareTotals[ mode ][ 6 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 7 ] && compareTotals[ mode ][ 7 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 8 ] && compareTotals[ mode ][ 8 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 9 ] && compareTotals[ mode ][ 9 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 10 ] && compareTotals[ mode ][ 10 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item month-item'> { compareTotals[ mode ][ 11 ] && compareTotals[ mode ][ 11 ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            <div className='item total'>{ compareTotals.total[ mode ].toFixed( 2 ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


function getTotals( data ){

    const response = data
                        .reduce( ( prev, curr, index ) => {

                            const FAENA = []
                            const REPRODUCTORAS = []

                            Array.from( Array(12).keys() )
                                .map( ( item , i ) => {
                                    FAENA[ i ] = prev.FAENA[ i ] + ( curr.FAENA ? curr.FAENA[ i ] : 0 )
                                    REPRODUCTORAS[ i ] = prev.REPRODUCTORAS[ i ] + ( curr.REPRODUCTORAS ? curr.REPRODUCTORAS[ i ] : 0 )
                                } )

                            return {
                                FAENA,
                                REPRODUCTORAS
                            }

                        }, { 
                            FAENA: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ], 
                            REPRODUCTORAS: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] 
                        })

    response[ 'total' ] = {
        FAENA: response.FAENA.reduce( ( prev, curr ) => prev + curr, 0 ) / 12,
        REPRODUCTORAS: response.REPRODUCTORAS.reduce( ( prev, curr ) => prev + curr, 0 ) / 12
    }

    return response

}