import React, { useState, useEffect } from 'react';
import { FiTrash } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import { FaArrowsAltV } from 'react-icons/fa';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import clientsService from '../../../../services/Clients';

// COMPONENTS

// ITEM LIST HEADER
const ClientListItemHeader = ({ setOrderedList, edition }) => {

  return (
    <div className="list-item header">
      <div className="column-data"> Nombre <div onClick={ () => { setOrderedList( 'NAME' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Grupo <div onClick={ () => { setOrderedList( 'GROUP' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Faena Mensual <div onClick={ () => { setOrderedList( 'FAENA_MENSUAL' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Reproductoras <div onClick={ () => { setOrderedList( 'REPRODUCTORAS' ) } }> <FaArrowsAltV /> </div> </div>
      { edition && <div className="column-data"> <FiTrash /> </div> }
    </div>
  )
}

// ITEM LIST DEL BODY
const ClientListItem = ( { client, groups, reloadAction, setNewClient, edition, campaign } ) => {

  const groupData = groups && groups.find( g => g.id === client.group )

  return (
    <div className="list-item">
      <div className="column-data"> { client.name } </div>
      <div className="column-data"> { groupData && groupData.name || '' } </div>
      <div className="column-data"> { client.faena_mensual && client.faena_mensual.toLocaleString( 'es-AR' ) } </div>
      <div className="column-data"> { client.reproductoras && client.reproductoras.toLocaleString( 'es-AR' ) } </div>
        
        <div className='column-data actions'> 
          <div className='action edition' onClick={ () => { setNewClient( { visible: true, editionClient: client, editionLimited: !edition } ) } }> <HiOutlinePencil /> </div>
          { edition && <div className='action delete' onClick={ () => { deleteClient( client.id, campaign, reloadAction ) } }> <FiTrash /> </div> }
        </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const ClientsList = ( { clients, groups, reloadAction, setNewClient, edition, campaign } ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ activePage, setActivePage ] = useState( 1 );
  const [ filteredList, setFilteredList ] = useState( [] );
  const [ orderedList, setOrderedList ] = useState( { orderby: { item: null, desc: false }, list: clients } )
  const pageSize = 5;

  // EFECTO PARA ORDENAR LISTA
  useEffect( () => {

    let ordered = clients
    if ( orderedList.orderby.item === 'NAME' ){

      ordered = clients.sort( ( client1, client2 ) => {

        if ( orderedList.orderby.desc ){

          if ( client1.name < client2.name ) {
            return 1;
          }
          if ( client1.name > client2.name ) {
            return -1;
          }
          return 0;

        } else {

          if ( client1.name > client2.name ) {
            return 1;
          }
          if ( client1.name < client2.name ) {
            return -1;
          }
          return 0;

        }

      } );

    } else if ( orderedList.orderby.item === 'GROUP' ){

      ordered = clients.sort( ( client1, client2 ) => {

        const groupData1 = groups && groups.find( g => g.id === client1.group )
        const groupData2 = groups && groups.find( g => g.id === client2.group )

        if ( orderedList.orderby.desc ){

          if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
            return 1;
          }
          if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
            return -1;
          }
          return 0;

        } else {

          if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
            return 1;
          }
          if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
            return -1;
          }
          return 0;

        }

      } );

    } else if ( orderedList.orderby.item === 'FAENA_MENSUAL' ){
      
      
      ordered = clients.sort( ( client1, client2 ) => {

        if ( orderedList.orderby.desc ){

          if ( client1.faena_mensual < client2.faena_mensual ) {
            return 1;
          }
          if ( client1.faena_mensual > client2.faena_mensual ) {
            return -1;
          }
          return 0;

        } else {

          if ( client1.faena_mensual > client2.faena_mensual ) {
            return 1;
          }
          if ( client1.faena_mensual < client2.faena_mensual ) {
            return -1;
          }
          return 0;

        }
        
      } );

    } else if ( orderedList.orderby.item === 'REPRODUCTORAS' ){

      
      ordered = clients.sort( ( client1, client2 ) => {
        
        if ( orderedList.orderby.desc ){
  
          if ( client1.reproductoras < client2.reproductoras ) {
            return 1;
          }
          if ( client1.reproductoras > client2.reproductoras ) {
            return -1;
          }
          return 0;
  
        } else {
  
          if ( client1.reproductoras > client2.reproductoras ) {
            return 1;
          }
          if ( client1.reproductoras < client2.reproductoras ) {
            return -1;
          }
          return 0;
  
        }
      } );

    }

    setOrderedList( {
      ...orderedList,
      list: ordered
    } )

  }, [ clients, orderedList.orderby ] )

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = orderedList.list
                      .map( client => <ClientListItem 
                                                key={ client.id } 
                                                client={ client } 
                                                groups={ groups }
                                                edition={ edition }
                                                campaign={ campaign }
                                                setNewClient={ setNewClient }
                                                reloadAction={ reloadAction } /> )
                      // .slice( ( activePage - 1 ) * pageSize , ( activePage - 1 ) + pageSize);

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="list-container">
      <div className="list">
        <ClientListItemHeader edition={ edition } setOrderedList={ ( orderby ) => { setOrderedList( { ...orderedList, orderby: { item: orderby, desc: !orderedList.orderby.desc } } ) } } />
        { pageList }
      </div>
      {/* <div className="pagination" style={ filteredList.length <= pageSize ? { display: 'none' } : { } }>
        <Pagination
              activePage={ activePage }
              itemsCountPerPage={ pageSize }
              totalItemsCount={ filteredList.length }
              pageRangeDisplayed={ pageSize }
              onChange={ page => setActivePage( page ) }
              hideFirstLastPages={ true }
              prevPageText="«"
              nextPageText="»"
            />
      </div> */}
    </div>
  )
}

// FUNCTIONS

// BAJA DE USUARIO
const deleteClient = ( clientId, campaign, reloadAction ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Eliminar cliente',
            text: 'Estás seguro que deseas eliminar el cliente? Recuerda que se eliminaran todas las ventas a este cliente.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, elimiarlo!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return clientsService.delete( clientId, campaign )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Eliminado!',
                'Cliente eliminado exitosamente!',
                'success'
              )

              reloadAction();
            }
          })
}

export default ClientsList
