import Api from './Api';

const commitmentsService = {

  get: (  ) => {

    return Api.get( '/commitment', {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/commitment', jsonData, {
        withCredentials: true
      } )

  },
  delete: ( id ) => {

    return Api.delete( `/commitment/${ id }`, {
      withCredentials: true
    })
  }

}

export default commitmentsService
