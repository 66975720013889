import Axios from 'axios';

import environment from '../environments/environment'


const Api = Axios.create({
  baseURL: environment.url
});

export default Api
