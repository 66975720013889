import React, { useState } from 'react'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import { Button, Radio, Spin } from 'antd'
import { DetailConsumeList, MainConsumeGraph } from '../../../clients/components/graphs/ConsumeGraph'

export const ConsumeGraph = ({
    fetchStatus,
    clients,
    type,
    compare
}) => {

    const [ detailView, setDetailView ] = useState( false )
    const [ mode, setMode ] = useState( "YEAR" )

    if ( fetchStatus.loading ){
        return <div className={ `card consume ${ type === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }> <Spin /> </div>
    }
    

    const { CONSUME_GRAPH } = fetchStatus.data.data.data

    return (
        <div className={ `card consume ${ type === 'COMPARE' ? 'COMPARE' : 'MAIN' }` }>
            { compare.on && <div className='header-color-tag'></div> }
            <div className='header'>
                <div className='title'> Marketshare </div>
                <div className='mode-selector'>
                    <Radio.Group value="large" onChange={ e => setMode( e.target.value ) }>
                        <Radio.Button className={ mode === "MONTH" ? 'selected' : '' } value="MONTH">Mes</Radio.Button>
                        <Radio.Button className={ mode === "YEAR" ? 'selected' : '' } value="YEAR">Año</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            {
                !CONSUME_GRAPH.total.pendings_data_load
                ?
                <div className='data-container'>
                    <MainConsumeGraph sold={ mode === 'YEAR' ? CONSUME_GRAPH.total.sold_year : CONSUME_GRAPH.total.sold_month } max={ mode === 'YEAR' ? CONSUME_GRAPH.total.max_year : CONSUME_GRAPH.total.max_month } />

                    {
                        Object.keys( CONSUME_GRAPH.partials ) &&
                        Object.keys( CONSUME_GRAPH.partials ).length
                        ?
                        <Button type="link" block size='medium' onClick={ () => { setDetailView( !detailView ) } }>
                            { detailView ? 'Ocultar detalle' : 'Ver detalle' }
                        </Button>
                        :
                        null
                    }

                    {
                        detailView && <DetailConsumeList listItems={
                                                                    Object.keys( mode === 'YEAR' ? CONSUME_GRAPH.partials.year : CONSUME_GRAPH.partials.month ).map( productId => {
                                                                            const match = clients.data.find( client => Number( client.id ) === Number( productId ) )
                                                                    
                                                                            return {
                                                                                ...CONSUME_GRAPH.partials[ productId ],
                                                                                id: productId,
                                                                                name: match.name
                                                                            }
                                                                        } )
                                                                    } />
                    }
                </div>
                :
                <EmptyContainer 
                    title="Falta configurar productos" 
                    message="Para visualizar este grafico por favor configure el consumo por ave de este producto" 
                    />
            }
        </div>
    )

}