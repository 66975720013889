import Api from './Api';

const clientsService = {

  get: ( year ) => {

    return Api.get( '/clients/' + year, {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/clients', jsonData, {
        withCredentials: true
      } )

  },
  put: ( jsonData ) => {

    return Api.put( '/clients/' + jsonData.id, jsonData, {
      withCredentials: true
    } )

  },
  delete: ( clientId, campaign ) => {

    return Api.delete( `/clients/${ clientId }/${ campaign }`, {
      withCredentials: true
    })
  },
  associate: ( jsonData ) => {

    return Api.post( '/clients/associate', jsonData, {
      withCredentials: true
    } )

},

}

export default clientsService
