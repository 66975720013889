import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BsFileText } from 'react-icons/bs'
import { connect } from 'react-redux'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import graphService from '../../../../services/Graph'
import { LoadingPage } from '../../loading/LoadingPage'
import { Filter } from './components/Filter'
import NewCommitmentModal from './components/NewCommitmentModal'
import { SalesStateList } from './components/SalesStateList'

const SalesStatePage = ({ clients_status, sellers_status, userdata_status, campaign }) => {
    
    const [ graphFetchStatus, setGraphFetchStatus ] = useState( { loading: true, error: null, data: null } )
    const [ dateSelected, setDateSelected ] = useState( { month: moment().month() } )
    const [ filteredString, setFilteredString ] = useState( '' )
    const [ commitmentModal, setCommitmentModal ] = useState( { visible: false, data: null } )
    const [ filteredList, setFilteredList ] = useState( null )

    const refreshData = () => {

        setGraphFetchStatus( { loading: true, error: null, data: null } )

        graphService.getSalesStatusData( { date: moment().year( campaign ).month( dateSelected.month ).format( 'YYYY-MM-DD' ) } )
            .then( res => {
                setGraphFetchStatus( { loading: false, error: null, data: res.data } )
            } )
            .catch( err => {
                setGraphFetchStatus( { loading: false, error: true, data: null } )
            })
    }

    // SETUP
    useEffect( refreshData, [ dateSelected.month, campaign ] )

    // EFECTO PARA APLICAR EL FILTRO SOBRE LA LISTA DE ITEMS ANCLADOS EN CADA GRUPOS DE CLIENTES
    useEffect( () => {

        if( clients_status.groups.data && graphFetchStatus.data ){

            const filteredClientsGroupsIds = Object.keys( graphFetchStatus.data.data )
                .filter( client_group_id => {
        
                    const client_group = clients_status.groups.data.find( cg => Number( cg.id ) === Number( client_group_id ) )
        
                    return client_group && client_group.name.toUpperCase().indexOf( filteredString.toUpperCase() ) > -1
        
                } )
                .map( client_group_id => ({ id: client_group_id, ...graphFetchStatus.data.data[ client_group_id ] }) )

                setFilteredList( filteredClientsGroupsIds )

        }

    }, [ filteredString, clients_status, graphFetchStatus ] )

    if ( 
        clients_status.list.loading    || 
        clients_status.groups.loading  ||
        sellers_status.loading ||
        userdata_status.loading ||
        !graphFetchStatus.data
      ) return <LoadingPage />
      

    return (
        <div className={ `page status state` }>
            <PageHeader title="Estado del mes" icon={ <BsFileText /> }  />
            <Filter 
                    dateSelected={ dateSelected } 
                    selectDate={ setDateSelected } 
                    closestExchange={ graphFetchStatus.data.closestExchange }
                    totals={ graphFetchStatus.data.totals }
                    filteredString={ filteredString } 
                    setFilterString={ setFilteredString } />
            <SalesStateList 
                            filteredList={ filteredList }
                            graphFetchStatus={ graphFetchStatus } 
                            client_groups={ clients_status.groups }
                            setCommitmentModal={ setCommitmentModal } 
                            sellers={ sellers_status }
                            userdata={ userdata_status.data } />

            { 
                commitmentModal.visible && 
                <NewCommitmentModal
                                client_group={ commitmentModal.data.client_group }
                                ammount={ commitmentModal.data.ammount }
                                dateSelected={ dateSelected }
                                campaign={ campaign }
                                handlerClose={ () => { setCommitmentModal( { ...commitmentModal, visible: false } ) } }
                                refreshAction={ refreshData }
                                clients_groups={ clients_status.groups }
                                 />
            }
        </div>
    )
}

export default connect( state => ({ campaign: state.campaign, clients_status: state.clients, sellers_status: state.sellers, userdata_status: state.userdata } ) )( SalesStatePage )