import Api from './Api';

const clientsGroupsService = {

  get: ( year ) => {

    return Api.get( '/groups/' + year, {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/groups', jsonData, {
        withCredentials: true
      } )

  },
  delete: ( groupId, year ) => {

    return Api.delete( `/groups/${year}/${groupId}`, {
      withCredentials: true
    })
  }

}

export default clientsGroupsService
