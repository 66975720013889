const sellersReducer = ( sellers = { loading: true, error: null, data: null }, action ) => {
  
      if ( action.type === 'START_FETCH_SELLERS' ){
        return { loading: true, error: null, data: null };
      }

      if ( action.type === 'FAIL_FETCH_SELLERS' ){
        return { loading: false, error: true, data: null };
      }

      if ( action.type === 'RECEIVE_FETCH_SELLERS' ){
        return { loading: false, error: true, data: [ ...action.payload ] };
      }

      return sellers;
    
    }
    
export default sellersReducer;
    