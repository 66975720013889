import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import { FiUserPlus } from 'react-icons/fi'
import Swal from 'sweetalert2';
import sellersService from '../../../../services/Sellers';


// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewSeller = ( { groups, handlerClose, refreshAction, refreshGroups } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ newSellerData, setNewSellerData ] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: ''
  })

  const addSeller = () => {

    if ( !newSellerData.firstname || !newSellerData.email ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Invitando al vendedor..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    sellersService.invite( newSellerData )
      .then( res => {
        refreshAction();
        Swal.close();
        handlerClose()
      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

            <div className="modalContainer creation">
                <div className="column">
                    <FiUserPlus />
                </div>
                <div className="data">
                    <div className="form">
                        <div className="line email">
                            <div className="form-input">
                            <input type="text" placeholder="Email" value={ newSellerData.email } onChange={ ev => { setNewSellerData( { ...newSellerData, email: ev.target.value } ) } } />
                            </div>
                        </div>
                        <div className="line name">
                            <div className="form-input">
                            <input type="text"placeholder="Nombre" value={ newSellerData.firstname } onChange={ ev => { setNewSellerData( { ...newSellerData, firstname: ev.target.value } ) } } />
                            </div>

                            <div className="form-input">
                            <input type="text"placeholder="Apellido" value={ newSellerData.lastname } onChange={ ev => { setNewSellerData( { ...newSellerData, lastname: ev.target.value } ) } } />
                            </div>
                        </div>
                        <div className="line contraseña">
                            <div className="form-input">
                            <input type="text" placeholder="Contraseña" value={ newSellerData.password } onChange={ ev => { setNewSellerData( { ...newSellerData, password: ev.target.value } ) } } />
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <Button variant="primary" onClick={ addSeller } size="large" disabled={ loading }> Crear Cliente </Button>
                    </div>
                </div>
                </div>

      </Modal>
    </div>
  )
}

export default NewSeller