import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { GiBottledBolt } from 'react-icons/gi'
import Filter from './components/Filter'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import { getProducts, getProductsGroups, getProductsLines } from '../../../store/actions/getProducts'
import ProductsList from './components/ProductsList'
import NewProduct from './components/NewProduct'
import { LoadingPage } from '../loading/LoadingPage'

const ProductsPage = ({ products_status, getProducts, getProductsGroups, getProductsLines, userdata, campaign }) => {

  const [ filterString, setFilterString ] = useState( '' );
  const [ productsListFiltered, setProductsListFiltered ] = useState( [] )
  const [ newProduct, setNewProduct ] = useState( { visible: false, editionProduct: null } )
  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    
    if ( !products_status.list.loading && products_status.list.data ){

      const fullList = products_status.list.data.filter( product => {

        const stringToMatch = `${ product.name } ${ product.code } ${ product.group_name }`
  
        return stringToMatch.toUpperCase().includes( filterString.toUpperCase() )
  
      } )
  
      setProductsListFiltered( fullList );

    }

  }, [ filterString, products_status.list ] )

  if ( products_status.list.loading || products_status.groups.loading || products_status.lines.loading || userdata.loading ) return <LoadingPage />

  return (
    <div className='page products page-list'>
        <div className='row'>
          <PageHeader title="Productos" icon={ <GiBottledBolt /> } />
          {
            userdata.data.type === 10 &&
            <div className='actions'>
              <div className='action add'>
                <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewProduct( { visible: true, editionProduct: null } ) } }> Crear Nuevo </Button>
              </div>
            </div>
          }
        </div>
        <div className='body'>

            <Filter filterString={ filterString } setFilterString={ setFilterString } />

            <ProductsList 
                      products={ productsListFiltered } 
                      groups={ products_status.groups.data } 
                      lines={ products_status.lines.data }
                      setNewProduct={ setNewProduct }
                      campaign={ campaign }
                      edition={ userdata.data.type === 10 }
                      reloadAction={ getProducts } />

        </div>
        { newProduct.visible && <NewProduct 
                                      handlerClose={ () => { setNewProduct( { visible: false, editionProduct: null } ) } } 
                                      groups={ products_status.groups.data } 
                                      lines={ products_status.lines.data } 
                                      refreshAction={ getProducts }
                                      campaign={ campaign }
                                      editionProduct={ newProduct.editionProduct }
                                      refreshGroups={ getProductsGroups }
                                      refreshLines={ getProductsLines } /> }
    </div>
  )
}

export default connect( state => ({ userdata: state.userdata, products_status: state.products, campaign: state.campaign } ), { getProducts, getProductsGroups, getProductsLines } )( ProductsPage )