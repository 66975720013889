import Api from './Api';

const salesService = {

  get: ( { year, month } ) => {

    return Api.get( `/sales/yearmonth/${ year }/${ month }`, {
      withCredentials: true
    } )
  },
  getForClient: ( { year, month, client } ) => {

    return Api.get( `/sales/yearmonth/client/${ year }/${ month }/${ client }`, {
      withCredentials: true
    } )
  },
  getForProduct: ( { year, month, product } ) => {

    return Api.get( `/sales/yearmonth/product/${ year }/${ month }/${ product }`, {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

    return Api.post( '/sales', jsonData, {
      withCredentials: true
    } )

  },
  put: ( jsonData ) => {

    return Api.put( '/sales/' + jsonData.id, jsonData, {
      withCredentials: true
    } )

  },
  uploadExcel: ( jsonData ) => {

    var bodyFormData = new FormData();
    bodyFormData.append('file', jsonData.file);
    bodyFormData.append('forceExisting', jsonData.forceExisting);

      return Api.post( '/sales/excel', bodyFormData, {
        withCredentials: true
      } )

  },
  delete: ( saleId ) => {

    return Api.delete( `/sales/${ saleId }`, {
      withCredentials: true
    })
  },
  deleteMonth: ( year, month ) => {

    return Api.delete( `/sales/month/${ year }/${ month }`, {
      withCredentials: true
    })

  }

}

export default salesService
