import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { BiLineChartDown } from 'react-icons/bi'
import { Radio } from 'antd'
import { connect } from 'react-redux'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { LoadingPage } from '../../loading/LoadingPage'
import { ForecastGraph } from './components/graphs/ForecastGraph'
import { ActualForecastSummery } from './components/graphs/ActualForecastSummery'
import { RankingProductsGraph } from './components/graphs/RankingProductsGraph'
// import { SalesList } from './components/graphs/SalesList'
// import { RevenueList } from './components/graphs/RevenueList'
import { EmptyContainer } from '../../sales/components/EmptyContainer'
// import { ConsumeGraph } from './components/graphs/ConsumeGraph'
import './totals.css'
import graphService from '../../../../services/Graph'
import { RankingClientsGraph } from './components/graphs/RankingClientsGraph'
import Currency from '../../../../components/Currency'

const TotalsPage = ({ clients_status, products_status, campaign }) => {

  const [ filters, setFilters ] = useState( { 
                                                exchange: 'USD'
                                            } )
  const [ graphFetchStatus, setGraphFetchStatus ] = useState( { loading: true, error: null, data: null } )

  useEffect( () => {

    graphService.getTotalsGraphData( { 
        filters: {
         date: {
           year: campaign, 
         },
         exchange: filters.exchange
        }
       } )
       .then( res => {
            setGraphFetchStatus( { loading: false, error: null, data: res } )
        } )
        .catch( err => {
            setGraphFetchStatus( { loading: false, error: true, data: null } )
        } )

  }, [ filters, campaign ] )

  if ( 
    clients_status.list.loading    || 
    clients_status.groups.loading  || 
    products_status.list.loading   || 
    products_status.groups.loading || 
    products_status.lines.loading  ||
    graphFetchStatus.loading
  ) return <LoadingPage />

  return (
    <div className={ `page totals state` }>
      <PageHeader title="Totales del año" icon={ <BiLineChartDown /> } >
        <div className='options'>
            <Radio.Group buttonStyle="solid" value="large" onChange={ e => setFilters({ ...filters, exchange: e.target.value }) }>
                <Radio.Button className={ filters.exchange === "LOCAL" ? 'selected' : '' } value="LOCAL"><Currency /></Radio.Button>
                <Radio.Button className={ filters.exchange === "USD" ? 'selected' : '' } value="USD">USD</Radio.Button>
            </Radio.Group>
        </div>
      </PageHeader>
      {
        !graphFetchStatus.loading && 
        graphFetchStatus.data
        ?
        (
          <div className='body'>

                    <ForecastGraph 
                                    fetchStatus={ graphFetchStatus } 
                                    products={ products_status.list } 
                                    exchange={ filters.exchange } />
              

                      <ActualForecastSummery 
                                    fetchStatus={ graphFetchStatus } 
                                    exchange={ filters.exchange } />

                      {/* <ConsumeGraph 
                                    fetchStatus={ graphFetchStatus }
                                    products={ products_status.list }  
                                  /> */}

              
                      <RankingProductsGraph 
                                    fetchStatus={ graphFetchStatus } 
                                    products={ products_status.list } 
                                    exchange={ filters.exchange }
                                    />

                        <RankingClientsGraph
                                    fetchStatus={ graphFetchStatus } 
                                    clients={ clients_status.list } 
                                    clients_groups={ clients_status.groups } 
                                    exchange={ filters.exchange }
                                    />

                    {/* <RevenueList 
                                fetchStatus={ graphFetchStatus } 
                                products={ products_status.list } 
                                exchange={ filters.exchange } /> */}

                              
          </div>
        )
        :
        <EmptyContainer title="Sin datos" message="No cargaste datos de ventas ni pronósticos para estos filtros" />
        
      }
      
    </div>
  )


}


export default connect( state => ({ campaign: state.campaign, products_status: state.products, clients_status: state.clients } ) )( TotalsPage )
