import Api from './Api';

const sellersService = {

  get: (  ) => {

    return Api.get( '/users/sellers', {
      withCredentials: true
    } )
  },
  invite: ( jsonData ) => {

      return Api.post( '/users/sellers/invite', jsonData, {
        withCredentials: true
      } )

  },
  suspend: ( sellerId ) => {

    return Api.post( '/users/sellers/suspend', { user_id: sellerId }, {
      withCredentials: true
    } )

  },
  activate: ( sellerId ) => {

    return Api.post( '/users/sellers/activate', { user_id: sellerId }, {
        withCredentials: true
    } )

  }

}

export default sellersService
