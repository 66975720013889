import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Input } from 'antd';
import './selectClientModal.css'


  // ESTILO DEL MODAL DE INVITACION DE USUARIO
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      background            : 'white',
      padding               : '0',
      height                : '50vh',
      width                 : '30vw',
      overflow              : 'hidden'
    }
  };

const SelectClientModal = ( { clients, handlerClose, selectClientGroup } ) => {

    const [ stringFilter, setStringFilter ] = useState( '' )
    const [ filteredClients, setFilteredClients ] = useState( clients.list.data )

    useEffect( () => {

      const fullList = clients.groups.data.filter( clientgroup => {

        const stringToMatch = `${ clientgroup.name }`
  
        return stringToMatch.toUpperCase().includes( stringFilter.toUpperCase() )
  
      } )

        setFilteredClients( fullList )

    }, [ stringFilter, clients.list.data ] )

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer list select-client">
            
            <div className='filter'>
                <Input value={ stringFilter } onChange={ ev => { setStringFilter( ev.target.value ) } } placeholder="Buscar cliente.." />
            </div>

            <div className='clients-list'>
                {
                    filteredClients.map( clientgroup => {

                        return (
                            <div className='item-client' key={ clientgroup.id } onClick={ () => { selectClientGroup( clientgroup.id ); handlerClose() } }>
                                <div className='data'>
                                    <div className='name'>{ clientgroup.name }</div>
                                </div>
                                {/* <div className='group'>{ clientGroup.name }</div> */}
                            </div>
                        )

                    } )
                }
            </div>

        </div>

      </Modal>
    </div>
  )
}

export default SelectClientModal
