import Api from './Api';

const forecastService = {

  get: ( { year } ) => {

    return Api.get( `/forecast/year/${ year }`, {
      withCredentials: true
    } )
  },
  getForProduct: ( { year, product } ) => {

    return Api.get( `/forecast/year/product/${ year }/${ product }`, {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

    return Api.post( '/forecast', jsonData, {
      withCredentials: true
    } )

  },
  put: ( jsonData ) => {

    return Api.put( '/forecast/' + jsonData.id, jsonData, {
      withCredentials: true
    } )

  },
  uploadExcel: ( jsonData ) => {

    var bodyFormData = new FormData();
    bodyFormData.append('file', jsonData.file);
    bodyFormData.append('year', jsonData.year);
    bodyFormData.append('forceExisting', jsonData.forceExisting);

      return Api.post( '/forecast/excel', bodyFormData, {
        withCredentials: true
      } )

  },
  delete: ( forecastId ) => {

    return Api.delete( `/forecast/${ forecastId }`, {
      withCredentials: true
    })
  },
  deleteYear: ( year ) => {

    return Api.delete( `/forecast/year/${ year }`, {
      withCredentials: true
    })
  }

}

export default forecastService
