import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Select } from 'antd';
import { GiBottledBolt } from 'react-icons/gi'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import Swal from 'sweetalert2';
import productsGroupsService from '../../../../services/ProductsGroups';
import productsService from '../../../../services/Products';
import productsLinesService from '../../../../services/ProductsLines';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewProduct = ( { groups, lines, handlerClose, refreshAction, refreshGroups, refreshLines, editionProduct, campaign } ) => {

  const [ pageVisible, setPageVisible ] = useState( 'PRODUCT' )
  const [ loading, setLoading ] = useState( false )
  const [ newProductData, setNewProductData ] = useState( editionProduct ? { ...editionProduct, price: productsService.getPriceFromYear( editionProduct, campaign ) } : {
    name: '',
    price: '',
    consume: '',
    price_2021: null,
    price_2022: null,
    price_2023: null,
    price_2024: null,
    price_2025: null,
    group: groups[ 0 ].id,
    line: lines[ 0 ].id
  })
  const [ newGroupData, setNewGroupData ] = useState({
    name: ''
  })
  const [ newLineData, setNewLineData ] = useState({
    name: ''
  })

  const addProduct = () => {

    if ( !newProductData.name || !newProductData.group || !newProductData.price || !newProductData.line ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando el producto..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    const payload = {
      ...newProductData,
      price: undefined,
      [ "price_" + campaign ]: newProductData.price
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    const apiFx = !editionProduct ? productsService.post : productsService.put

    apiFx( payload )
      .then( res => {
        refreshAction();
        Swal.close();
        handlerClose()
      } )

  }
  
  const addGroup = () => {

    if ( !newGroupData.name ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })

      return;
    }

    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando el grupo de producto..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    productsGroupsService.post( newGroupData )
      .then( res => {
        Swal.close();
        refreshGroups();
        setPageVisible( 'PRODUCT' )
      } )

  }
  
  const addLine = () => {

    if ( !newLineData.name ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })

      return;
    }

    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Creando el grupo de producto..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    productsLinesService.post( newLineData )
      .then( res => {
        Swal.close();
        refreshLines();
        setPageVisible( 'PRODUCT' )
      } )

  }

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        {
          loading
          ?
          "loading.."
          :
          (
            pageVisible === 'PRODUCT'
            ?
            <ProductModalBody
                    newProductData={ newProductData }
                    setNewProductData={ setNewProductData }
                    setPageVisible={ setPageVisible }
                    groups={ groups }
                    campaign={ campaign }
                    lines={ lines }
                    addProduct={ addProduct }
                    />
            :
            (
                pageVisible === 'GROUP'
                ?
                <ProductGroupModalBody
                    newGroupData={ newGroupData }
                    setNewGroupData={ setNewGroupData }
                    setPageVisible={ setPageVisible }
                    addGroup={ addGroup }
                    />
                :
                <ProductLineModalBody
                    newLineData={ newLineData }
                    setNewLineData={ setNewLineData }
                    setPageVisible={ setPageVisible }
                    addLine={ addLine }
                    />
            )
          )
        }

      </Modal>
    </div>
  )
}

export default NewProduct


const ProductModalBody = ({ newProductData, setNewProductData, setPageVisible, groups, lines, addProduct, campaign }) => {

  return (
    <div className="modalContainer creation">
      <div className="column">
        <GiBottledBolt />
      </div>
      <div className="data">
        <div className="form">
          <div className="line name">
            <div className="form-input">
              <input type="text" placeholder="Nombre" value={ newProductData.name } onChange={ ev => { setNewProductData( { ...newProductData, name: ev.target.value } ) } } />
            </div>
          </div>
          <div className="line faena">
            <div className="form-input">
              <input type="number" placeholder="Precio" value={ newProductData.price } onChange={ ev => { setNewProductData( { ...newProductData, price: ev.target.value } ) } } />
            </div>
          </div>
          <div className="line extra">
            <div className="form-select">
              <Select value={ newProductData.group } onChange={ group => { setNewProductData({ ...newProductData, group }) } }>
                {
                  groups.map( group => <Option key={ group.id } value={ group.id }> { group.name } </Option> )
                }
              </Select>
            </div>
            <Button variant="primary" onClick={ () => { setPageVisible( 'GROUP' )  } }> Crear Grupo </Button>
          </div>
          <div className="line extra">
            <div className="form-select">
              <Select value={ newProductData.line } onChange={ line => { setNewProductData({ ...newProductData, line }) } }>
                {
                  lines.map( line => <Option key={ line.id } value={ line.id }> { line.name } </Option> )
                }
              </Select>
            </div>
            <Button variant="primary" onClick={ () => { setPageVisible( 'LINE' )  } }> Crear Linea </Button>
          </div>
          <div className="line consume">
            <div className="form-input">
              <input type="number" placeholder="Consumo por ave" value={ newProductData.consume } onChange={ ev => { setNewProductData( { ...newProductData, consume: ev.target.value } ) } } />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button variant="primary" onClick={ addProduct } size="large"> Guardar Producto </Button>
        </div>
      </div>
    </div>
  )
}

const ProductGroupModalBody = ({ newGroupData, setNewGroupData, setPageVisible, addGroup }) => {

  return (
    <div className="modalContainer creation">
      <div className="column">
        <AiOutlineAppstoreAdd />
      </div>
      <div className="data">
        <div className="form">
          <div className="line name">
            <div className="form-input">
              <input type="text"placeholder="Nombre" value={ newGroupData.name } onChange={ ev => { setNewGroupData( { ...newGroupData, name: ev.target.value } ) } } />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button type="text" size="large" onClick={ () => { setPageVisible( 'PRODUCT' ) } }> Volver </Button>
          <Button variant="primary" onClick={ addGroup } size="large"> Crear Grupo </Button>
        </div>
      </div>
    </div>
  )
}

const ProductLineModalBody = ({ newLineData, setNewLineData, setPageVisible, addLine }) => {

    return (
      <div className="modalContainer creation">
        <div className="column">
          <AiOutlineAppstoreAdd />
        </div>
        <div className="data">
          <div className="form">
            <div className="line name">
              <div className="form-input">
                <input type="text"placeholder="Nombre" value={ newLineData.name } onChange={ ev => { setNewLineData( { ...newLineData, name: ev.target.value } ) } } />
              </div>
            </div>
          </div>
          <div className="footer">
            <Button type="text" size="large" onClick={ () => { setPageVisible( 'PRODUCT' ) } }> Volver </Button>
            <Button variant="primary" onClick={ addLine } size="large"> Crear Linea </Button>
          </div>
        </div>
      </div>
    )
  }