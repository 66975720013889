import Api from './Api';

const loginService = {

  login: ( jsonData, jsonCallbacks ) => {

    return Api.post('/login', jsonData, { withCredentials: true } )
  },
  logout: () => {

    return Api.post('/logout', null, { withCredentials: true } )

  }

}

export default loginService
