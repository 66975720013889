import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { FiUsers } from 'react-icons/fi'
import Filter from './components/Filter'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import { getSellers } from '../../../store/actions/getSellers'
import SellersList from './components/SellersList'
import NewSeller from './components/NewSeller'
import './sellers.css'
import { LoadingPage } from '../loading/LoadingPage'

const SellersPage = ({ sellers_status, getSellers }) => {

  const [ filterString, setFilterString ] = useState( '' );
  const [ sellersListFiltered, setSellersListFiltered ] = useState( [] )
  const [ newSellerVisible, setNewSellerVisible ] = useState( false )

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    
    if ( !sellers_status.loading && sellers_status.data ){

      const fullList = sellers_status.data.filter( seller => {

        const stringToMatch = `${ seller.name } ${ seller.code } ${ seller.lastname } ${ seller.email }`
  
        return stringToMatch.toUpperCase().includes( filterString.toUpperCase() )
  
      } )
  
      setSellersListFiltered( fullList );

    }

  }, [ filterString, sellers_status ] )

  if ( sellers_status.loading ) return <LoadingPage />

  return (
    <div className='page sellers page-list'>
        <div className='row'>
          <PageHeader title="Vendedores" icon={ <FiUsers /> } />
          <div className='actions'>
            <div className='action add'>
              <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewSellerVisible( true ) } }> Crear Nuevo </Button>
            </div>
          </div>
        </div>
        <div className='body'>

            <Filter filterString={ filterString } setFilterString={ setFilterString } />

            <SellersList sellers={ sellersListFiltered } reloadAction={ getSellers } />

        </div>
        { newSellerVisible && <NewSeller 
                                    handlerClose={ () => { setNewSellerVisible( false ) } } 
                                    refreshAction={ getSellers }
                                     /> }
    </div>
  )
}

export default connect( state => ({ sellers_status: state.sellers } ), { getSellers } )( SellersPage )