import React, { useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { connect } from 'react-redux'
import { RiExchangeDollarFill } from 'react-icons/ri'
import { MONTHS } from '../../../constants/constants'
import { MonthExchangeCard } from './components/MonthExchangeCard'
import './exchangePage.css'
import moment from 'moment'
import NewMonthExchange from './components/NewMonthExchange'
import { getExchanges } from '../../../store/actions/getExchanges'
import { LoadingPage } from '../loading/LoadingPage'

const ExchangePage = ({ exchanges_status, getExchanges, yearSelected, userdata }) => {

  const [ newExchangeModal, setNewExchangeModal ] = useState( { visible: false, data: null } )

  const openNewExchangeModal = ( month, editionMode, initialValue ) => {

    setNewExchangeModal({
      visible: true,
      data: { month: month, year: yearSelected, editionMode: editionMode, initialValue: initialValue }
    })

  }

  if ( exchanges_status.loading || userdata.loading ) return <LoadingPage />

  return (
    <div className='page exchange'>
        <div className='row'>
          <PageHeader title="Tipo de cambio" icon={ <RiExchangeDollarFill /> } />
          <div className='actions'>
            <div className='action add'>
              {/* <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewClientVisible( true ) } }> Crear Nuevo </Button> */}
            </div>
          </div>
        </div>
        <div className='body'>
            <div className='months-container'>
                <div className='row'>
                  {
                      MONTHS.slice( 0, 4 ).map( ( month, monthIndex ) => {

                        const yearMonthExchange = exchanges_status.data && exchanges_status.data.find( ( ex, exchangeIndex ) => {
                          
                          return moment( ex.date ).year() === yearSelected && moment( ex.date ).month() === monthIndex
                        } )

                        return <MonthExchangeCard 
                                          key={ monthIndex } 
                                          index={ monthIndex } 
                                          edition={ userdata.data.type === 10 }
                                          value={ yearMonthExchange && yearMonthExchange.value }
                                          openModal={ ( ) => { openNewExchangeModal( monthIndex, Boolean( yearMonthExchange && yearMonthExchange.value ), yearMonthExchange && yearMonthExchange.value ) } } />

                      } )
                  }
                </div>
                <div className='row'>
                  {
                      MONTHS.slice( 4, 8 ).map( ( month, monthIndex ) => {

                        const yearMonthExchange = exchanges_status.data && exchanges_status.data.find( ( ex, exchangeIndex ) => {
                          
                          return moment( ex.date ).year() === yearSelected && moment( ex.date ).month() === ( 4 + monthIndex )
                        } )

                        return <MonthExchangeCard 
                                          key={ 4 + monthIndex } 
                                          index={ 4 + monthIndex } 
                                          edition={ userdata.data.type === 10 }
                                          value={ yearMonthExchange && yearMonthExchange.value }
                                          openModal={ ( ) => { openNewExchangeModal( 4 + monthIndex, Boolean( yearMonthExchange && yearMonthExchange.value ), yearMonthExchange && yearMonthExchange.value ) } } />

                      } )
                  }
                </div>
                <div className='row'>
                  {
                      MONTHS.slice( 8, 12 ).map( ( month, monthIndex ) => {

                        const yearMonthExchange = exchanges_status.data && exchanges_status.data.find( ( ex, exchangeIndex ) => {
                          
                          return moment( ex.date ).year() === yearSelected && moment( ex.date ).month() === ( 8 + monthIndex )
                        } )

                        return <MonthExchangeCard 
                                          key={ 8 + monthIndex } 
                                          index={ 8 + monthIndex } 
                                          edition={ userdata.data.type === 10 }
                                          value={ yearMonthExchange && yearMonthExchange.value }
                                          openModal={ ( ) => { openNewExchangeModal( 8 + monthIndex, Boolean( yearMonthExchange && yearMonthExchange.value ), yearMonthExchange && yearMonthExchange.value ) } } />

                      } )
                  }
                </div>
            </div>
        </div>
        { newExchangeModal.visible && <NewMonthExchange 
                                          handlerClose={ () => { setNewExchangeModal( { ...newExchangeModal, visible: false } ) } } 
                                          refreshAction={ getExchanges }
                                          month={ newExchangeModal.data?.month }
                                          year={ yearSelected }
                                          editionMode={ newExchangeModal.data?.editionMode }
                                          initialValue={ newExchangeModal.data?.initialValue }
                                          /> }
    </div>
  )
}

export default connect( state => ({ userdata: state.userdata, exchanges_status: state.exchanges, yearSelected: state.campaign } ), { getExchanges } )( ExchangePage )