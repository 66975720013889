import { Dropdown, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
import { MONTHS } from '../../../../../../constants/constants'
import './calendar-filter.css'

export const CalendarFilter = ({ dateSelected, selectDate }) => {

    const prevMonth = () => {

        if ( dateSelected.month > 0 ){
            selectDate( { month: dateSelected.month - 1 } )
        }

    }

    const nextMonth = () => {

        if ( dateSelected.month < 11 ){
            selectDate( { month: dateSelected.month + 1 } )
        }

    }

  return (
    <div className='calendar-filter'>
        <div className='tag'>Mes seleccionado</div>
        <div className='date-selected'>
            <div className='prev chevron' onClick={ prevMonth }> <GrFormPrevious /> </div>
            <Dropdown 
                menu={ { items: [  ] } }
                dropdownRender={(menu) => (
                    <CalendarMonthYearSelector dateSelected={ dateSelected } selectDate={ selectDate } />
                  )}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <div className='value'>
                            <div className='month'>{ MONTHS[ dateSelected.month ] }</div>
                            {/* <div className='year'>{ dateSelected.year }</div> */}
                        </div>
                    </Space>
                </a>
            </Dropdown>
            <div className='next chevron' onClick={ nextMonth }> <GrFormNext /> </div>
        </div>
    </div>
  )
}

const CalendarMonthYearSelector = ({ dateSelected, selectDate }) => {

  return (
    <div className='calendar-view-overlay'>
        <div className='month-selector'>
            <div className='row'>
                <div className={ `month ${ dateSelected.month === 0 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 0 } ) } }>ENE</div>
                <div className={ `month ${ dateSelected.month === 1 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 1 } ) } }>FEB</div>
                <div className={ `month ${ dateSelected.month === 2 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 2 } ) } }>MAR</div>
                <div className={ `month ${ dateSelected.month === 3 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 3 } ) } }>ABR</div>
            </div>
            <div className='row'>
                <div className={ `month ${ dateSelected.month === 4 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 4 } ) } }>MAY</div>
                <div className={ `month ${ dateSelected.month === 5 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 5 } ) } }>JUN</div>
                <div className={ `month ${ dateSelected.month === 6 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 6 } ) } }>JUL</div>
                <div className={ `month ${ dateSelected.month === 7 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 7 } ) } }>AGO</div>
            </div>
            <div className='row'>
                <div className={ `month ${ dateSelected.month === 8 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 8 } ) } }>SEP</div>
                <div className={ `month ${ dateSelected.month === 9 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 9 } ) } }>OCT</div>
                <div className={ `month ${ dateSelected.month === 10 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 10 } ) } }>NOV</div>
                <div className={ `month ${ dateSelected.month === 11 ? 'selected' : '' }` } onClick={ () => { selectDate( { ...dateSelected, month: 11 } ) } }>DIC</div>
            </div>
        </div>

    </div>
  )
}