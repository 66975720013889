import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { GiBottledBolt } from 'react-icons/gi'
import Filter from './components/Filter'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import SalesList from './components/SalesList'
import salesService from '../../../services/Sales'
import UploadExcelModal from './components/UploadExcelModal'
import './sales.css'
import moment from 'moment'
import NewSaleModal from './components/NewSaleModal'
import { LoadingPage } from '../loading/LoadingPage'

const SalesPage = ({ products_state, clients_state, userdata, campaign }) => {

  const [ filterString, setFilterString ] = useState( '' );
  const [ salesListFiltered, setSalesListFiltered ] = useState( [] )
  const [ monthYearSelected, setMonthYearSelected ] = useState( { month: moment().month() } )
  const [ uploadModalVisible, setUploadModalVisible ] = useState( false )
  const [ newSaleModal, setNewSaleModal ] = useState( { visible: false, data: { editionMode: false, saleData: null } } )
  const [ fetchingSalesStatus, setFetchingSalesStatus ] = useState( { loading: true, error: null, data: null } )

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    
    if ( 
      fetchingSalesStatus.data &&
      products_state.list.data &&
      clients_state.list.data
       ){

      const fullList = fetchingSalesStatus.data
                                            .filter( sale => {

                                              const product = products_state.list.data.find( p => Number( sale.product ) === Number( p.id ) )
                                              const client = clients_state.list.data.find( c => Number( sale.client ) === Number( c.id ) )

                                                const stringToMatch = `${ product.name } ${ client.name }`
                                                const stringFilter = stringToMatch.toUpperCase().includes( filterString.toUpperCase() )
                                        
                                                return stringFilter
                                        
                                            } )
  
      setSalesListFiltered( fullList );

    }

  }, [ filterString, fetchingSalesStatus.data, products_state.list, clients_state.list ] )

  const refreshSales = () => {

    const { month } = monthYearSelected

    setFetchingSalesStatus( { loading: true, error: null, data: null } )
    salesService.get({ month, year: campaign })
      .then( res => {
        setFetchingSalesStatus( { loading: false, error: null, data: res.data.data } )
      } )

  }

  const openEditionModal = ( saleId ) => {

    const saleData = fetchingSalesStatus.data.find( sale => Number( sale.id ) === Number( saleId ) )

    setNewSaleModal({ visible: true, data: { editionMode: true, saleData: saleData } })

  }

  useEffect( () => {
    refreshSales()
  }, [ monthYearSelected.month, campaign ] )

  if ( 
        fetchingSalesStatus.loading   || 
        clients_state.list.loading    || 
        clients_state.groups.loading  || 
        products_state.list.loading   || 
        products_state.groups.loading || 
        products_state.lines.loading ||
        userdata.loading
      ) return <LoadingPage />

  return (
    <div className='page sales page-list'>
        <div className='row'>
          <PageHeader title="Ventas" icon={ <GiBottledBolt /> } />
          {
            userdata.data.type === 10 &&
            <div className='actions'>
              <div className='action add'>
                <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setUploadModalVisible( true ) } }> Subir Excel </Button>
              </div>
              <div className='action add'>
                <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewSaleModal( { ...newSaleModal, visible: true, data: { editionMode: false, saleData: null } } ) } }> Subir Venta Manual </Button>
              </div>
            </div>
          }
        </div>
        <div className='body'>

            <div className='row'>
              {/* <MonthCard monthYearSelected={ monthYearSelected } setMonthYearSelected={ setMonthYearSelected } /> */}
              <Filter 
                      filterString={ filterString } 
                      setFilterString={ setFilterString } 
                      monthYearSelected={ monthYearSelected }
                      fetchingSalesStatus={ fetchingSalesStatus } 
                      setMonthYearSelected={ setMonthYearSelected } />
            </div>

            <SalesList 
                      sales={ salesListFiltered }
                      products={ products_state } 
                      clients={ clients_state } 
                      openEditionModal={ openEditionModal }
                      monthYearSelected={ monthYearSelected }
                      edition={ userdata.data.type === 10 } 
                      reloadAction={ refreshSales } />

        </div>
        {
          uploadModalVisible && <UploadExcelModal
                                      handlerClose={ () => { setUploadModalVisible( false ) } }
                                      refreshAction={ refreshSales } />
        }
        {
          newSaleModal.visible && <NewSaleModal
                                      handlerClose={ () => { setNewSaleModal( { ...newSaleModal, visible: false } ) } }
                                      products={ products_state }
                                      clients={ clients_state }
                                      month={ monthYearSelected.month }
                                      year={ campaign }
                                      data={ newSaleModal.data }
                                      refreshAction={ refreshSales }
                                              />
        }
    </div>
  )
}

export default connect( state => ({ campaign: state.campaign, userdata: state.userdata, products_state: state.products, clients_state: state.clients } ) )( SalesPage )