import React, { useState, useEffect } from 'react';
import { FaArrowsAltV } from 'react-icons/fa';
import { FiTrash } from 'react-icons/fi';
import { HiOutlinePencil } from 'react-icons/hi';
import Swal from 'sweetalert2';
import productsService from '../../../../services/Products';

// COMPONENTS

// ITEM LIST HEADER
const ProductListItemHeader = ({ setOrderedList, edition }) => {

  return (
    <div className="list-item header">
      <div className="column-data"> Nombre <div onClick={ () => { setOrderedList( 'NAME' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Precio <div onClick={ () => { setOrderedList( 'PRICE' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Grupo <div onClick={ () => { setOrderedList( 'GROUP' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Linea <div onClick={ () => { setOrderedList( 'LINE' ) } }> <FaArrowsAltV /> </div> </div>
      <div className="column-data"> Consumo x Ave <div onClick={ () => { setOrderedList( 'CONSUME' ) } }> <FaArrowsAltV /> </div> </div>
      { edition && <div className="column-data"> <FiTrash /> </div> }
    </div>
  )
}

// ITEM LIST DEL BODY
const ProductListItem = ( { product, groups, lines, reloadAction, setNewProduct, edition, campaign } ) => {

  const groupName = groups && groups.find( g => g.id === product.group )?.name || ''
  const lineName = lines && lines.find( l => l.id === product.line )?.name || ''
  const price = productsService.getPriceFromYear( product, campaign )

  return (
    <div className="list-item">
      <div className="column-data"> { product.name } </div>
      <div className="column-data"> { price } { price && <div className='exchange-tag'>USD</div> } </div>
      <div className="column-data"> { groupName } </div>
      <div className="column-data"> { lineName } </div>
      <div className="column-data"> { product.consume } </div>
      {
        edition &&
        <div className='column-data actions'>
          <div className='action edition' onClick={ () => { setNewProduct( { visible: true, editionProduct: product } ) } }> <HiOutlinePencil /> </div>
          <div className='action delete' onClick={ () => { deleteProduct( product.id, reloadAction ) } }> <FiTrash /> </div>
        </div>
      }
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const ProductsList = ( { products, groups, lines, reloadAction, setNewProduct, edition, campaign } ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ orderedList, setOrderedList ] = useState( { orderby: { item: null, desc: false }, list: products } )

  // EFECTO PARA ORDENAR LISTA
  useEffect( () => {

    let ordered = products
    if ( orderedList.orderby.item === 'NAME' ){

      ordered = products.sort( ( product1, product2 ) => {

        if ( orderedList.orderby.desc ){

          if ( product1.name < product2.name ) {
            return 1;
          }
          if ( product1.name > product2.name ) {
            return -1;
          }
          return 0;

        } else {

          if ( product1.name > product2.name ) {
            return 1;
          }
          if ( product1.name < product2.name ) {
            return -1;
          }
          return 0;

        }

      } );

    } else if ( orderedList.orderby.item === 'GROUP' ){

      ordered = products.sort( ( product1, product2 ) => {

        const groupData1 = groups && groups.find( g => g.id === product1.group )
        const groupData2 = groups && groups.find( g => g.id === product2.group )

        if ( orderedList.orderby.desc ){

          if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
            return 1;
          }
          if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
            return -1;
          }
          return 0;

        } else {

          if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
            return 1;
          }
          if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
            return -1;
          }
          return 0;

        }

      } );

    } else if ( orderedList.orderby.item === 'LINE' ){
      
      ordered = products.sort( ( product1, product2 ) => {

        const groupData1 = lines && lines.find( g => g.id === product1.line )
        const groupData2 = lines && lines.find( g => g.id === product2.line )

        if ( orderedList.orderby.desc ){

          if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
            return 1;
          }
          if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
            return -1;
          }
          return 0;

        } else {

          if ( ( groupData1 && groupData1.name || '' ) > ( groupData2 && groupData2.name || '' ) ) {
            return 1;
          }
          if ( ( groupData1 && groupData1.name || '' ) < ( groupData2 && groupData2.name || '' ) ) {
            return -1;
          }
          return 0;

        }

      } );

    } else if ( orderedList.orderby.item === 'PRICE' ){
      
      ordered = products.sort( ( product1, product2 ) => {
        
        if ( orderedList.orderby.desc ){
  
          if ( product1.price < product2.price ) {
            return 1;
          }
          if ( product1.price > product2.price ) {
            return -1;
          }
          return 0;
  
        } else {
  
          if ( product1.price > product2.price ) {
            return 1;
          }
          if ( product1.price < product2.price ) {
            return -1;
          }
          return 0;
  
        }
      } );

    } else if ( orderedList.orderby.item === 'CONSUME' ){
      
      ordered = products.sort( ( product1, product2 ) => {
        
        if ( orderedList.orderby.desc ){
  
          if ( product1.consume < product2.consume ) {
            return 1;
          }
          if ( product1.consume > product2.consume ) {
            return -1;
          }
          return 0;
  
        } else {
  
          if ( product1.consume > product2.consume ) {
            return 1;
          }
          if ( product1.consume < product2.consume ) {
            return -1;
          }
          return 0;
  
        }
      } );

    }

    setOrderedList( {
      ...orderedList,
      list: ordered
    } )

  }, [ products, orderedList.orderby ] )

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = orderedList.list
                      .map( product => <ProductListItem 
                                                key={ product.id }
                                                product={ product }
                                                groups={ groups }
                                                lines={ lines }
                                                campaign={ campaign }
                                                edition={ edition }
                                                setNewProduct={ setNewProduct }
                                                reloadAction={ reloadAction }  /> )
                      // .slice( ( activePage - 1 ) * pageSize , ( activePage - 1 ) + pageSize);

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="list-container">
      <div className="list">
        <ProductListItemHeader edition={ edition } setOrderedList={ ( orderby ) => { setOrderedList( { ...orderedList, orderby: { item: orderby, desc: !orderedList.orderby.desc } } ) } } />
        { pageList }
      </div>
    </div>
  )
}

// FUNCTIONS

// BAJA DE USUARIO
const deleteProduct = ( productId, reloadAction ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: 'Eliminar Producto',
            text: 'Estás seguro que deseas eliminar el producto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, eliminarlo!',
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return productsService.delete( productId )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                'Eliminado!',
                'Producto eliminado exitosamente!',
                'success'
              )

              reloadAction();
            }
          })
}

export default ProductsList
