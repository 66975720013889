import { Button } from 'antd'
import React from 'react'
import { MONTHS } from '../../../../constants/constants'
import { HiOutlinePencil } from 'react-icons/hi'

import './monthExchangeCard.css'
import Currency from '../../../../components/Currency'

export const MonthExchangeCard = ({ index, value, openModal, edition }) => {

  const monthName = MONTHS[ index ]

  return (
    <div className='card month-exchange'>
        <div className='header'>{ monthName }</div>
        <div className='body'>
          {
            value
            ?
            <>
              <div className='usd'>1 USD</div>
              <div className='equal'>=</div>
              <div className='peso'>{ value || '-' } <span className='unit'> <Currency /> </span></div>
              {
                edition &&
                <div className='edition' onClick={ openModal }>
                  <HiOutlinePencil />
                </div>
              }
            </>
            :
            <>
              <div className='message'>Sin datos</div>
              <div className='action'> <Button onClick={ openModal }> Cargar </Button> </div>
            </>
          }
        </div>
    </div>
  )
}
