import Checkbox from 'antd/es/checkbox/Checkbox'
import React from 'react'
import Currency from '../../../../../../components/Currency'
import { MONTHS } from '../../../../../../constants/constants'
import './menu.css'

export const FilterMenu = ({
    filters,
    setFilters,
    products,
    client_groups,
    selectProduct
}) => {

    const handleChangeSelector = clientGroupId => {

        setFilters( prev => {

            const client_groups = prev.client_groups.indexOf( clientGroupId ) > -1 ? prev.client_groups.filter( p => p !== clientGroupId ) : [ ...prev.client_groups, clientGroupId ] 

            return {
                ...prev,
                client_groups
            }
        })

    }

    const changeGroupByHandler = value => {

        const actualProduct = filters.product

        if ( value === 'PRODUCT' ){

            if ( actualProduct.groupby === 'PRODUCT' ){

                return

            } else if ( actualProduct.groupby === 'GROUP' ){
    
                const product = products.list.data.find( p => Number( p.group ) === Number( actualProduct.data.id ) )
                selectProduct( { data: product, groupby: value } )


            } else if ( actualProduct.groupby === 'LINE' ){
    
                const product = products.list.data.find( p => Number( p.line ) === Number( actualProduct.data.id ) )
                selectProduct( { data: product, groupby: value } )

            }

        } else if ( value === 'GROUP' ){

            if ( actualProduct.groupby === 'PRODUCT' ){

                const group = products.groups.data.find( pg => Number( pg.id ) === Number( actualProduct.data.group ) )
                selectProduct( { data: group, groupby: value } )

            } else if ( actualProduct.groupby === 'GROUP' ){

                return
    
            } else if ( actualProduct.groupby === 'LINE' ){
    
                const group = products.groups.data[ 0 ]
                selectProduct( { data: group, groupby: value } )

            }

        } else if ( value === 'LINE' ){

            if ( actualProduct.groupby === 'PRODUCT' ){

                const line = products.lines.data.find( pl => Number( pl.id ) === Number( actualProduct.data.line ) )
                selectProduct( { data: line, groupby: value } )

            } else if ( actualProduct.groupby === 'GROUP' ){
                
                const line = products.lines.data[ 0 ]
                selectProduct( { data: line, groupby: value } )
    
            } else if ( actualProduct.groupby === 'LINE' ){
    
                return 

            }

        }

      }

    const selectAll = type => {

        if ( type === 'ALL' ){

            setFilters({ ...filters, client_groups: client_groups.data.map( p => p.id ) })

        } else if ( type === 'NONE' ){

            setFilters({ ...filters, client_groups: [] })

        }

    }

    return (
        <div className="filter-menu">

            <div className='item'>
                <div className='title'>AGRUPAR POR</div>
                <div className='options'>
                    <div 
                        className={ `option ${filters.product.groupby === "PRODUCT" ? 'selected' : ''}` }
                        onClick={ () => changeGroupByHandler("PRODUCT") }>
                            PRODUCTO
                    </div>
                    <div 
                        className={ `option ${filters.product.groupby === "GROUP" ? 'selected' : ''}` }
                        onClick={ () => changeGroupByHandler("GROUP") }>
                            GRUPO
                    </div>
                    <div 
                        className={ `option ${filters.product.groupby === "LINE" ? 'selected' : ''}` }
                        onClick={ () => changeGroupByHandler("LINE") }>
                            LINEA
                    </div>
                </div>
            </div>

            <div className="item">
                <div className="title">MES</div>
                <div className="options">
                    {
                        MONTHS.map( (monthName, index) => (
                            <div 
                                key={monthName} 
                                className={`option ${ index === filters.date.month ? 'selected' : '' }`}
                                onClick={ () => { setFilters( { ...filters, date: { month: index } } ) } }>
                                    { monthName }
                            </div>
                        ) )
                    }
                </div>
            </div>

            <div className="item">
                <div className="title">MONEDA</div>
                <div className="options row">
                    <div 
                        className={ `option ${ filters.exchange === 'LOCAL' ? 'selected' : '' }` }
                        onClick={ () => setFilters({ ...filters, exchange: 'LOCAL' }) }>
                        <Currency />
                    </div> 
                    | 
                    <div 
                        className={ `option ${ filters.exchange === 'USD' ? 'selected' : '' }` }
                        onClick={ () => setFilters({ ...filters, exchange: 'USD' }) }>
                        USD
                    </div>
                </div>
            </div>

            <div className="item">
                <div className="title">Grupos económicos</div>

                <div className="options row" style={{ paddingBottom: '10px' }}>
                    <div className="options row">
                        <div 
                            className={ `option` }
                            onClick={ () => { selectAll( 'NONE' ) } }>
                                Ninguno
                        </div> 
                        | 
                        <div 
                            className={ `option` }
                            onClick={ () => { selectAll( 'ALL' ) } }>
                                Todos
                        </div>
                    </div>
                </div>
                
                <div className="options">
                    {
                        client_groups.data.map( client_group => (
                            <div className="item" key={ client_group.id } onClick={ () => handleChangeSelector( client_group.id ) }>
                                <Checkbox checked={ filters.client_groups.indexOf( client_group.id ) > -1 } />
                                <span style={{ paddingLeft: '5px' }}> { client_group.name } </span>
                            </div>    
                        ) )
                    }
                </div>
            </div>

            <div className="item">
                <div className="title">CLIENTES</div>
                <div className="options">
                    
                </div>
            </div>
        </div>
    )

}