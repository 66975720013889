import React from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';


import loginService from '../../../../services/Login';
import { Button } from 'antd';
import { LoadingPage } from '../../loading/LoadingPage';
import { FiUser } from 'react-icons/fi';

const AccountCard = ( { user_status } ) => {

  const history = useHistory();

  if ( user_status.loading ) return <LoadingPage />

  return (
    <div className="card full">
      <div className="title"> Mi cuenta </div>
      <div className="data">
        <div className="icon">
          <FiUser />
        </div>
        <div className="dataValues">
          <div className="column">
            <div className="dataItem">
              <div className="tag"> Nombre </div>
              <div className="value"> { user_status.data.firstname } </div>
            </div>
            <div className="dataItem">
              <div className="tag"> Apellido </div>
              <div className="value"> { user_status.data.lastname } </div>
            </div>
            <div className="dataItem">
              <div className="tag"> Email </div>
              <div className="value"> { user_status.data.email } </div>
            </div>
          </div>
          <div className="column">
            <div className="dataItem">
              <div className="tag"> Estado </div>
              <div className="value"> Activo </div>
            </div>
            <div className="dataItem role">
              <div className="tag"> Tipo de usuario </div>
              <div className="value"> { user_status.data.type === 1 ? 'Vendedor' : 'Administrador' } </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <Button variant="danger" onClick={ () => { loginService.logout().then(() => history.push("/login")); } }> Cerrar Sesión </Button>
      </div>
    </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    user_status: state.userdata
  }

}

export default connect( mapStateToProps )(AccountCard)
