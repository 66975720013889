import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import { FiUserPlus } from 'react-icons/fi'
import Swal from 'sweetalert2';
import moment from 'moment';
import { MONTHS } from '../../../../../constants/constants';
import commitmentsService from '../../../../../services/Commitment';
import Currency from '../../../../../components/Currency';

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewCommitmentModal = ( { campaign, ammount, client_group, dateSelected, handlerClose, refreshAction, clients_groups } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ commitmentData, setCommitmentData ] = useState({
    client_group: client_group,
    ammount: ammount || 0,
    exchange: 'LOCAL',
    date: moment().year( campaign ).month( dateSelected.month ).startOf( 'month' ).format( 'YYYY-MM-DD' )
  })

  const saveCommitment = () => {

    if ( !commitmentData.ammount ){
      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos",
        text: "Por favor complete con todos los campos para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Subiendo promesa de pago..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    commitmentsService.post( commitmentData )
      .then( res => {
        refreshAction();
        Swal.close();
        handlerClose()
      } )

  }

  const client_group_data = clients_groups.data.find( cg => Number( cg.id ) === Number( client_group ) )

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer creation">
            <div className="column">
                <FiUserPlus />
            </div>
            <div className="data">
                <div className='title'> ¿Cuanto se compromete que cobrará del cliente { client_group_data.name } en el mes { MONTHS[ dateSelected.month ] } del año { campaign }? </div>
                <div className="form">
                    <div className="line code">
                        <div className="form-input">
                            <input type="number" placeholder="Valor de cambio" value={ commitmentData.ammount } onChange={ ev => { setCommitmentData( { ...commitmentData, ammount: Number( ev.target.value ) } ) } } />
                            <span className='unit'> <Currency /> </span>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button variant="primary" onClick={ saveCommitment } size="large" disabled={ loading }> Enviar compromiso de pago </Button>
                </div>
            </div>
        </div>

      </Modal>
    </div>
  )
}

export default NewCommitmentModal