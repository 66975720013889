import React from 'react';

const Header = ( props ) => {

  return (
    <div className="header">
      <div className="logo"> 
        <img src='/assets/img/logo_vetanco.svg' alt="company" />
      </div>
    </div>
  )
}

export default Header
