import React from 'react'
import { useSelector } from 'react-redux'

const Currency = ({ value }) => {

    const { userdata } = useSelector( ({ userdata }) => ({ userdata }))

    if ( userdata.loading ) return null

    const LOCAL_TAG = userdata.data.country === 1 ? 'ARS' : 'UY'

    return (
        <span>
            { value ? ( value === 'LOCAL' ? LOCAL_TAG : 'USD' ) : LOCAL_TAG }
        </span>
    )

}

export default Currency