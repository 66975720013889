const env = {

  production: false,
  url: 'https://api.ventasvetanco.com/api',
  front_url: 'https://ventasvetanco.com',
  // url: 'http://localhost:8000/api',
  // front_url: 'http://localhost:3001'

}

export default env
