import Api from './Api';

const productsService = {

  get: (  ) => {

    return Api.get( '/products', {
      withCredentials: true
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/products', jsonData, {
        withCredentials: true
      } )

  },
  put: ( jsonData ) => {

    return Api.put( '/products/' + jsonData.id, jsonData, {
      withCredentials: true
    } )

  },
  delete: ( productId ) => {

    return Api.delete( `/products/${ productId }`, {
      withCredentials: true
    })
  },
  getPriceFromYear: ( product, year ) => {

    if ( product[ "price_" + year ] ) return product[ "price_" + year ]

    if ( year < 2021 ){

      return product.price
    } else {

      return productsService.getPriceFromYear( product, year - 1 )
    }

  }

}

export default productsService
