import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Input } from 'antd';
import './selectProductModal.css'


  // ESTILO DEL MODAL DE INVITACION DE USUARIO
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      background            : 'white',
      padding               : '0',
      height                : '50vh',
      width                 : '30vw',
      overflow              : 'hidden'
    }
  };

const SelectProductModal = ( { products, handlerClose, selectProduct, groupby } ) => {

    const [ stringFilter, setStringFilter ] = useState( '' )
    const [ filteredProducts, setFilteredProducts ] = useState( products.list.data )

    useEffect( () => {

      const primaryList = groupby === 'PRODUCT' ? products.list.data : ( groupby === 'GROUP' ? products.groups.data : products.lines.data )

      const fullList = primaryList.filter( product => {

        const stringToMatch = `${ product.name }`
  
        return stringToMatch.toUpperCase().includes( stringFilter.toUpperCase() )
  
      } )

        setFilteredProducts( fullList )

    }, [ stringFilter, products.list.data, groupby ] )

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer list select-product">
            
            <div className='filter'>
                <Input value={ stringFilter } onChange={ ev => { setStringFilter( ev.target.value ) } } placeholder="Buscar producto.." />
            </div>

            <div className='products-list'>
                {
                    filteredProducts.map( product => {

                        return (
                            <div className='item-product' key={ product.id } onClick={ () => { selectProduct( { groupby: groupby, data: product } ); handlerClose() } }>
                                <div className='data'>
                                    <div className='name'>{ product.name }</div>
                                </div>
                                {/* <div className='group'>{ clientGroup.name }</div> */}
                            </div>
                        )

                    } )
                }
            </div>

        </div>

      </Modal>
    </div>
  )
}

export default SelectProductModal
