import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import Swal from 'sweetalert2';
import salesService from '../../../../services/Sales'

import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import './uploadModal.css'

const { Dragger } = Upload;

  // ESTILO DEL MODAL DE INVITACION DE USUARIO
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      background            : 'white'
    }
  };

const UploadExcelModal = ( { handlerClose, refreshAction } ) => {

  const [ loading, setLoading ] = useState( false )
  const [ file, setFile ] = useState( null )

  const draggerProps = {
    name: 'file',
    accept: ".xls, .xlsx",
    multiple: false,
    showUploadList: false,
    beforeUpload: () => false,
    onChange(info) {  
      setFile( info.file )
    }
  };


  const uploadFiles = () => {

    if ( !file ){
      Swal.fire({
        icon: 'warning',
        title: "Archivo sin seleccionar",
        text: "Por favor seleccione el archivo para continuar"
      })
      
      return;
    }
    
    setLoading( true )
    // ALERT DE LOADING
    Swal.fire ({
       title: "..Subiendo excel..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    salesService.uploadExcel( { file, forceExisting: false } )
      .then( res => {

        if ( !res.data.success ){

            if ( res.data.code === 9 ){

                confirmExisting( file, () => {
                    refreshAction()
                    handlerClose()
                }, res.data.data )

            }

        } else {

            refreshAction();
            Swal.close();
            handlerClose()

        }

      } )
      .catch( err => {

      } )

  }

  useEffect( () => {
      console.log( file )
  }, [ file ] )

  return (
    <div>
      <Modal
              isOpen={ true }
              onRequestClose={ handlerClose }
              style={ customStyles }
            >

        <div className="modalContainer upload">
            <div className='title'> Subir excel de ventas </div>
            <Dragger {...draggerProps} file={ file }>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Clickea aquí o arrastrá tu excel con ventas</p>
            </Dragger>

            <div className='actions'>
                <Button onClick={ uploadFiles } disabled={ loading || !file }> Subir </Button>
            </div>
        </div>

      </Modal>
    </div>
  )
}

export default UploadExcelModal

const confirmExisting = ( file, reloadAction, data ) => {
    // DISPARO ALERT DE CONFIRMACION
    Swal.fire({
              title: 'Datos desconocidos',
              html: `
                    <div class="message"> Detectamos productos y clientes desconocidos en nuestra DB. Recuerda que al subir un excel con ventas de un mes, se sobreescribiran los datos existentes del mes correspondiente. ¿Quieres subir igualmente el resto de los registros de los que conocemos el producto y el cliente? </div>
                    <div class="collapsed">
                      <div class="header"> Lista de productos sin crear </div>
                      <div class="body">
                        ${ data.unknownProducts.map( prod => '<div class="item">' + prod + '</div>' ).join( ' ' ) }
                      </div>
                    </div>
                    <div class="collapsed">
                      <div class="header"> Lista de clientes sin crear </div>
                      <div class="body">
                        ${ data.unknownClients.map( prod => '<div class="item">' + prod + '</div>' ).join( ' ' ) }
                      </div>
                    </div>
                    `,
              icon: 'question',
              customClass: {
                container: 'excel-container'
              }, 
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Si, subir los conocidos!',
              showLoaderOnConfirm: true,
              // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
              preConfirm: ( value ) => {
                return salesService.uploadExcel( { file, forceExisting: true } )
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
              if ( result.value ) {
                Swal.fire(
                  'Subidos!',
                  'Ventas subidas exitosamente!',
                  'success'
                )
  
                reloadAction();
              }
            })
  }