import React from 'react'

import Header from './components/Header';
import FeaturesList from './components/FeaturesList';
import PoweredBy from './components/PoweredBy';

import './sidenav.css';
import { YearCard } from '../../pages/main/forecast/components/YearCard';

const Sidenav = ( { year, setYear, open } ) => {

  return (
    <div className={ open ? 'sidenav' : 'sidenav closed' }>
      <Header />
      <YearCard yearSelected={ year } setYearSelected={ setYear } />
      <FeaturesList />
      {/* <PoweredBy /> */}
    </div>
  )
}

export default Sidenav
