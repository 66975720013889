import React from 'react';

import authService from '../../../../services/Auth';

import Swal from 'sweetalert2';
import { Button } from 'antd';

const PasswordCard = ( props ) => {

  const updatePassword = () => {

    const actualPassword = document.getElementById( 'actualPassword' ).value;
    const newPassword = document.getElementById( 'newPassword' ).value;
    const confirmNewPassword = document.getElementById( 'confirmNewPassword' ).value;

    if ( !actualPassword || !newPassword || !confirmNewPassword ){

      Swal.fire({
        icon: 'warning',
        title: "Campos incompletos!",
        text: "Por favor completa con ambas contraseñas para continuar"
      })

      return;
    }

    if ( newPassword !== confirmNewPassword ){

      Swal.fire({
        icon: 'warning',
        title: "Las contraseñas no coinciden",
        text: "Tu contraseña actual y la confirmación de la misma no coinciden"
      })

      return;
    }

    if ( newPassword === actualPassword ){

      Swal.fire({
        icon: 'warning',
        title: "Misma contraseña",
        text: "Tu nueva contraseña no debe ser igual a la actual"
      })

      return;
    }


    Swal.fire ({
       title: "..Actualizando tu contraseña..",
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    authService.updatePassword( {
      passwordActual: actualPassword,
      passwordNueva: newPassword
    } ).then( data => {
      Swal.fire({
        icon: 'success',
        title: "Contraseña actualizada!",
        text: "Tu contraseña fue actualizada exitosamente"
      })

      document.getElementById( 'actualPassword' ).value = null;
      document.getElementById( 'newPassword' ).value = null;
      document.getElementById( 'confirmNewPassword' ).value = null;

    }, error => {
      Swal.fire({
        icon: 'error',
        title: "Contraseña incorrecta!",
        text: "Tu contraseña actual no es correcta"
      })
    } )

  }

  return (
    <div className="card password">
      <div className="title"> Contraseña </div>
      <div className="content">
        <div className="column">
          <div className="line actualPassword">
            <div className="form-input">
              <input id="actualPassword" type="password" name="password" placeholder="Contraseña actual" />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="line newPassword">
            <div className="form-input">
              <input id="newPassword" type="password" name="password" placeholder="Contraseña nueva" />
            </div>
          </div>
          <div className="line confirmNewPassword">
            <div className="form-input">
              <input id="confirmNewPassword" type="password" name="password" placeholder="Confirmacion de nueva contraseña" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Button size="sm" onClick={ updatePassword }> Actualizar </Button>
      </div>
    </div>
  )
}


export default PasswordCard
