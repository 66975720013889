import authService from '../../services/Auth';

export const getUserdata = () => {

  return ( dispatch ) => {

    dispatch( { type: 'START_FETCH_USERDATA' } )

    return authService.getSelfData()
      .then( response => {

        dispatch({
          type: 'RECEIVE_FETCH_USERDATA',
          payload: response.data
        })

        return response

      } )
      .catch( err => {

        dispatch({
          type: 'FAIL_FETCH_USERDATA'
        })

      })
    
  }

}
