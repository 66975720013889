import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { MdStackedLineChart } from 'react-icons/md'
import Filter from './components/Filter'
import { connect } from 'react-redux'
import { IoMdAdd } from 'react-icons/io'
import ForecastsList from './components/ForecastList'
import forecastService from '../../../services/Forecast'
import './forecast.css'
import NewForecastModal from './components/NewForecast'
import { Button } from 'antd'
import { getProducts } from '../../../store/actions/getProducts'
import UploadExcelForecastModal from './components/UploadExcelModal'
import { LoadingPage } from '../loading/LoadingPage'

const ForecastPage = ({ products_state, clients_state, yearSelected, userdata, getProducts }) => {

  const [ filters, setFilters ] = useState( { string: '', client_group: null, product: null, justProductsWithData: true } );
//   const [ facturableFilter, setFacturableFilter ] = useState( 'ALL' );
  const [ forecastListFiltered, setForecastListFiltered ] = useState( [] )
  const [ newForecastModal, setNewForecastModal ] = useState( { visible: false, data: { editionMode: false, forecastId: null } } )
  const [ uploadExcelModalVisible, setUploadExcelModalVisible ] = useState( false ) 
  const [ fetchingForecastStatus, setFetchingForecastStatus ] = useState( { loading: true, error: null, data: null } )

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    
    if ( 
      fetchingForecastStatus.data &&
      products_state.list.data &&
      clients_state.groups.data
       ){

      const fullList = fetchingForecastStatus.data
                                            .filter( forecast => {

                                                const product = products_state.list.data && products_state.list.data.find( prod => Number( forecast.product ) === Number( prod.id ) )
                                                const client_group = clients_state.groups.data && clients_state.groups.data.find( cg => Number( forecast.client_group ) === Number( cg.id ) )

                                                const stringToMatch = `${ product && product.name } ${ client_group && client_group.name }`
                                                const stringFilter = stringToMatch.toUpperCase().includes( filters.string.toUpperCase() )

                                                const productMatch = !filters.product || filters.product === forecast.product
                                                const groupMatch = !filters.client_group || filters.client_group === forecast.client_group

                                                const productsWithDataFilter = filters.justProductsWithData ? (
                                                  forecast.q_enero ||
                                                  forecast.q_febrero ||
                                                  forecast.q_marzo ||
                                                  forecast.q_abril ||
                                                  forecast.q_mayo ||
                                                  forecast.q_junio ||
                                                  forecast.q_julio ||
                                                  forecast.q_agosto ||
                                                  forecast.q_septiembre ||
                                                  forecast.q_octubre ||
                                                  forecast.q_noviembre ||
                                                  forecast.q_diciembre
                                                ) : true;
                                                
                                                return stringFilter && productMatch && groupMatch && productsWithDataFilter
                                            } )
  
      setForecastListFiltered( fullList );

    }

  }, [ filters, fetchingForecastStatus.data, clients_state, products_state ] )

  const refreshForecast = () => {

    setFetchingForecastStatus( { loading: true, error: null, data: null } )
    forecastService.get({ year: yearSelected })
      .then( res => {
        setFetchingForecastStatus( { loading: false, error: null, data: res.data.data } )
        getProducts()
      } )

  }

  const editForecast = forecastId => {

    const saleData = fetchingForecastStatus.data.find( forecast => Number( forecast.id ) === Number( forecastId ) )

    setNewForecastModal({ visible: true, data: { editionMode: true, forecastData: saleData } })

  }

  useEffect( () => {
    refreshForecast()
  }, [ yearSelected ] )

  if ( 
    fetchingForecastStatus.loading   || 
    clients_state.list.loading    || 
    clients_state.groups.loading  || 
    products_state.list.loading   || 
    products_state.groups.loading || 
    products_state.lines.loading ||
    userdata.loading
  ) return <LoadingPage />

  return (
    <div className='page forecast page-list'>
        <div className='row'>
          <PageHeader title="Pronostico de ventas" icon={ <MdStackedLineChart /> } />
          {
            userdata.data.type === 10 &&
            <div className='actions'>
              <div className='action add'>
                <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setUploadExcelModalVisible( true ) } }> Subir Excel </Button>
              </div>
              <div className='action add'>
                <Button type="primary" shape="round" icon={<IoMdAdd />} size="middle" onClick={ () => { setNewForecastModal( { visible: true, data: { editionMode: false, forecastId: null } } ) } }> Subir Pronostico Manual </Button>
              </div>
            </div>
          }
        </div>
        <div className='body'>

            <div className='row'>
              <Filter filters={ filters } setFilters={ setFilters } clients={ clients_state } products={ products_state } />
            </div>

            <ForecastsList 
                      forecasts={ forecastListFiltered }
                      products={ products_state } 
                      clients={ clients_state }
                      edition={ userdata.data.type === 10 }
                      openEditionModal={ editForecast }
                      yearSelected={ yearSelected } 
                      reloadAction={ refreshForecast } />

        </div>
        {
          uploadExcelModalVisible && <UploadExcelForecastModal
                                      handlerClose={ () => { setUploadExcelModalVisible( false ) } }
                                      year={ yearSelected }
                                      refreshAction={ refreshForecast } />
        }
        {
          newForecastModal.visible && <NewForecastModal
                                      handlerClose={ () => { setNewForecastModal( { ...newForecastModal, visible: false } ) } }
                                      products={ products_state }
                                      clients={ clients_state }
                                      year={ yearSelected }
                                      refreshAction={ refreshForecast }
                                      data={ newForecastModal.data }
                                              />
        }
    </div>
  )
}

export default connect( state => ({ userdata: state.userdata, products_state: state.products, clients_state: state.clients, yearSelected: state.campaign } ), { getProducts } )( ForecastPage )