import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaArrowsAltV } from 'react-icons/fa'
import Currency from '../../../../../../components/Currency'
import { EmptyContainer } from '../../../../sales/components/EmptyContainer'
import './sales-list.css'

export const SalesList = ({ fetchStatus, products, clients, compare, exchange }) => {

    const [ orderedList, setOrderedList ] = useState( { orderby: { item: null, desc: false }, list: [] } )

      // EFECTO PARA ORDENAR LISTA
    useEffect( () => {

        const compareData = compare.on && !compare.fetchStatus.loading && compare.fetchStatus.data ? compare.fetchStatus.data.data.data.SALES_GRAPH.map( item => ({ ...item, compareItem: true }) ) : []
        let ordered = [ ...fetchStatus.data.data.data.SALES_GRAPH, ...compareData ]

        if ( orderedList.orderby.item === 'DATE' ){

            ordered = ordered.sort( ( sale1, sale2 ) => {

                if ( orderedList.orderby.desc ){

                if ( sale1.date < sale2.date ) {
                    return 1;
                }
                if ( sale1.date > sale2.date ) {
                    return -1;
                }
                return 0;

                } else {

                if ( sale1.date > sale2.date ) {
                    return 1;
                }
                if ( sale1.date < sale2.date ) {
                    return -1;
                }
                return 0;

                }

            } );

        } else if ( orderedList.orderby.item === 'CLIENT' ){

            ordered = ordered.sort( ( sale1, sale2 ) => {

                const client1 = clients && clients.data.find( g => Number( g.id ) === Number( sale1.client ) )
                const client2 = clients && clients.data.find( g => Number( g.id ) === Number( sale2.client ) )

                if ( orderedList.orderby.desc ){

                if ( ( client1 && client1.name || '' ) < ( client2 && client2.name || '' ) ) {
                    return 1;
                }
                if ( ( client1 && client1.name || '' ) > ( client2 && client2.name || '' ) ) {
                    return -1;
                }
                return 0;

                } else {

                if ( ( client1 && client1.name || '' ) > ( client2 && client2.name || '' ) ) {
                    return 1;
                }
                if ( ( client1 && client1.name || '' ) < ( client2 && client2.name || '' ) ) {
                    return -1;
                }
                return 0;

                }

            } );

        } else if ( orderedList.orderby.item === 'PRODUCT' ){
        
            ordered = ordered.sort( ( sale1, sale2 ) => {

                const product1 = products && products.data.find( g => Number( g.id ) === Number( sale1.product ) )
                const product2 = products && products.data.find( g => Number( g.id ) === Number( sale2.product ) )

                if ( orderedList.orderby.desc ){

                if ( ( product1 && product1.name || '' ) < ( product2 && product2.name || '' ) ) {
                    return 1;
                }
                if ( ( product1 && product1.name || '' ) > ( product2 && product2.name || '' ) ) {
                    return -1;
                }
                return 0;

                } else {

                if ( ( product1 && product1.name || '' ) > ( product2 && product2.name || '' ) ) {
                    return 1;
                }
                if ( ( product1 && product1.name || '' ) < ( product2 && product2.name || '' ) ) {
                    return -1;
                }
                return 0;

                }

            } );

        } else if ( orderedList.orderby.item === 'Q' ){

            ordered = ordered.sort( ( sale1, sale2 ) => {

                if ( orderedList.orderby.desc ){

                if ( sale1.q < sale2.q ) {
                    return 1;
                }
                if ( sale1.q > sale2.q ) {
                    return -1;
                }
                return 0;

                } else {

                if ( sale1.q > sale2.q ) {
                    return 1;
                }
                if ( sale1.q < sale2.q ) {
                    return -1;
                }
                return 0;

                }

            } );

        } else if ( orderedList.orderby.item === 'TOTAL' ){

            ordered = ordered.sort( ( sale1, sale2 ) => {

                if ( orderedList.orderby.desc ){

                if ( sale1.total < sale2.total ) {
                    return 1;
                }
                if ( sale1.total > sale2.total ) {
                    return -1;
                }
                return 0;

                } else {

                if ( sale1.total > sale2.total ) {
                    return 1;
                }
                if ( sale1.total < sale2.total ) {
                    return -1;
                }
                return 0;

                }

            } );

        }

        setOrderedList( {
            ...orderedList,
            list: ordered
        } )

    }, [ clients, orderedList.orderby, compare ] )

    if ( !fetchStatus.data ) return <div className='card sales'><Spin/></div>

    const totals = getTotals( fetchStatus.data.data.data.SALES_GRAPH )
    const totalsCompate = compare.on && compare.fetchStatus.data && getTotals( compare.fetchStatus.data.data.data.SALES_GRAPH )

    return (
        <div className='card sales'>
            <div className='title'>Ventas del mes</div>
            {
                orderedList.list && orderedList.list.length
                ?
                <div className='sales-table'>
                    <div className='table-header'>
                        <div className='item'>Fecha <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'DATE', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                        <div className='item'>Cliente <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'CLIENT', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                        <div className='item'>Producto <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'PRODUCT', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                        <div className='item'>Cantidad <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'Q', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                        <div className='item'>Total <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'TOTAL', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                    </div>
                    <div className='table-body'>
                        {
                            orderedList.list.map( ( sale, index ) => {

                                const product = products.data.find( p => p.id === Number( sale.product ) )
                                const client = clients.data.find( c => c.id === Number( sale.client ) )

                                return (
                                    <div className='sale-item' key={ index }>
                                        { compare.on && <div className={ "color-tag " + (sale.compareItem ? "COMPARE" : 'MAIN') }></div> }
                                        <div className='item'>{ sale.date }</div>
                                        <div className='item name'>{ client.name }</div>
                                        <div className='item name'>{ product.name }</div>
                                        <div className='item'>{ sale.q }</div>
                                        <div className='item'>{ Math.round( sale.totalExchange ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                                    </div>
                                )
                            } )
                        }
                        <div className='sale-item item-total' >
                            { compare.on && <div className={ "color-tag " + 'MAIN' }></div> }
                            <div className='item'>Total mes</div>
                            <div className='item name'></div>
                            <div className='item name'></div>
                            <div className='item'>{ totals.q }</div>
                            <div className='item'>{ Math.round( totals.totalExchange ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                        </div>
                        {
                            totalsCompate &&
                            <div className='sale-item item-total' >
                                { compare.on && <div className={ "color-tag " + 'COMPARE' }></div> }
                                <div className='item'>Total mes</div>
                                <div className='item name'></div>
                                <div className='item name'></div>
                                <div className='item'>{ totalsCompate.q }</div>
                                <div className='item'>{ Math.round( totalsCompate.totalExchange ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'> <Currency value={ exchange } /> </span> </div>
                            </div>
                        }
                    </div>
                </div>
                :
                <EmptyContainer title="Sin datos" message="No hay ventas registradas para los filtros seleccionados" />
            }
        </div>
    )
}

function getTotals( data ){

    return data.reduce( ( prev, curr ) => {

        return {
            q: prev.q + curr.q,
            totalExchange: prev.totalExchange + curr.totalExchange
        }

    }, { q: 0, totalExchange: 0 })

}