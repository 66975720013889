import { Spin } from 'antd'
import React from 'react'
import './loading.css'

export const LoadingPage = () => {
  return (
    <div className='page loading'>
        <div className='spinner'>
            <Spin />
        </div>
    </div>
  )
}
