import React from 'react'
import Currency from '../../../../../../components/Currency'
import { MONTHS } from '../../../../../../constants/constants'
import './filter-pills.css'

export const FilterPills = ({
    filters,
    products,
    clients
}) => {

    return (
        <div className="filter-pills">
            
            <div className="item">{ MONTHS[ filters.date.month ] }</div>

            <div className="item">{filters.exchange === "LOCAL" ? <Currency /> : "USD" }</div>

            <div className="item">{ filters.sub_clients !== null && filters.sub_clients.length < clients.list.data.length ? `${ filters.sub_clients.length } clientes del grupo` : 'Todos los clientes del grupo' }</div>

            <div className="item">{ filters.products.length < products.data.length ? `${ filters.products.length } productos` : 'Todos los productos' }</div>

        </div>
    )

}