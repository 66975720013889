import React from 'react'
import { VscInbox } from 'react-icons/vsc'
import './empty-container.css'

export const EmptyContainer = ({ title, message }) => {

    return (
      <div className='empty-container'>
        <div className='icon'>
          <VscInbox />
        </div>
        <div className='title'> { title } </div>
        <div className='message'> { message } </div>
      </div>
    )
  
  }
