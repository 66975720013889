import React from 'react';
import ReactDOM from 'react-dom';
import './style/global.css';

import { Provider } from 'react-redux';

import App from './components/App';

import store from './store/store';

const root = (
              <Provider store={ store }>
                <App />
              </Provider>
              )

ReactDOM.render( root, document.getElementById('root') );
