import { Popover, Progress, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { FaArrowsAltV } from 'react-icons/fa'
import { HiOutlinePencil } from 'react-icons/hi'
import { EmptyContainer } from '../../../sales/components/EmptyContainer'
import './sales-state-list.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Currency from '../../../../../components/Currency'

export const SalesStateList = ({ graphFetchStatus, client_groups, sellers, setCommitmentModal, userdata, filteredList }) => {

    const [ orderedList, setOrderedList ] = useState( { orderby: { item: null, desc: false }, list: [] } )

    // EFECTO PARA ORDENAR LISTA
    useEffect( () => {

        if ( filteredList ){

            let ordered = filteredList
            if ( orderedList.orderby.item === 'FORECAST' ){
    
                ordered = filteredList.sort( ( client1, client2 ) => {
    
                    const forecast1 = client1.forecast.USD
                    const forecast2 = client2.forecast.USD
    
                    if ( orderedList.orderby.desc ){
    
                        if ( forecast1 < forecast2 ) {
                            return 1;
                        }
                        if ( forecast1 > forecast2 ) {
                            return -1;
                        }
                        return 0;
    
                    } else {
    
                        if ( forecast1 > forecast2 ) {
                            return 1;
                        }
                        if ( forecast1 < forecast2 ) {
                            return -1;
                        }
                        return 0;
    
                    }
    
                } );
    
            } else if ( orderedList.orderby.item === 'GROUP' ){
    
            ordered = filteredList.sort( ( client1, client2 ) => {
    
                const client_group1 = client_groups.data.find( c => Number( c.id ) === Number( client1.id ) )
                const client_group2 = client_groups.data.find( c => Number( c.id ) === Number( client2.id ) )
    
                if ( orderedList.orderby.desc ){
    
                if ( ( client_group1 && client_group1.name || '' ) < ( client_group2 && client_group2.name || '' ) ) {
                    return 1;
                }
                if ( ( client_group1 && client_group1.name || '' ) > ( client_group2 && client_group2.name || '' ) ) {
                    return -1;
                }
                return 0;
    
                } else {
    
                if ( ( client_group1 && client_group1.name || '' ) > ( client_group2 && client_group2.name || '' ) ) {
                    return 1;
                }
                if ( ( client_group1 && client_group1.name || '' ) < ( client_group2 && client_group2.name || '' ) ) {
                    return -1;
                }
                return 0;
    
                }
    
            } );
    
            } else if ( orderedList.orderby.item === 'SALES' ){
    
                ordered = filteredList.sort( ( client1, client2 ) => {
    
                    const sales1 = client1.sales.LOCAL
                    const sales2 = client2.sales.LOCAL
    
                    if ( orderedList.orderby.desc ){
    
                        if ( sales1 < sales2 ) {
                            return 1;
                        }
                        if ( sales1 > sales2 ) {
                            return -1;
                        }
                        return 0;
    
                    } else {
    
                        if ( sales1 > sales2 ) {
                            return 1;
                        }
                        if ( sales1 < sales2 ) {
                            return -1;
                        }
                        return 0;
    
                    }
    
                } );
    
            } else if ( orderedList.orderby.item === 'COMMITMENT' ){
    
                ordered = filteredList.sort( ( client1, client2 ) => {
                    
                    const commitment1 = Object.keys( client1.commitments ).length ? Object.keys( client1.commitments ).reduce( ( prev, curr ) => ( prev + client1.commitments[ curr ].LOCAL ), 0 ) : 0
                    const commitment2 = Object.keys( client2.commitments ).length ? Object.keys( client2.commitments ).reduce( ( prev, curr ) => ( prev + client2.commitments[ curr ].LOCAL ), 0 ) : 0
    
                    if ( orderedList.orderby.desc ){
    
                        if ( commitment1 < commitment2 ) {
                            return 1;
                        }
                        if ( commitment1 > commitment2 ) {
                            return -1;
                        }
                        return 0;
    
                    } else {
    
                        if ( commitment1 > commitment2 ) {
                            return 1;
                        }
                        if ( commitment1 < commitment2 ) {
                            return -1;
                        }
                        return 0;
    
                    }
    
                } );
            } else if ( orderedList.orderby.item === 'RESULT' ){
    
                ordered = filteredList.sort( ( client1, client2 ) => {
                    
                    const commitment1 = Object.keys( client1.commitments ).length ? Object.keys( client1.commitments ).reduce( ( prev, curr ) => ( prev + client1.commitments[ curr ].LOCAL ), 0 ) : 0
                    const commitment2 = Object.keys( client2.commitments ).length ? Object.keys( client2.commitments ).reduce( ( prev, curr ) => ( prev + client2.commitments[ curr ].LOCAL ), 0 ) : 0
    
                    const result1 = !client1.forecast.LOCAL ? 100 : Math.round( ( client1.sales.LOCAL + commitment1 ) / client1.forecast.LOCAL * 100 )
                    const result2 = !client2.forecast.LOCAL ? 100 : Math.round( ( client2.sales.LOCAL + commitment2 ) / client2.forecast.LOCAL * 100 )
    
                    if ( orderedList.orderby.desc ){
    
                        if ( result1 < result2 ) {
                            return 1;
                        }
                        if ( result1 > result2 ) {
                            return -1;
                        }
                        return 0;
    
                    } else {
    
                        if ( result1 > result2 ) {
                            return 1;
                        }
                        if ( result1 < result2 ) {
                            return -1;
                        }
                        return 0;
    
                    }
    
                } );
    
            }
    
            setOrderedList( {
                ...orderedList,
                list: ordered
            } )
            
        }

    }, [ filteredList, orderedList.orderby ] )

    if ( graphFetchStatus.loading ) return <div className='status-table'><Spin/></div>

    const totaldata = getTotals( graphFetchStatus.data.data )

    return (
            <div className='status-table'>
                <div className='table-header'>
                    <div className='item'>Cliente <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'GROUP', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div>  </div>
                    <div className='item'>Pronóstico <span className='exchange-tag'>USD</span> <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'FORECAST', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div>  </div>
                    <div className='item'>Pronóstico <span className='exchange-tag'><Currency /></span> <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'FORECAST', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div>  </div>
                    <div className='item'>Vendido <span className='exchange-tag'><Currency /></span> <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'SALES', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                    <div className='item'>Comprometido <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'COMMITMENT', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                    <div className='item'>Estado Actual <div onClick={ () => { setOrderedList( { ...orderedList, orderby: { ...orderedList.orderby, item: 'RESULT', desc: !orderedList.orderby.desc } } ) } }> <FaArrowsAltV /> </div> </div>
                </div>
                <div className='table-body'>
                    {
                        orderedList.list.length
                        ?
                        <>
                        {
                            orderedList.list.map( ( cg, index ) => {

                                const client_group = client_groups.data.find( c => Number( c.id ) === Number( cg.id ) )
        
                                return <SaleStateItem
                                            key={ index }
                                            client_group={ client_group } 
                                            data={ cg } 
                                            sellers={ sellers }
                                            userdata={ userdata }
                                            setCommitmentModal={ setCommitmentModal } />
                                } )
                        }
                        <div className='item-total'>
                            <SaleStateItem
                                client_group={ { name: 'Total' } } 
                                data={ totaldata } />
                        </div>
                        </>                        
                        :
                        <EmptyContainer
                                    title="Sin registros"
                                    message="No tenemos datos de ventas ni pronósticos para este mes" />
                    }
                </div>
            </div>
    )

}

const SaleStateItem = ({ client_group, data, sellers, userdata, setCommitmentModal }) => {

    const history = useHistory()
    const { id, forecast, sales, commitments } = data || {}

    const commitmentsARSValue = commitments && Object.keys( commitments ).length ? Object.keys( commitments ).reduce( ( prev, curr ) => ( prev + commitments[ curr ].LOCAL ), 0 ) : 0
    const percentage = !forecast.LOCAL ? 100 : Math.round( ( sales.LOCAL + commitmentsARSValue ) / forecast.LOCAL * 100 )

    const handleClick = () => {
        history.push( `/main/statecli/${ client_group.id }` )
    }

    return (
        <div className='status-item' onClick={ handleClick }>
            <div className='item'>{ client_group && client_group.name }</div>
            <div className='item'>{ Math.round( forecast.USD ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'>USD</span></div>
            <div className='item'>{ Math.round( forecast.LOCAL ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'><Currency /></span></div>
            <div className='item'>{ Math.round( sales.LOCAL ).toLocaleString( 'es-AR' ) } <span className='exchange-tag'><Currency /></span></div>
            <div className='item'>
                {
                    commitmentsARSValue && commitments && sellers && userdata &&
                    <>
                    { commitmentsARSValue.toLocaleString( 'es-AR' ) } <span className='exchange-tag'><Currency /></span>
                    <div className='actions'>
                        {
                            commitmentsARSValue
                            ?
                            <Popover
                                content={ <CommitmentsUsersList commitments={ commitments } users={ sellers.data } userdata={ userdata } /> }
                                trigger="click"
                                >
                                <div className='action'> <AiOutlineEye /> </div>
                            </Popover>
                            :
                            null
                        }
                        <div className='action' onClick={ () => { setCommitmentModal( { visible: true, data: { client_group: id, ammount: commitments[ userdata.id ]?.LOCAL } } ) } }> <HiOutlinePencil /> </div>
                    </div>
                    </>
                }
            </div>
            <div className='item'>
                <Progress
                    strokeColor={ percentage >= 70 ? '#52c41a' : ( percentage >= 30 ? '#ff9800' : '#ff0000' ) }
                    percent={ percentage > 100 ? 100 : percentage }
                    format={ percent => <div className='percentage'>{ Math.round( percentage ) }%</div> }
                    />
            </div>
        </div>
    )

}

const CommitmentsUsersList = ({ commitments, users, userdata }) => {

    return (
        <div className='commitments-list'>
            {
                Object.keys( commitments ).map( user => <CommitmentsUsersComponent key={ user } commitment={ { ...commitments[ user ], user: user } } users={ users } userdata={ userdata } /> )
            }
        </div>
    )

}

const CommitmentsUsersComponent = ({ commitment, users, userdata }) => {

    const user_data = Number( commitment.user ) === Number( userdata.id ) ? userdata : users.find( user => Number( user.id ) === Number( commitment.user ) )

    return (
        <div className='commitment'>
            <div className='ammount'>
                <div className='value'>{ commitment.LOCAL.toLocaleString( 'es-AR' ) }</div>
                <div className='exchange'><Currency /></div>
            </div>
            {
                user_data &&
                <div className='user'>
                    {
                        user_data.firstname + " " + user_data.lastname
                    }
                </div>
            }
        </div>
    )

}

function getTotals( data ){

    return Object.keys( data )
                .reduce( ( prev, curr ) => {
                    
                    return {
                        forecast: {
                            LOCAL: prev.forecast.LOCAL + data[ curr ].forecast.LOCAL,
                            USD: prev.forecast.USD + data[ curr ].forecast.USD
                        },
                        sales: {
                            LOCAL: prev.sales.LOCAL + data[ curr ].sales.LOCAL,
                            USD: prev.sales.USD + data[ curr ].sales.USD
                        }
                    }

                }, { forecast: { LOCAL: 0, USD: 0 }, sales: { LOCAL: 0, USD: 0 } } )
    
}