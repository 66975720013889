import { Button } from 'antd'
import React, { useState } from 'react'
import { MONTHS } from '../../../../constants/constants'
import { GrFormPrevious, GrFormNext } from 'react-icons/gr'
import './yearcard.css'


export const YearCard = ({ yearSelected, setYearSelected }) => {

  return (
    <div className='year-card'>
        <div className='action prev' onClick={ () => { setYearSelected( yearSelected - 1 ) } } > <GrFormPrevious /> </div>
        <div className='year'>
          <div className='tag'>Año</div>
          <div className='value'> { yearSelected } </div>
        </div>
        <div className='action next' onClick={ () => { setYearSelected( yearSelected + 1 ) } } > <GrFormNext /> </div>
    </div>
  )
}
